import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { createUser } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import { setToken, setUser } from "../../redux/actions/userActions";
import { URL } from "../../components/Navbar/routes";
import Iqilogo from '../../images/iqi-cloud-blue-mobile.svg';

import "./SignUp.scoped.scss";
import { useHistory } from "react-router-dom";

const SignUp: React.FC = () => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
			confirmPassword: "",
			firstName: "",
			lastName: "",
			contactNumber: "",
			terms: false,
			agreement: false,
			role: "user",
			isContractor: ""
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Please enter valid email ID")
				.required("Email is required"),
			password: Yup.string()
				.required("Password is required")
				.test(
					"len",
					"The password must be 6–16 characters in length",
					function (newPassword: any) {
						return newPassword?.length > 5 && newPassword?.length < 17;
					}
				)
				.test(
					"security-check",
					"The password should contain at least two of the following types: numbers, uppercase letters, lowercase letters, and underscores.",
					function (newPassword: any) {
						let isFound = [];
						if (/\d/.test(newPassword)) {
							isFound.push(true);
						}
						if (/[a-z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (/[A-Z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (newPassword && newPassword.includes("_")) {
							isFound.push(true);
						}
						return isFound.length >= 2;
					}
				),
			confirmPassword: Yup.string()
				.required("Confirm Password is required")
				.oneOf(
					[Yup.ref("password")],
					`Password and confirm password didn't match`
				),
			firstName: Yup.string().trim().required("First Name is required")
				.matches(/[a-zA-Z]/, "First name is not valid"),
			lastName: Yup.string().trim().required("Last Name is required")
				.matches(/[a-zA-Z]/, "Last name is not valid"),
			contactNumber: Yup.string()
				.matches(/^\d{10}$/, "Please Enter 10 digit number without country code")
				.required("Phone Number is required"),
			terms: Yup.bool().oneOf([true], "Please accept terms and conditions"),
			isContractor: Yup.string()
			.required("Please select Yes or No")
		}),
		onSubmit: async (values: any) => {
			if (formik.values.isContractor === "true") {
				values.role = "contractor";
			}
			setIsLoading(true);
			const response = await createUser(values);
			setIsLoading(false);
			if (response?.responseObj?.token) {
				toast.success(response?.responseObj?.message);
				dispatch(setToken(response?.responseObj?.token));
				dispatch(setUser(response?.responseObj?.user));
				history.push(URL.DASHBOARD);
			} else if (response.error) {
				toast.error(response.error);
			}
		},
	});

	return (
		<>
			<div className="d-flex sign-up-section bg-white">
				<div className="flex-1 signup-banner">
					<div className="signup-logo" />
					<div className="d-flex align-items-center h-100 font-700">
						<div className="text-content">
							<h4 className="fs-60 ls-024 txt-white d-flex flex-column mb-4 lh-72">
								<span className="font-warn-light-1">Filter Forensics</span>
							</h4>
							<p className="desc-xlarge font-white-85 ls-024 mb-5">For a Healthier, Cleaner & Safer Country</p>
						</div>
					</div>
				</div>
				{isLoading && <SpinnerLoader />}
				<div className="flex-1 signup-form">
					<div className="d-flex align-items-center justify-content-end py-3 px-4 sticky-top-0 bg-white login-mobile-nosign">
						<p className="mb-0 mr-3">Already have an Account ?</p>
						<a href="/login" className="btn btn-outline-primary align-self-center mr-2">
							Log In
						</a>
					</div>
					<div className="form-section py-4 w-100 mx-auto">
						<div className="w-100 logo-mobile sign-up-logo">
							<img className="" src={Iqilogo} alt="" />
						</div>
						<div>

						</div>
						<h4 className="mb-2 fs-24 font-700 ls-024 lh-normal font-text-1">Create account</h4>
						<p className="mb-4 desc-normal ls-024 lh-normal font-grey-light-4">Create account to start using Filter Screening</p>
						<form onSubmit={formik.handleSubmit}>
							<div className="form-group mb-3">
								<label className="desc-normal ls-024 font-grey-light-4" htmlFor="firstName">First Name</label>
								<input
									type="text"
									className="form-control"
									autoComplete="off"
									placeholder="First Name"
									name="firstName"
									id="firstName"
									onChange={(e: any) => {
										let name = e?.target?.value?.trimStart();
										name = name?.replace(/[^a-zA-Z0-9 ]/g, '');
										formik.setFieldValue("firstName", name);
									}}
									onBlur={formik.handleBlur}
									value={formik.values.firstName}
								/>
								{formik.touched.firstName && formik.errors.firstName && (
									<div className="text-danger mt-1 desc-small">
										{formik.errors.firstName}
									</div>
								)}
							</div>
							<div className="form-group mb-3">
								<label className="desc-normal ls-024 font-grey-light-4" htmlFor="lastName">Last Name</label>
								<input
									type="text"
									className="form-control"
									autoComplete="off"
									placeholder="Last Name"
									name="lastName"
									id="lastName"
									onChange={(e: any) => {
										const name = e?.target?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
										formik.setFieldValue("lastName", name);
									}}
									onBlur={formik.handleBlur}
									value={formik.values.lastName}
								/>
								{formik.touched.lastName && formik.errors.lastName && (
									<div className="text-danger mt-1 desc-small">{formik.errors.lastName}</div>
								)}
							</div>
							<div className="from-group mb-3">
								<label className="desc-normal ls-024 font-grey-light-4" htmlFor="email">Email</label>
								<input
									className="form-control"
									type="text"
									autoComplete="off"
									placeholder="Email"
									name="email"
									id="email"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
								/>
								{formik.touched.email && formik.errors.email && (
									<div className="text-danger mt-1 desc-small">{formik.errors.email}</div>
								)}
							</div>
							<div className="form-group mb-3">
								<label className="desc-normal ls-024 font-grey-light-4" htmlFor="contactNumber">Phone Number</label>
								<input
									type="text"
									className="form-control"
									autoComplete="off"
									placeholder="Phone Number"
									name="contactNumber"
									id="contactNumber"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.contactNumber}
								/>
								{formik.touched.contactNumber && formik.errors.contactNumber && (
									<div className="text-danger mt-1 desc-small">
										{formik.errors.contactNumber}
									</div>
								)}
							</div>
							<div className="from-group mb-3">
								<label className="desc-normal ls-024 font-grey-light-4" htmlFor="password">Password</label>
								<div className="position-relative">
									<input
										type={showPassword ? "text" : "password"}
										className="form-control"
										autoComplete="off"
										placeholder="Enter Password"
										name="password"
										id="password"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.password}
									/>

									<IconButton
										className="eye-icon"
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</div>
								{formik.touched.password && formik.errors.password && (
									<div className="text-danger mt-1 desc-small">{formik.errors.password}</div>
								)}
							</div>
							<div className="form-group mb-3">
								<label className="desc-normal ls-024 font-grey-light-4" htmlFor="confirm-password">Confirm Password</label>
								<div className="position-relative">
									<input
										type={showConfirmPassword ? "text" : "password"}
										className="form-control"
										autoComplete="off"
										placeholder="Re-Enter Password"
										name="confirmPassword"
										id="confirm-password"
										data-bv-identical="true"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.confirmPassword}
									/>
									<IconButton
										className="eye-icon"
										aria-label="toggle password visibility"
										onClick={handleClickShowConfirmPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</div>
								{formik.touched.confirmPassword &&
									formik.errors.confirmPassword && (
										<div className="text-danger mt-1 desc-small">
											{formik.errors.confirmPassword}
										</div>
									)}
							</div>
							<input
								type="hidden"
								value={formik.values.firstName}
								name="role"
								id="role"
							/>

							<div className="mb-3 col-md-12">
								<div className="align-items-center">
									<label className="mb-0 cursor-pointer flex-1">
										Are you a Service Provider? / Will you be also collecting Samples for your clients?
									</label>
									<div className="d-flex align-items-center">
										<input
											type="radio"
											id="p-plan"
											name="isContractor"
											onChange={formik.handleChange}
											className="mr-1"
											value="true"
											checked={formik.values.isContractor === "true"}
										/>{" "}
										Yes
										<input
											type="radio"
											id="f-plan"
											name="isContractor"
											onChange={formik.handleChange}
											className="mr-1 ml-2"
											value="false"
											checked={formik.values.isContractor === "false"}
										/>{" "}
										No
									</div>
								</div>
								{formik.touched.isContractor && formik.errors.isContractor && (
									<div className="text-danger mt-1 desc-small">{formik.errors.isContractor}</div>
								)}
							</div>

							<div className="mb-3 col-md-12">
								<div className="d-flex align-items-center">
									<input
										className="sign-up-checkbox cursor-pointer"
										type="checkbox"
										id="terms"
										name="terms"
										onChange={formik.handleChange}
										checked={formik.values.terms}
									/>
									<label className="ml-2 mb-0 cursor-pointer flex-1" htmlFor="terms">
										I have read, understood and agreed to the
										<a
											className="link ml-1"
											target="_blank"
											rel="noreferrer"
											href="https://www.bldg.health/toc"
										>
											Terms & Conditions
										</a>
										&nbsp; and
										<a
											className="link ml-1"
											target="_blank"
											rel="noreferrer"
											href="https://www.bldg.health/privacy"
										>
											Privacy Policy
										</a>
										.
									</label>
								</div>
								{formik.touched.terms && formik.errors.terms && (
									<div className="text-danger mt-1 desc-small">{formik.errors.terms}</div>
								)}
							</div>
							<div className="d-flex justify-content-center mb-4">
								<button type="submit" className="btn btn-lg btn-primary w-100 mr-0 pull-right">
									Sign Up
								</button>
							</div>

							<div className="login-mobile-sign">
								<p className="ls-024 fs-14 font-400 font-grey-light-4">
									Already have an account?{" "}
									<a
										href="/login"
										className="font-500 font-dark-blue"
									>
										Log in
									</a>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>

		</>
	);
};

export default SignUp;
