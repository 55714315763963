import React from "react";
import { Button, Modal } from "react-bootstrap";

interface ShipIt {
    open: boolean;
    onClose: () => void;
}

export const GetResultModal: React.FC<ShipIt> = ({
    open,
    onClose
}) => {

    return (
        <Modal show={open} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>3. Get Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="ml-3 font-500">
                    <p>We look forward to receiving your sample. Once the sample is received,
                        please allow <u>5-7 business days</u> for our lab to process and get you the reports. </p>
                    <p>You will receive an email notification when the report is ready.
                        <span className="txt-blue">You can also check the status of your order online in your Filter Forensics portal.</span>
                    </p>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} className="btn-medium">
                    Proceed
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
