import { SelectableRows } from "mui-datatables";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { getShippingLabelsList, voidShippinglabel } from "../../api/UserController";
import TableCell from "@material-ui/core/TableCell/TableCell";
import IconButton from "@material-ui/core/IconButton/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from "react-bootstrap";

const ShippingLabel = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [labelList, setLabelsList] = useState<any>([]);
    const [selectedLabel, setSelectedLabel] = useState<any>({});
    const [showVoidLabel, setShowVoidLabel] = useState<boolean>(false);

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        setIsLoading(true);
        const response = await getShippingLabelsList();
        setIsLoading(false);
        if (response?.responseObj?.list) {
            const list = response?.responseObj?.list
            setLabelsList(list);
        }
    }

    const onClickVoid = async (id: string) => {
        const data = labelList.find((l: any) => l._id === id);
        if (data) {
            setSelectedLabel(data);
            setShowVoidLabel(true);
        }
    };

    const voidShippingLabel = async () => {
        setShowVoidLabel(false);
        setIsLoading(true);
        let response = await voidShippinglabel(selectedLabel.labelId);
        setIsLoading(false);
        if (response?.error) {
            toast.error(response?.error);
        } else if (response?.responseObj.message) {
            toast.success("Deleted successfully");
            getList();
        }
    }

    const columns = [
        {
            name: "orderId",
            label: "Order Id",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "customerTrackingNo",
            label: "Customer Tracking Number",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "shipmentLabel",
            label: "Download Shipment Label",
            options: {
                filter: false,
                customHeadRender: () => (
                    <TableCell>
                        <div className="download-report">Download Shipment Label</div>
                    </TableCell>
                ),
                customBodyRender: (value: any) => {
                    return <IconButton disabled={!value} onClick={() => window.open(value)}><GetAppIcon className={value ? "download-icon" : ""} /></IconButton>;
                },
            },
        },
        {
            name: "_id",
            options: {
                filter: false,
                customHeadRender: () => (
                    <TableCell>
                        <div className="download-report">Void Label</div>
                    </TableCell>
                ),
                customBodyRender: (value: any) => {
                    return (<Button className="btn-medium btn-primary-outline"
                        onClick={() => onClickVoid(value)}>Void</Button>)
                },
            },
        },
    ];

    const options = {
        print: false,
        viewColumns: false,
        responsive: "standard",
        downloadOptions: {
            filename: "FilterUsers.csv",
        },
        selectableRows: "none" as SelectableRows,
    };

    return (
        <div className="px-4 py-4">
            {isLoading && <SpinnerLoader />}

            {showVoidLabel && (
                <ConfirmDialog
                    dialogTitle="Void Shipping Label"
                    message="Are you sure want to void this shipping label?"
                    positiveBtnLabel="Confirm"
                    negativeBtnLabel="Cancel"
                    onPositiveBtnClicked={voidShippingLabel}
                    onNegativeBtnClicked={() => setShowVoidLabel(false)}
                    onCloseIconClicked={() => setShowVoidLabel(false)}
                />
            )}

            <div className="user-section">
                <CustomDataTable
                    title="Shipping Labels"
                    rows={labelList}
                    columns={columns}
                    options={options}
                />
            </div>
        </div>
    )
};

export default ShippingLabel;
