import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory, withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { loginApi, loginWithApple, loginWithFacebook, loginWithGoogle, loginWithMicroSoft } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import { setToken, setUser } from "../../redux/actions/userActions";
import { URL } from "../../components/Navbar/routes";
import { RootState } from "../../redux/reducers";
import Iqilogo from '../../images/iqi-cloud-blue-mobile.svg';
import FacebookLogin from 'react-facebook-login';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleSignin from 'react-apple-login';
import MicrosoftLogin from "react-microsoft-login";
import AppleIcon from '@mui/icons-material/Apple';
import WindowSharpIcon from '@mui/icons-material/WindowSharp';
import { FcGoogle } from "react-icons/fc";

import { gapi } from 'gapi-script';
import "./Login.scoped.scss";
import { toast } from "react-toastify";

const Login: React.FC = () => {
	const [errorText, setErrorText] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const redirectUrl = window.location.href;
	const [googleClientId] = useState<string>(process.env.REACT_APP_GOOGLE_OAUTH_CLIEN_ID || "");
	const [faceBookClientId] = useState<string>(process.env.REACT_APP_FACEBOOK_APP_ID || "");

	const history = useHistory();
	const dispatch = useDispatch();

	const userData: any = useSelector((state: RootState) => state.user);

	useEffect(() => {
		const initClient = () => {
			gapi?.client?.init({
				clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIEN_ID,
				scope: ''
			});
		};
		gapi?.load('client:auth2', initClient);
		if (userData?.token) {
			history.push(URL.DASHBOARD);
			setIsLoading(false);
		} else {
			history.push(URL.LOGIN);
			setIsLoading(false);
		}
	}, []);

	const userLogin = async (value: object) => {
		setIsLoading(true);
		const response: any = await loginApi(value);
		if (response.responseObj) {
			console.log(response.responseObj.userData, "User Data");
			setErrorText("");
			dispatch(setToken(response.responseObj.token));
			dispatch(setUser(response.responseObj.userData));
			localStorage.setItem("userId", response.responseObj.userData._id);
			history.push(URL.DASHBOARD);
		} else {
			setErrorText(response.error);
		}
		setIsLoading(false);
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Invalid email address")
				.required("Email is required"),
			password: Yup.string().required("Password is required"),
		}),
		onSubmit: (values) => {
			userLogin(values);
		},
	});

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	const responseFacebook = async (data: any) => {
		console.log(data?.accessToken);
		setIsLoading(true);
		const response: any = await loginWithFacebook({ token: data?.accessToken });
		console.log(JSON.stringify(response));
		setIsLoading(false);
		if (response.responseObj) {
			setErrorText("");
			dispatch(setToken(response.responseObj.token));
			dispatch(setUser(response.responseObj.user));
			localStorage.setItem("userId", response.responseObj.user._id);
			history.push(URL.DASHBOARD);
		} else {
			setErrorText(response.error);
		}
	}

	const responseFromGoogle = async (data: any) => {
		setIsLoading(true);
		const response: any = await loginWithGoogle({ token: data?.id_token });
		setIsLoading(false);
		if (response.responseObj) {
			setErrorText("");
			dispatch(setToken(response.responseObj.token));
			dispatch(setUser(response.responseObj.user));
			localStorage.setItem("userId", response.responseObj.user._id);
			history.push(URL.DASHBOARD);
		} else {
			toast.error(response.error);
		}
	}

	const responseFromApple = async (data: any) => {
		console.log(data);
		setIsLoading(true);
		const response: any = await loginWithApple({ token: data?.authorization?.id_token });
		setIsLoading(false);
		if (response.responseObj) {
			setErrorText("");
			dispatch(setToken(response.responseObj.token));
			dispatch(setUser(response.responseObj.user));
			localStorage.setItem("userId", response.responseObj.user._id);
			history.push(URL.DASHBOARD);
		} else {
			setErrorText(response.error);
		}
	}

	const responseFromMicroSoft = async (err: any, data: any) => {
		if (err) {
			console.log(err);
			sessionStorage?.clear();
		} else if (data?.account && data?.account?.userName) {
			let name = data?.account?.userName?.split('@');
			name = name[0];
			const response: any = await loginWithMicroSoft({
				email: data?.account?.userName,
				firstName: name,
				id: data?.uniqueId
			});
			if (response.responseObj) {
				setErrorText("");
				dispatch(setToken(response.responseObj.token));
				dispatch(setUser(response.responseObj.user));
				localStorage.setItem("userId", response.responseObj.user._id);
				history.push(URL.DASHBOARD);
			} else {
				setErrorText(response.error);
			}
		}
	};

	const onClickGoogle = () => {
		gapi?.auth2?.authorize({
			clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIEN_ID,
			scope: 'email profile openid',
			response_type: 'id_token permission'
		}, responseFromGoogle);
	}

	return (
		<>
			<div className="login-page d-flex">
				<div className="flex-1 login-banner">
					<div className="login-logo" />
					<div className="d-flex align-items-end login-content font-700">
						<div className="text-content">
							<h4 className="fs-60 ls-024 txt-white d-flex flex-column mb-4 lh-72">
								<span className="font-warn-light-1">Filter Forensics</span>
							</h4>
							<p className="desc-xlarge font-white-85 ls-024">
								For a Healthier, Cleaner & Safer Country
							</p>
						</div>
					</div>
				</div>
				<div className="login-content flex-1 bg-white">
					<div className="p-3 login-block">
						<div className="d-flex align-items-center justify-content-end py-2 px-2 sticky-top-0 bg-white login-mobile-nosign">
							<p className="mb-0 mr-3">Don't have an account?</p>
							<a href="#"
								onClick={() => history.push("/sign-up")}
								className="btn btn-outline-primary align-self-center mr-2">
								Sign Up
							</a>
						</div>
						<div className="login-form d-flex flex-column login-main align-items-center">
							<div className="form-section w-100 py-3 mx-auto">
								<div className="w-100 logo-mobile pb-4 mb-2">
									<img className="" src={Iqilogo} alt="" />
									<p className="fs-14 font-400 font-grey-light-4">
										For a Healthier, Cleaner & Safer Country
									</p>
								</div>
								<h4 className="mb-2 fs-24 font-700 ls-024 lh-normal font-text-1">
									Log In
								</h4>
								<p className="fs-14 font-400 ls-024 lh-16 font-grey-light-4 mb-2">
									Please enter your email and password
								</p>
								<div className="mb-2">
									<form onSubmit={formik.handleSubmit}>
										<div className="mb-4 form-group">
											<label className="desc-normal ls-024 font-grey-light-4">
												Email
											</label>
											<input
												type="email"
												id="email"
												name="email"
												className="email bg-white profileinput border-radius-4 mt-2"
												placeholder="Enter your email address"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.email}
											/>
											{formik.touched.email && formik.errors.email ? (
												<div className="desc-medium text-danger mt-1">
													{formik.errors.email}
												</div>
											) : null}
										</div>
										<div className="mb-2 form-group">
											<label className="desc-normal ls-024 font-grey-light-4">
												Password
											</label>
											<div className="password">
												<input
													type={showPassword ? "text" : "password"}
													id="password"
													name="password"
													className="bg-white profileinput border-radius-4"
													placeholder="Enter your password"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.password}
												/>
												<IconButton
													className="icon"
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</div>
											{formik.touched.password && formik.errors.password ? (
												<div className="desc-medium text-danger mt-1">
													{formik.errors.password}
												</div>
											) : null}
										</div>
										<p className="desc-medium text-danger ml-2 pl-1 mt-1 mb-0">
											{" "}
											{errorText ? errorText : ""}{" "}
										</p>
										<p className="mb-10 text-left">
											<Link
												to="/forgotPassword"
												className="mt-2 desc-normal font-dark-blue font-500 fs-14 ls-024 lh-16"
											>
												Forgot Password?
											</Link>
										</p>
										<button
											type="submit"
											className="btn  w-100 mb-4 bg-white"
										>
											Log In
										</button>

										<hr />
									</form>
									<p>Login with your Google, Apple account</p>

									<div className="social-login">
										<div className="social-btn">
											<button className="login-google" onClick={onClickGoogle}>
												<FcGoogle className="mr-3 " />
												Google
											</button>
										</div>

										{/* <div className="mb-3 mt-3 social-btn">
											<FacebookLogin
												appId={faceBookClientId}
												autoLoad={false}
												fields="name,email,picture"
												textButton="Facebook"
												cssClass="login-facebook"
												icon={<FacebookIcon className="mr-3 txt-blue-face" />}
												callback={responseFacebook}
												cookie={false}
												redirectUri={redirectUrl}
												reAuthenticate={true}
											/>
										</div> */}

										{/* <div className="social-btn">
											<MicrosoftLogin
												clientId="82c3e5f9-515d-49f1-a64f-2aec8d1715f1"
												redirectUri={redirectUrl}
												authCallback={responseFromMicroSoft}
												prompt="login"
											>
												<button className="apple-login" onClick={() => {
													formik.setFieldValue("email", "");
													formik.setFieldValue("password", "");
												}
												}>
													<WindowSharpIcon className="mr-3 txt-light-blue" />
													Microsoft
												</button>
											</MicrosoftLogin>
										</div> */}

										<div className="social-btn mb-3">
											<AppleSignin
												clientId="com.filtertester.id"
												scope="email name"
												redirectURI={redirectUrl}
												usePopup={true}
												callback={responseFromApple}
												responseMode="query"
												render={renderProps => (
													<button
														onClick={renderProps.onClick}
														className="apple-login"
													>
														<AppleIcon className="mr-3 txt-black" />
														Apple
													</button>
												)}
											/>
										</div>
									</div>

									<div className="login-mobile-sign">
										<p className="ls-024 fs-14 font-400 font-grey-light-4">
											Don't have an account?{" "}
											<a
												href="#"
												onClick={() => history.push("/sign-up")}
												className="font-500 font-dark-blue"
											>
												Sign up
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{isLoading && <SpinnerLoader />}
			</div >
		</>
	);
};

export default withRouter(Login);
