import React from "react";
import { Button, Modal } from "react-bootstrap";
import ReactPlayer from 'react-player';

interface ActivationSuccess {
    open: boolean;
    nextStep: () => void;
}

export const OrderRegiteredModal: React.FC<ActivationSuccess> = ({
    open,
    nextStep
}) => {
    return (
        <Modal show={open} onHide={nextStep}>
            <Modal.Header closeButton>
                <Modal.Title>Registration Successful</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="font-500">It's Time to Take Your Sample:</h6>
                <h6 className="steps-heading">1. Dab It:</h6>
                <div className="ml-3">
                    <ReactPlayer
                        width={"280px"}
                        height={"180px"}
                        url={process.env.REACT_APP_DAB_IT_VIDEO_LINK}
                        playing={true}
                        controls={true}
                    />
                </div>
                <div className="ml-3 font-500">
                    <p>a. Use the Bio Film and take the sample from your Air filter/ Duct / applicable area </p>
                    <p>b. Peel the sticker off the Bio Film and discard it</p>
                    <p>c. Dab the adhesive side of the Bio Film at various spots on the Air Inflow side of the Filter / Duct/ Applicable area (5-6 dabs are generally good enough).</p>
                    <p>d. Place the Bio Film back to its case and lock it. </p>
                    <p>e. Use the Tamper resistant Labels to properly secure the sides of the Bio Film case.</p>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={nextStep} className="btn-medium">
                    Next Step
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
