import { setToken } from "../redux/actions/userActions";
import { store } from "../redux/store";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface ApiResponse<T> {
  responseObj?: T;
  error?: string;
}

export default async function apiPlugin<T>(
  method: RequestMethod,
  route: string,
  urlParams?: URLSearchParams,
  requestData?: object | any[],
  formData?: FormData,
  isReportData?: boolean,
  isEndAuditReport?: boolean
): Promise<ApiResponse<T>> {
  try {
    let headers: Record<string, string> = {};

    //set authorization header
    const state = store.getState();
    const token: any = state.user.token;

    if (token) {
      headers["Authorization"] = token;
    }

    let body: string | undefined | FormData;
    // TODO: change url path
    let url: string;

    if (isReportData) {
      url = `${process.env.REACT_APP_REPORTS_BASE_URL}`;
    } else if (isEndAuditReport) {
      url = `${process.env.REACT_APP_AUDIT_REPORT_URL}`;
    } else {
      url = `${process.env.REACT_APP_API_BASE_URL}`;
    }
    url += `/${route}${urlParams ? "?" + urlParams.toString() : ""}`;
    if (requestData) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(requestData);
    }

    if (formData) {
      body = formData;
    }

    const res = await fetch(url, {
      method,
      headers,
      body,
    });

    if (res.ok) {
      try {
        const resData = (await res?.json()) as T;
        return {
          responseObj: resData,
        };
      } catch (err) {
        console.log(err);
        return {
          error: "failed to parse response returned by server",
        };
      }
    } else {
      if (res.status === 401) {
        store.dispatch(setToken(""));
      }
      const resData = (await res?.json()) as any;
      return {
        error: resData.message
          ? resData.message
          : resData.error
          ? resData.error
          : "",
      };
    }
  } catch (err: any) {
    let errorMessage = "failed to call api";
    errorMessage = `${errorMessage} - ${err.message}`;
    return {
      error: errorMessage,
    };
  }
}


export async function apiPluginForImageUpload<T>(
	method: RequestMethod,
	route: string,
	requestData?: any
): Promise<ApiResponse<T>> {
	try {
		let headers: Record<string, string> = {};

		//set authorization header
		const state = store.getState();
		const token: any = state.user.token;

		headers["Content-Type"] = "application/octet-stream";

		let body: any;
		body = requestData;
		const res = await fetch(route, {
			method,
			headers,
			body,
		});

		if (res.ok) {
			try {
				const resData = (await res.json()) as T;
				return {
					responseObj: resData,
				};
			} catch (err) {
				console.error(err);
				return {
					error: "failed to parse response returned by server",
				};
			}
		} else {
			if (res.status === 401) {
				store.dispatch(setToken(""));
			}
			const resData = (await res.json()) as any;
			return {
				error: resData.message
					? resData.message
					: resData.error
					? resData.error
					: "",
			};
		}
	} catch (err: any) {
		let errorMessage = "failed to call api";
		errorMessage = `${errorMessage} - ${err.message}`;
		return {
			error: errorMessage,
		};
	}
}
