import { SelectableRows } from "mui-datatables";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AddEditOrganisms } from "./AddEditOrganisms";
import { toast } from "react-toastify";
import { createMicroOrganism, getMicroOrganisms, getOrganismsCharacters, updateOrganism } from "../../api/TestController";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import "./OrganismsList.scss";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Dropdown } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

const OrganismsList = () => {

	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [organismsList, setOrganismsList] = useState<any>([]);
	const [list, setList] = useState<any>([]);
	const [particulatesList, setParticulatesList] = useState<any>([]);
	const [appendixList, setAppendixList] = useState<any>([]);
	const [selectedOrganism, setSelectedOrganism] = useState<any>({});
	const [showDeleteTest, setShowDeleteTest] = useState<boolean>(false);
	const [value, setValue] = useState(0);
	const [charOptions, setCharOptions] = useState<any>([]);

	useEffect(() => {
		getDefinitionsList();
		getOrganismCharacters();

	}, []);

	const getDefinitionsList = async () => {
		setIsLoading(true);
		let response = await getMicroOrganisms();
		setIsLoading(false);
		if (response?.responseObj?.list) {
			setList(response?.responseObj?.list);
			const particulates = response?.responseObj?.list?.filter((l: any) => l.type === 0);
			const mOrganisms = response?.responseObj?.list?.filter((l: any) => l.type === 1);
			const defs = response?.responseObj?.list?.filter((l: any) => l.type === 2);
			setOrganismsList(mOrganisms);
			setParticulatesList(particulates);
			setAppendixList(defs);
		}
	}

	const getOrganismCharacters = async () => {
		setIsLoading(true);
		const response = await getOrganismsCharacters();
		setIsLoading(false);
		if (response?.responseObj?.list) {
			let list = response?.responseObj?.list;
			list = list.map((l: any) => {
				return {
					label: l.name,
					value: l._id
				}
			});
			setCharOptions(list);
		}
	}

	const onDelete = async () => {
		setIsLoading(true);
		let response = await updateOrganism({ isDeleted: true }, selectedOrganism?._id);
		setIsLoading(false);
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj.message) {
			setShowDeleteTest(false);
			toast.success("Deleted successfully");
			getDefinitionsList();
		}
	};

	const onSUbmit = async (values: any) => {
		setIsLoading(true);
		let response;
		if (selectedOrganism._id) {
			response = await updateOrganism({
				...values,
				_id: selectedOrganism._id,
			}, selectedOrganism._id);
			setIsLoading(false);
		} else {
			response = await createMicroOrganism(values);
			setIsLoading(false);
		}
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj.message) {
			setShowDrawer(false);
			getDefinitionsList();
			toast.success(response?.responseObj.message);
		}
	};

	const columns = [
		{
			name: "name",
			label: "Name",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "definition",
			label: "Definition",
			options: {
				filter: false
			},
		},
		{
			name: "_id",
			label: "Actions",
			options: {
				filter: false,
				customBodyRender: (value: string) => {
					return (
						<Dropdown className="">
							<Dropdown.Toggle
								variant="Primary"
								id="dropdown-basic"
								bsPrefix="dropdown-button"
							>
								<MoreHorizIcon />
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ width: "inherit" }}>
								<Dropdown.Item
									className="simple text-break"
									onClick={() => {
										const selectedData: any = list.find(
											(mapData: any) => mapData._id === value
										);
										setSelectedOrganism(selectedData);
										setShowDrawer(true);
									}}
								>
									Edit
								</Dropdown.Item>

								<Dropdown.Item
									className="simple text-break"
									onClick={() => {
										const selectedData: any = list.find(
											(mapData: any) => mapData._id === value
										);
										setSelectedOrganism(selectedData);
										setShowDeleteTest(true);
									}}
								>
									Delete
								</Dropdown.Item>

							</Dropdown.Menu>
						</Dropdown>
					);
				},
			},
		}
	];

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const options = {
		download: false,
		print: false,
		viewColumns: false,
		responsive: "standard",
		selectableRows: "none" as SelectableRows,
		rowsPerPage: 100
	};

	function a11yProps(index: any) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}


	function TabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	return (
		<div className="px-4 pt-4 pb-5">
			<div className="d-flex justify-content-end mb-4">
				<Button onClick={() => {
					setShowDrawer(true);
					setSelectedOrganism({});
				}
				} className="btn mr-0 btn-medium">
					Add New
				</Button>
			</div>

			{isLoading && <SpinnerLoader />}

			{showDrawer && (
				<AddEditOrganisms
					index={value}
					open={showDrawer}
					onclose={() => setShowDrawer(false)}
					formData={selectedOrganism}
					onSUbmit={onSUbmit}
					isLoading={isLoading}
					charOptions={charOptions}
				/>
			)}

			{showDeleteTest && (
				<ConfirmDialog
					dialogTitle="Delete"
					message="Are you sure want to delete the organism?"
					positiveBtnLabel="Confirm"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={onDelete}
					onNegativeBtnClicked={() => setShowDeleteTest(false)}
					onCloseIconClicked={() => setShowDeleteTest(false)}
				/>
			)}
			<div className="microb-tab-section">
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="simple tabs example"
					indicatorColor="primary"
				>
					<Tab label="Particulates" {...a11yProps(0)} />
					<Tab label="Micro Organisms" {...a11yProps(1)} />
					<Tab label="Appendix" {...a11yProps(2)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<CustomDataTable
						title="Particulates"
						rows={particulatesList}
						columns={columns}
						options={options}
					/>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<CustomDataTable
						title="Micro Organisms"
						rows={organismsList}
						columns={columns}
						options={options}
					/>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<CustomDataTable
						title="Appendix"
						rows={appendixList}
						columns={columns}
						options={options}
					/>
				</TabPanel>
			</div>
		</div>)
};

export default OrganismsList;
