import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	updateFilterTest,
	uploadFile,
	getFilterTestReadings,
	generateTestReport,
	uploadModifiedReport,
	getMicroOrganisms,
	updateFilterTestReadings,
	getOrganismsCharacters,
	getQuestionnaires
} from "../../api/TestController";
import { RootState } from "../../redux/reducers";
import { ChevronLeftOutlined } from "@material-ui/icons";
import "./AddReportData.scoped.scss";
import { useHistory } from "react-router-dom";
import { SpinnerLoader } from "../../common/Global.Style";
import { cloneDeep, isEmpty, isNumber, sortBy } from "lodash";
import { Button } from "react-bootstrap";
import PreviewDialog from "../../components/PreviewModal/PreviewModal";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as XLSX from 'xlsx';
import Compressor from "compressorjs";
import Select from "react-select";
import { FilterTestImagesModal } from "../../components/FilterTestImages/FilterTestImages";

const AddReportData: React.FC = () => {
	const test = useSelector((state: RootState) => state.test);
	const statusText: any = {
		0: "Order Created",
		1: "Customer Registered",
		2: "Test Kit Received",
		3: "Lap Prep In Progress",
		4: "Lab Testing In Progress",
		5: "Lab Test Report Available"
	}
	const fLocText: any = {
		"air_filter": "Air Filter",
		"in_duct": "In Duct",
		"others": "Others"
	}
	const bTypeText: any = {
		"residential": "Residential",
		"commercial": "Commercial",
		"industrial": "Industrial"
	}
	const filterRatingText: any = {
		"merv6": "MERV 6",
		"merv8": "MERV 8",
		"merv11": "MERV 11",
		"merv13": "MERV 13",
		"other": "Other",
		"do_not_know": "Do not Know"
	}
	const lastChangeText: any = {
		"within_1_month": "Within 1 Month",
		"1-3_month": "1-3 Months",
		"3-6_months": "3-6 Months",
		"6-12_months": "6-12 Months",
		"more_than_12_months": "> 12 Months",
		"do_not_know": "Do not Know"
	}
	const thermoStatText: any = {
		"auto": "Auto",
		"manual": "Manual"
	}
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [showOriginalReportPreview, setShowOriginalReportPreview] = useState<boolean>(false);
	const [reportUrl, setReportUrl] = useState<string>("");
	const [originalReport, setOriginalReport] = useState<string>("");
	const [organismsList, setOrganismsList] = useState<any>([]);
	const [appendixList, setAppendixList] = useState<any>([]);
	const history = useHistory();
	const [images, setImages] = useState<string[]>([]);
	const [particulate, setParticulate] = useState<any>([]);
	const [organisms, setOrganisms] = useState<any>([]);
	const [charOptions, setCharOptions] = useState<any>([]);
	const [questionnaire, setQuestionnaire] = useState<any>({});
	const [rawParticulateList, setRawParticulateList] = useState<any>([]);
	const [rawOrganismsList, setRawOrganismsList] = useState<any>([]);
	const [importFormats] = useState<any[]>(
		[
			{ label: "AEML Format", value: "aeml", accept: ".xlsx" },
			{ label: "HAYES Format", value: "heys", accept: ".csv" }
		]
	);
	const [selectedImportFormat, setSelectedImportFormat] = useState<any>(importFormats[0]);
	const [selectedFileFormat, setSelectedFileFormat] = useState<string>(".xlsx");
	const [showFilterImages, setShowFilterImages] = useState<boolean>(false);

	useEffect(() => {
		getMicroOrganismsList();
		getOrganismCharacters();
		getQuestionnaire();
	}, []);

	const getTestReadings = async (particulatesList: any[], organismsList: any[]) => {
		setIsLoading(true);
		let response = await getFilterTestReadings(test?._id);
		setIsLoading(false);
		if (response?.responseObj && response?.responseObj?.test) {
			let test: any = response?.responseObj?.test;
			let tempParticulateArr: any[] = [];
			let tempOrganismsArray: any[] = [];
			if (test?.particulate) {
				tempParticulateArr = test.particulate;
				tempParticulateArr = particulatesList.map((p: any) => ({ ...p, ...tempParticulateArr.find((sp: any) => sp.name === p.name) }));
				setParticulate(tempParticulateArr);
			}
			if (test?.organisms) {
				tempOrganismsArray = test?.organisms;
				tempOrganismsArray = organismsList.map((p: any) => ({ ...p, ...tempOrganismsArray.find((sp: any) => sp.name === p.name) }));
				setOrganisms(tempOrganismsArray);
			}
			setImages(test?.images);
			setOriginalReport(test?.originalReport);
		}
	}

	const handleCSVFile = async (file: any) => {
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);
		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const jsonData: any = XLSX.utils.sheet_to_json(worksheet, {
			header: 1,
			defval: "",
			blankrows: false
		});
		let rows: any[] = [];
		let keyStartingIndex: number = 0;
		let dataIndex: number = 0;

		if (!jsonData.length) {
			toast.error("Please Upload valid File");
		} else {
			for (let i = 0; i < jsonData.length; i++) {
				let keyRow = jsonData[i]?.filter((d: any) => d?.length && (d.includes("Count") || d.includes("count")));
				if (keyRow.length) {
					dataIndex = i;
					break;
				}
			}

			let headers = jsonData[dataIndex];
			for (let i = 0; i < headers.length; i++) {
				if (headers[i]?.includes("Count") || headers[i]?.includes("count")) {
					keyStartingIndex = i;
					break;
				}
			}
			headers = headers.splice(keyStartingIndex, headers.length - keyStartingIndex);
			let readings = jsonData[dataIndex + 1];
			readings = readings.splice(keyStartingIndex, readings.length - keyStartingIndex);

			for (let i = 0; i < headers.length - 1;) {
				let name = headers[i].replace("Normalized Count", "")
				rows.push({
					name: name?.trim(),
					rCount: readings[i + 1],
					count: readings[i]
				})
				i += 2;
			}
			rows = rows.filter(r => r.rCount || r.count);
			const particulate = rawParticulateList?.map((p: any) => ({ ...p, ...rows.find((sp: any) => sp.name.toLowerCase() === p.name.toLowerCase()) }));
			setParticulate(particulate);
			const organism = rawOrganismsList?.map((p: any) => ({ ...p, ...rows.find((sp: any) => sp.name.toLowerCase() === p.name.toLowerCase()) }));
			setOrganisms(organism);
			toast.success("Readings Imported Successfully");
		}
	}

	const handleFiles = async (file: any) => {
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);
		let sheetName = "";
		if (workbook.SheetNames.length > 1) {
			if (workbook.SheetNames.includes("6. Client Sample Results")) {
				sheetName = "6. Client Sample Results";
			} else if (workbook.SheetNames.includes("Client Sample Results")) {
				sheetName = "Client Sample Results";
			}
			else if (workbook.SheetNames.includes("client sample results")) {
				sheetName = "client sample results";
			}
		}

		const worksheet = sheetName.length !== 0 ? workbook.Sheets[sheetName] : workbook.Sheets[workbook.SheetNames[0]];
		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			header: 1,
			defval: "",
			blankrows: false
		});
		let rows: any[] = [];
		if (jsonData.length) {
			jsonData.forEach((data: any) => {
				const row = data.filter((d: any) => !isEmpty(d) || isNumber(d));
				if (!isNumber(row[0])) {
					rows.push({
						name: row[0]?.trim(),
						rCount: isNumber(row[1]) ? row[1] : null,
						count: isNumber(row[2]) ? row[2] : null,
						perc: isNumber(row[3]) ? row[3] : null,
					});
				}

			});
			const particulate = rawParticulateList.map((p: any) => ({ ...p, ...rows.find((sp: any) => sp.name.toLowerCase() === p.name.toLowerCase()) }));
			setParticulate(particulate);
			const organism = rawOrganismsList.map((p: any) => ({ ...p, ...rows.find((sp: any) => sp.name.toLowerCase() === p.name.toLowerCase()) }));
			setOrganisms(organism);
			toast.success("Readings Imported Successfully");
		} else {
			toast.error("Please Upload valid File");
		}
	}

	const uploadTestReport = async () => {
		const response = await uploadModifiedReport(
			{
				modifiedReport: reportUrl,
				particulate,
				organisms,
				images
			},
			test?._id);
		if (response?.responseObj) {
			toast.success("Report uploaded successfully");
			history.goBack();
		} else if (response?.error) {
			toast.error(response?.error);
		}
	}

	const splitArray = (arr: any, rows: any) => {
		const itemsPerRow = Math.ceil(arr.length / rows);
		return arr.reduce((acc: any, val: any, ind: number) => {
			val.characteristics = val.characteristics?.filter((c: any) => c.name !== "N/A");
			const currentRow = Math.floor(ind / itemsPerRow);
			val.characteristics = val.characteristics?.filter((c: any) => c.name !== "N/A");
			if (!acc[currentRow]) {
				acc[currentRow] = [val];
			} else {
				acc[currentRow].push(val);
			};
			return acc;
		}, []);
	};

	const saveFilterTestReadings = async () => {
		if (!originalReport?.length) {
			toast.error("Please Upload Original Report");
			return;
		}

		setIsLoading(true);
		const response = await updateFilterTestReadings(
			test._id,
			{
				test,
				particulate,
				organisms,
				images
			}
		);
		setIsLoading(false);
		if (response?.responseObj) {
			await generateReport();
		} else if (response?.error) {
			toast.error(response?.error);
		}
	}

	const generateReport = async () => {
		setShowPreview(false);
		setIsLoading(true);
		let oLen: number = organismsList.length;
		let aLen: number = appendixList.length;

		const response = await generateTestReport(
			{
				test,
				particulate,
				organisms,
				oTableData: splitArray(organisms, organisms.length / 20),
				pTableData: splitArray(particulate, particulate.length / 20),
				orgDefinitions: splitArray(organismsList, oLen / 3),
				definitions: splitArray(appendixList, aLen / 6),
				images: questionnaire?.images,
				surroundingImages: questionnaire?.surroundingImages,
				characteristics: charOptions,
				questionnaire,
				isQuestionnaire: isEmpty(questionnaire) ? "no" : "yes",
				isImg: questionnaire?.images?.length ? "yes" : "no",
				isSurrImg: questionnaire?.surroundingImages?.length ? "yes" : "no",
				refImages: images,
				isRefImg: images.length ? "yes" : "no"
			});
		setIsLoading(false);
		if (response?.responseObj?.response) {
			setReportUrl(response?.responseObj?.response);
			setShowPreview(true);
		} else if (response?.error) {
			toast.error(response?.error);
		}
	}

	const onChooseImage = async (event: any, type: string) => {
		const apiBody = new FormData();
		let [file] = event.target.files;

		if (type === "report") {
			if (file?.size && file.size / 1e6 > 4) {
				toast.error("File size should be lesser than 4 MB");
				return;
			}
			apiBody.append("file", file);
			const response = await uploadFile(apiBody);
			if (response?.error) {
				toast.error(response?.error);
			} else if (type === "report") {
				setIsLoading(true);
				setOriginalReport(response?.responseObj?.Location);
				await updateFilterTest({ originalReport: response?.responseObj?.Location }, test?._id);
				toast.success("File uploaded successfully");
				setIsLoading(false);
			}
		} else {
			new Compressor(file, {
				quality: 0.8,
				width: 150,
				height: 200,
				success: async (compressedResult) => {
					apiBody.append("file", compressedResult);
					const response = await uploadFile(apiBody);
					if (response?.error) {
						toast.error(response?.error);
					} else {
						const tempImage = response?.responseObj?.Location;
						setImages([...images, tempImage]);
					}
				}
			});
		}
	}

	const goBack = () => {
		history.goBack();
	};

	const getOrganismCharacters = async () => {
		setIsLoading(true);
		const response = await getOrganismsCharacters();
		setIsLoading(false);
		if (response?.responseObj?.list) {
			let list = response?.responseObj?.list;
			list = list.filter((l: any) => l.name !== "N/A");
			setCharOptions(list);
		}
	}

	const getQuestionnaire = async () => {
		if (!test?.location?._id) {
			return;
		}

		setIsLoading(true);
		const response = await getQuestionnaires(test?.location?._id);
		setIsLoading(false);
		if (response?.responseObj?.questionnaire) {
			let questionnaire: any = response?.responseObj?.questionnaire;
			questionnaire.filterLocation = fLocText[questionnaire.filterLocation];
			questionnaire.filterRating = filterRatingText[questionnaire.filterRating];
			questionnaire.lastFilterChange = lastChangeText[questionnaire.lastFilterChange];
			questionnaire.buildingType = bTypeText[questionnaire.buildingType];
			questionnaire.thermostatSettings = thermoStatText[questionnaire.thermostatSettings];
			setQuestionnaire(questionnaire);
		}
	}

	const getMicroOrganismsList = async () => {
		setIsLoading(true);
		let response = await getMicroOrganisms();
		setIsLoading(false);

		let particulatesList = [];
		let organismsList = [];

		if (response?.responseObj?.list) {
			const organisms = response?.responseObj?.list?.filter((l: any) => l.type === 0 || l.type === 1);
			const defs = response?.responseObj?.list?.filter((l: any) => l.type === 2);
			setOrganismsList(organisms);
			setAppendixList(defs);
		}

		for (const data of response.responseObj.list) {
			switch (data.type) {
				case 0:
					particulatesList.push(
						{
							name: data.name,
							id: data._id?.toString(),
							rCount: null,
							count: null,
							perc: null
						}
					);
					break;
				case 1:
					organismsList.push(
						{
							name: data.name,
							rCount: null,
							count: null,
							perc: null,
							id: data._id?.toString()
						}
					);
					break;
				default:
					break;
			}
		}

		if (organismsList.length) {
			const total: any = {
				name: "Total",
				rCount: null,
				count: null,
				perc: null
			};
			organismsList = sortBy(organismsList, ['name']);
			organismsList.push(total)
			setOrganisms(organismsList);
		}

		if (particulatesList.length) {
			const total: any = {
				name: "Total",
				count: null,
				perc: null,
				rCount: null
			};

			particulatesList = sortBy(particulatesList, ['name']);
			particulatesList.push(total);
			setParticulate(particulatesList);
		}
		setRawParticulateList(particulatesList);
		setRawOrganismsList(organismsList);
		getTestReadings(particulatesList, organismsList);
	}

	const onChangeParticulateValue = (value: any, key: number, type: string = "") => {
		let tempParticulate: any = cloneDeep(particulate);
		if (type === "count") {
			tempParticulate[key].count = parseFloat(value);
		} else if (type === "rCount") {
			tempParticulate[key].rCount = parseFloat(value);
		} else if (type === "problems") {
			tempParticulate[key].problems = value;
		} else if (type === "solutions") {
			tempParticulate[key].solutions = value;
		} else {
			tempParticulate[key][type] = value;
		}
		setParticulate(tempParticulate);
	}

	const onChangeOrganismsValue = (value: any, key: number, type: string = "") => {
		let tempOrganisms: any = cloneDeep(organisms);
		if (type === "count") {
			tempOrganisms[key].count = parseFloat(value);
		} else if (type === "rCount") {
			tempOrganisms[key].rCount = parseFloat(value);
		} else if (type === "problems") {
			tempOrganisms[key].problems = value;
		} else if (type === "solutions") {
			tempOrganisms[key].solutions = value;
		} else {
			tempOrganisms[key][type] = value;
		}
		setOrganisms(tempOrganisms);
	}

	const renderParticle = () => particulate?.map((val: any, key: number) => {
		return <div className="d-flex mt-3">
			<div className="col-md-2 d-flex align-items-center">
				<label>{val?.name}</label>
			</div>
			<div className="col-md-2">
				<input type="number" placeholder="Raw Count" value={val?.rCount} autoComplete="off"
					onChange={(e) => onChangeParticulateValue(e?.target?.value, key, "rCount")}
				/>
			</div>
			<div className="col-md-1">
				<input type="number" placeholder="Count" value={val?.count} autoComplete="off"
					onChange={(e) => onChangeParticulateValue(e?.target?.value, key, "count")} />
			</div>
			<div className="col-md-1">
				<input placeholder="%" value={val?.perc} autoComplete="off"
					onChange={(e) => onChangeParticulateValue(e?.target?.value, key, "perc")} />
			</div>
			<div className="col-md-3">
				<textarea
					name="problems"
					id="problems"
					value={val?.problems}
					rows={2}
					onChange={(e: any) => onChangeParticulateValue(e?.target?.value, key, "problems")}
				/>
			</div>
			<div className="col-md-3">
				<textarea
					name="solutions"
					id="solutions"
					value={val?.solutions}
					rows={2}
					onChange={(e: any) => onChangeParticulateValue(e?.target?.value, key, "solutions")}
				/>
			</div>
		</div>
	})

	const renderMicroOrganisms = () => organisms?.map((val: any, key: number) => {
		return <div className="d-flex mt-3">
			<div className="col-md-2 d-flex align-items-center">
				<label>{val?.name}</label>
			</div>
			<div className="col-md-2">
				<input type="number" placeholder="Raw Count" value={val?.rCount} autoComplete="off"
					onChange={(e) => onChangeOrganismsValue(e?.target?.value, key, "rCount")}
				/>
			</div>
			<div className="col-md-1">
				<input type="number" placeholder="Count" value={val?.count} autoComplete="off"
					onChange={(e) => onChangeOrganismsValue(e?.target?.value, key, "count")}
				/>
			</div>
			<div className="col-md-1">
				<input placeholder="%" value={val?.perc} autoComplete="off"
					onChange={(e) => onChangeOrganismsValue(e?.target?.value, key, "perc")} />
			</div>
			<div className="col-md-3">
				<textarea
					name="problems"
					id="problems"
					value={val?.problems}
					rows={2}
					onChange={(e: any) => onChangeOrganismsValue(e?.target?.value, key, "problems")}
				/>
			</div>
			<div className="col-md-3">
				<textarea
					name="solutions"
					id="solutions"
					value={val?.solutions}
					rows={2}
					onChange={(e: any) => onChangeOrganismsValue(e?.target?.value, key, "solutions")}
				/>
			</div>
		</div>
	});

	const onClickCloseIcon = (index: number) => {
		images.splice(index, 1);
		setImages([...images]);
	}

	const onClickCloseReport = () => {
		setOriginalReport("");
	}

	return (
		<div className="add-report-container flex-column mb-4">
			{isLoading && <SpinnerLoader />}
			{showPreview && <PreviewDialog
				onPositiveBtnClicked={() => uploadTestReport()}
				onNegativeBtnClicked={() => setShowPreview(false)}
				onCloseIconClicked={() => setShowPreview(false)}
				reportUrl={reportUrl}
				title="Report Preview"
				positiveButtonLabel="Upload"
			/>
			}
			{showOriginalReportPreview && <PreviewDialog
				onPositiveBtnClicked={() => setShowOriginalReportPreview(false)}
				onNegativeBtnClicked={() => setShowOriginalReportPreview(false)}
				onCloseIconClicked={() => setShowOriginalReportPreview(false)}
				reportUrl={originalReport}
				title="Original Report Preview"
				positiveButtonLabel=""
			/>
			}
			{
				showFilterImages && <FilterTestImagesModal
					images={[...questionnaire?.images, ...questionnaire?.surroundingImages]}
					show={showFilterImages}
					onClose={() => setShowFilterImages(false)}
				/>
			}
			<div className="py-3 px-3 bg-blue-light-1">
				<div className="d-flex align-items-center w-fit cursor-pointer" onClick={() => goBack()}>
					<ChevronLeftOutlined className="txt-primary mr-2" />
					<p className="m-0 desc-large font-500 ls-024">Back</p>
				</div>
			</div>
			<div className="report-container mt-3 pl-3">
				<div className="report-wrap col-md-6 flex-column border-radius-4 p-4">
					<div className="d-flex align-items-center justify-content-between mb-4">
						<p className="desc-large ls-024 font-600 font-black-85 mb-0">{test?.orderId}</p>
						<p className="px-2 py-1 desc-large ls-024 font-600 font-black-85 mb-0">{statusText[test?.status]}</p>
					</div>

					<div className="d-flex pb-4 mb-4 border-b">
						<div className="flex-colum mr-3 min-w-120">
							<h5 className="font-600 mb-2">Filter Details</h5>
							<div className="d-flex">
								<p className="ls-024 font-600 font-black-85">Filter Location:</p>
								<p className="ls-024 font-400 font-black-85 ml-3">{questionnaire?.filterLocation}</p>
							</div>
							<div className="d-flex">
								<p className="ls-024 font-600 font-black-85">Filter Rating:</p>
								<p className="ls-024 font-400 font-black-85 ml-3">{questionnaire?.filterRating}</p>
							</div>
							<div className="d-flex">
								<p className="ls-024 font-600 font-black-85">Last Filter Change:</p>
								<p className="ls-024 font-400 font-black-85 ml-3">{questionnaire?.lastFilterChange}</p>
							</div>
							<div className="d-flex">
								<p className=" ls-024 font-600 font-black-85">Building Type:</p>
								<p className="ls-024 font-400 font-black-85 ml-3">{questionnaire?.buildingType}</p>
							</div>
							<div className="d-flex">
								<p className="ls-024 font-600 font-black-85">Temperature:</p>
								<p className="ls-024 font-400 font-black-85 ml-3">{questionnaire?.temperature}</p>
							</div>
							<div className="d-flex">
								<p className="ls-024 font-600 font-black-85">Thermostat Settings:</p>
								<p className="ls-024 font-400 font-black-85 ml-3">{questionnaire?.thermostatSettings}</p>
							</div>
							<div className="d-flex">
								<p className="ls-024 font-600 font-black-85">Images uploaded by user:</p>
								<Button
									variant="secondary"
									className="btn-medium ml-3"
									onClick={() => setShowFilterImages(true)}
								>View</Button>
							</div>

						</div>
					</div>

					<div>
						<h4 className="desc-large lh-20 ls-024 font-600 font-black-85 mb-2">Upload Original Report</h4>
						<span>Upload the original report provided by the lab.</span>
						<div className="ml-4 mt-4">
							<div className="d-flex justify-content-start">
								<input
									accept="application/pdf"
									style={{ display: "none" }}
									id="contained-button-report"
									type="file"
									onClick={(event: any) => {
										event.target.value = null;
									}}
									onChange={(event: any) => onChooseImage(event, "report")}
								/>
								<div className="text-right d-flex">
									<label htmlFor="contained-button-report">
										<p className="btn add-device-btn btn-medium upload-btn cursor-pointer m-0">
											{originalReport ? "Re-Upload Report" : "Upload Report"}
										</p>
									</label>
									<div className="d-flex flex-wrap report-data-main ml-3">
										{originalReport &&
											<div className="report-data-image">
												<div className="d-flex justify-content-between align-items-center">
													<PictureAsPdfIcon className="pdf-icon" />
													<div className="d-flex align-items-center">
														<p
															onClick={() => setShowOriginalReportPreview(true)}
															className="mb-0 font-400 f-s-14 lh-16 ls-024 text-truncate max-w-100 txt-black cursor-pointer">
															Lab Report
														</p>
													</div>
													<CloseIcon
														className="cursor-pointer ml-1 close-icon"
														onClick={() => onClickCloseReport()}
													/>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-5 pl-3 d-flex align-items-center">
				<NoteAddIcon className="mb-2 mr-2 note-icon" />
				<h3 className="desc-xlarge font-600 ls-024 font-black-85 lh-24">iQi Lab Report</h3>
			</div>

			<div className="mb-3 ml-3 bg-white p-24 org-wrap">
				<h4 className="desc-large lh-20 ls-024 font-600 font-black-85 mb-2 pb-8">Reference Images</h4>
				<span>Upload Reference Images</span>
				<div className="mt-4">
					<div className="d-flex justify-content-start">
						<input
							accept="image/*"
							style={{ display: "none" }}
							id="contained-button-file"
							type="file"
							onClick={(event: any) => {
								event.target.value = null;
							}}
							onChange={(event: any) => onChooseImage(event, "img")}
						/>
						<div className="text-right pb-3">
							<label htmlFor="contained-button-file">
								<p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
									Upload
								</p>
							</label>
						</div>
					</div>
				</div>
				{images?.length !== 0 && images.map((img: string, index: number) => {
					return (
						<div className="d-flex flex-wrap report-data-main">
							<div className="report-data-image">
								<div className="d-flex justify-content-between align-items-center">
									<div className="d-flex align-items-center">
										<img src={img} className="test-img" />
									</div>
									<CloseIcon
										className="cursor-pointer ml-1 close-icon"
										onClick={() => onClickCloseIcon(index)}
									/>
								</div>
							</div>
						</div>
					)
				}
				)}
			</div>

			<div className="mb-3 ml-3 bg-white p-24 org-wrap">
				<input
					accept={selectedFileFormat}
					style={{ display: "none" }}
					id="images"
					type="file"
					onClick={(event: any) => {
						event.target.value = null;
					}}
					onChange={(event: any) => {
						if (selectedFileFormat === ".xlsx") {
							handleFiles(event?.target?.files[0]);
						} else if (selectedFileFormat === ".csv") {
							handleCSVFile(event?.target?.files[0]);
						}
					}}
				/>
				<div className="pb-2 d-flex">
					<label htmlFor="images">
						<p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
							Import File
						</p>
					</label>
					<div className="password ml-5">
						<Select
							name="colors"
							options={importFormats}
							placeholder="Select existing location"
							className="basic-multi-select"
							classNamePrefix="select"
							value={selectedImportFormat}
							onChange={(value) => {
								setSelectedImportFormat(value);
								setSelectedFileFormat(value?.accept);
							}}
						/>
					</div>
				</div>
				<p className="desc-large">Import Readings from Excel File</p>
			</div>

			<div className="flex-column org-wrap ml-3 mt-4">
				<div className="d-flex mt-3">
					<div className="col-md-2 d-flex align-items-center">
						<label className="font-bold">Particulate</label>
					</div>
					<div className="col-md-2 d-flex align-items-center">
						<label className="font-bold">Raw Count</label>
					</div>
					<div className="col-md-1 d-flex align-items-center">
						<label className="font-bold">Count / cm2</label>
					</div>
					<div className="col-md-1 d-flex align-items-center">
						<label className="font-bold">% Of Total</label>
					</div>
					<div className="col-md-3 d-flex align-items-center">
						<label className="font-bold">Observations</label>
					</div>
					<div className="col-md-3 d-flex align-items-center">
						<label className="font-bold">Recommendations</label>
					</div>
				</div>
				{renderParticle()}
				<div className="my-3 d-flex">
					<div className="col-md-4"></div>
					<div className="col-md-8 px-3">
						<div className="horizontal-line"></div>
					</div>
				</div>
			</div>

			<div className="flex-column org-wrap ml-3 mt-3">
				<div className="d-flex mt-3">
					<div className="col-md-2 d-flex align-items-center">
						<label className="font-bold">Organisms</label>
					</div>
					<div className="col-md-2 d-flex align-items-center">
						<label className="font-bold">Raw Count</label>
					</div>
					<div className="col-md-1 d-flex align-items-center">
						<label className="font-bold">Count / cm2</label>
					</div>
					<div className="col-md-1 d-flex align-items-center">
						<label className="font-bold">% Of Total</label>
					</div>
					<div className="col-md-3 d-flex align-items-center">
						<label className="font-bold">Observations</label>
					</div>
					<div className="col-md-3 d-flex align-items-center">
						<label className="font-bold">Recommendations</label>
					</div>
				</div>
				{renderMicroOrganisms()}
			</div>

			<div className="my-3 d-flex">
				<div className="col-md-4"></div>
				<div className="col-md-8 px-3">
					<div className="horizontal-line"></div>
				</div>
			</div>

			<div className="col-md-12 sticky-bottom-0">
				<div className="d-flex justify-content-end py-3">
					<Button className="btn btn-submit btn-medium" onClick={saveFilterTestReadings}>
						Preview & Save
					</Button>
				</div>
			</div>
		</div >
	);
};

export default AddReportData;
