import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useEffect, useState } from "react";
import Select from "react-select";
import { getFilterAdminsList } from "../../api/UserController";

interface IAddProductProps {
	open: boolean;
	onclose: () => void;
	onSUbmit: (values: any) => void;
}

export const AddBulkTest = ({
	open,
	onclose,
	onSUbmit,
}: IAddProductProps) => {

	let validationSchema: any = {
		name: Yup.string().trim().required("Test Name is required"),
		count: Yup.number().min(1).required("Order count is required"),
		admin: Yup.object({
			label: Yup.string().required("Admin is required"),
			value: Yup.string().required("Admin is required"),
		}).required("Admin is required"),
	};
	const [adminOptions, setAdminOptions] = useState<any[]>([]);

	const formik = useFormik({
		initialValues: {
			name: "",
			count: 0,
			resellerName: "",
			admin: { label: "", value: "" },
		},
		validationSchema: Yup.object().shape(validationSchema),
		onSubmit: ({
			name,
			resellerName,
			count,
			admin,
		}) => {
			onSUbmit({
				name,
				resellerName,
				count,
				admin: admin.value,
			});
		},
	});

	useEffect(() => {
		listFilterAdmins();
	}, []);

	const listFilterAdmins = async () => {
		const response = await getFilterAdminsList();
		if (response?.responseObj?.list?.length) {
			setAdminOptions(
				response.responseObj.list.map((l: any) => {
					return { value: l?._id, label: `${l?.firstName}${l?.lastName}` }
				})
			)
		}
	}

	return (
		<Drawer anchor={"right"} open={open} onClose={onclose}>
			<div className="d-flex justify-content-between p-3">
				<p className="align-items-center d-flex mb-0">Add Bulk Filter Test</p>
				<IconButton onClick={onclose}>
					<CloseIcon />
				</IconButton>
			</div>

			<FormikProvider value={formik}>
				<form className="p-4" onSubmit={formik.handleSubmit}>
					<div className="mb-3 form-group">
						<label className="email">Test Name</label>
						<input
							type="text"
							id="name"
							name="name"
							className="bg-light border-0 mt-2"
							placeholder="Filter Test Name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
						/>
						{formik.touched.name && formik.errors.name ? (
							<div className="text-danger">{formik.errors.name}</div>
						) : null}
					</div>

					<div className="mb-3 form-group">
						<label className="pass mb-2">Assigned Admin</label>
						<div className="password">
							<Select
								name="colors"
								options={adminOptions}
								className="basic-multi-select"
								classNamePrefix="select"
								value={formik.values.admin}
								onChange={(value: any) => formik.setFieldValue("admin", value)}
							/>
						</div>
						{formik.touched?.admin && formik.errors.admin?.value ? (
							<div className="text-danger">
								{formik.errors.admin?.value}
							</div>
						) : null}
					</div>

					<div className="mb-3 form-group">
						<label className="email">Reseller Name</label>
						<input
							type="text"
							id="resellerName"
							name="resellerName"
							className="bg-light border-0 mt-2"
							placeholder="Reseller Name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.resellerName}
						/>
						{formik.touched.resellerName && formik.errors.resellerName ? (
							<div className="text-danger">{formik.errors.resellerName}</div>
						) : null}
					</div>

					<div className="mb-3 form-group">
						<label className="email">Order Count</label>
						<input
							type="number"
							id="count"
							name="count"
							className="bg-light border-0 mt-2"
							placeholder="Filter Test Name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.count}
						/>
						{formik.touched.count && formik.errors.count ? (
							<div className="text-danger">{formik.errors.count}</div>
						) : null}
					</div>

					<button type="submit" className="btn w-100 mb-3">
						Add
					</button>
				</form>
			</FormikProvider>
		</Drawer>
	);
};
