import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";

interface PrivateRouteProps extends RouteProps {
	// tslint:disable-next-line:no-any
	component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
	const { component: Component, ...rest } = props;
	const token = useSelector((state: RootState) => state.user.token);

	return (
		<Route
			{...rest}
			render={(routeProps) => {
				if (!token) {
					return (
						<Redirect
							to={{
								pathname: "/login",
							}}
						/>
					);
				}
				return <Component {...routeProps} />;
			}}
		/>
	);
};

export default PrivateRoute;
