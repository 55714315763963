import React from "react";
import MUIDataTable from "mui-datatables";

interface CustomDataTableProps {
  title: string;
  rows: any;
  columns: any;
  options: any;
}

const CustomDataTable: React.FC<CustomDataTableProps> = ({
  title,
  rows,
  columns,
  options,
}) => {
  return (
    <div className="filter-table">
      <MUIDataTable
        title={title}
        data={rows}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default CustomDataTable;
