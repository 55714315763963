import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavIcon = styled(Link)`
	margin-left: 2rem;
	font-size: 2rem;
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const SidebarNav = styled.nav`
	background: ${(props) => props.theme.sideNavBgColor};
	height: 100vh;
	display: flex;
	justify-content: center;
	top: 0;
	left: 0;
	transition: 350ms;
	z-index: 10;
	padding-top: 5% !important;
	padding: 10px;
	overflow-y: scroll !important;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
`;

export const SidebarWrap = styled.div`
	width: 100%;
`;

export const SidebarLink = styled(Link)`
	display: flex;
	color: ${(props) => props.theme.sideNavTextColor};
	justify-content: space-between;
	align-items: center;
	padding: 20px 12px;
	list-style: none;
	height: 50px;
	text-decoration: none;
	font-size: 14px !important;
	font-weight: 600;
	span{ 
		font-size: 14px;
	}

	&:hover {
		background: ${(props) => props.theme.sideNavHoverBoxColor};
		cursor: pointer;
		border-radius: 4px;
		color: ${(props) => props.theme.sideNavBgColor};
	}
`;

export const SidebarCollapsibleLabel = styled.span`
	display: flex;
	color: ${(props) => props.theme.sideNavTextColor};
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	list-style: none;
	height: 50px;
	text-decoration: none;
	font-size: 16px;

	&:hover {
		background: ${(props) => props.theme.sideNavHoverBoxColor};
		cursor: pointer;
		border-radius: 10px;
		color: ${(props) => props.theme.sideNavBgColor};
	}
`;

export const SidebarLabel = styled.span`
	margin-left: 16px;
`;

export const DropdownLink = styled(Link)`
	background: ${(props) => props.theme.sideNavBgColor};
	height: 50px;
	padding-left: 1rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: ${(props) => props.theme.sideNavTextColor};
	font-size: 14px;
	&:hover {
		background: ${(props) => props.theme.sideNavHoverBoxColor};
		cursor: pointer;
		border-radius: 10px;
		color: ${(props) => props.theme.sideNavBgColor};
	}
`;

export const VerticalNavbarLine = styled.div`
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	margin-left: 2rem;
	padding-left: 10px;
`;
