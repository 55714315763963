import React from "react";
import { Button, Modal } from "react-bootstrap";
import ReactPlayer from 'react-player';

interface ShipIt {
    open: boolean;
    genearteLabel: () => void;
}

export const ShipItModal: React.FC<ShipIt> = ({
    open,
    genearteLabel
}) => {

    return (
        <Modal show={open} onHide={genearteLabel}>
            <Modal.Header closeButton>
                <Modal.Title>2. Ship It</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="ml-3">
                    <ReactPlayer
                        width={"280px"}
                        height={"180px"}
                        url={process.env.REACT_APP_SHIP_IT_VIDEO_LINK}
                        playing={true}
                        controls={true}
                    />
                </div>
                <div className="ml-3 font-500">
                    <p>a. Place the sealed Bio Film case inside the return envelope and seal it.</p>
                    <p>b. Make sure the Order Id on the envelope, Bio Film case and Bio Film are not damaged.</p>
                    <p>c. Generate the Shipping Label by clicking on the button below. The return shipping label will be available to download against your order Id in the Home Screen.</p>
                    <p>d. Paste the shipping label to the sealed envelope and ship it back to us.</p>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={genearteLabel} className="btn-medium">
                    Generate Shipping Label
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
