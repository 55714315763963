import React, { useEffect, useState } from "react";
import CategoryIcon from "@material-ui/icons/Category";
import { IMenuItem } from "../../common/Interface";
import SubMenu from "./SubMenu";
import { URL } from "./routes";
import { SidebarNav, SidebarWrap } from "./Navbar.Style";
import GroupIcon from "@material-ui/icons/Group";
import BiotechIcon from '@mui/icons-material/Biotech';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';

import "./Navbar.scoped.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { cloneDeep } from "lodash";

const Sidebar: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const logo = "https://iqintel-dev-files.s3.amazonaws.com/standards/Group.png";
  const [routes, setRoutes] = useState<any[]>([
    {
      title: "",
      icon: <CategoryIcon className="navbar-menu-icon" />,
      path: URL.DASHBOARD,
    },
    {
      title: "",
      icon: <BiotechIcon className="navbar-menu-icon" />,
      path: URL.ORGANISMS_LIST,
    },
  ])

  useEffect(() => {
    if (user?.role === 'super-admin') {
      const subRoutes = cloneDeep(routes);
      subRoutes.push(
        {
          title: "",
          icon: <GroupIcon className="navbar-menu-icon" />,
          path: URL.INVITE_USER,
        },
        {
          title: "",
          icon: <PhotoAlbumIcon className="navbar-menu-icon" />,
          path: URL.LABEL,
        }
      )
      setRoutes(subRoutes);
    }
  }, []);

  return (
    <SidebarNav>
      <SidebarWrap>
        <div className="mt-4 mb-5 text-center sidenav">
          <img className="text-white-color" src={logo} alt="" />
        </div>
        {["super-admin", "filter-admin"].includes(user?.role) &&
          routes.map((item: IMenuItem, index) => (
            <div className="mb-2" key={`${index}-${item.path}`}>
              <SubMenu item={item} keyValue={index} />
            </div>
          ))}
      </SidebarWrap>
    </SidebarNav>
  );
};

export default Sidebar;
