import "date-fns";
import React, { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import "./DatePicker.scoped.scss";

interface DateTimePickerProps {
	inputId: string;
	label: string;
	date: moment.Moment | Date | null;
	minDate?: moment.Moment | Date | null;
	isDisablePast?: boolean;
	onDateChanged: (date: Date | null) => void;
	datepickerClass?: string;
	disableFuture?: boolean;
}

const DatePicker: React.FC<DateTimePickerProps> = ({
	inputId,
	label,
	date,
	minDate,
	isDisablePast,
	onDateChanged,
	datepickerClass,
	disableFuture,
}) => {
	const [selectedDate, setSelectedDate] = React.useState<
		Date | moment.Moment | null
	>(date);

	useEffect(() => {
		setSelectedDate(date);
	})

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date);
		onDateChanged(date);
	};
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<h6>{label}</h6>
			<div className={datepickerClass ? datepickerClass : "create-input"}>
				<KeyboardDatePicker
					animateYearScrolling
					format="MM-dd-yyyy"
					id={inputId}
					value={selectedDate}
					onChange={handleDateChange}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
					placeholder="mm-dd-yyyy"
					fullWidth
					variant="inline"
					InputProps={{
						disableUnderline: true,
					}}
					minDate={minDate}
					disablePast={isDisablePast}
					disableFuture={disableFuture}
				/>
			</div>
		</MuiPickersUtilsProvider>
	);
};

export default DatePicker;
