import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import get from "lodash/get";
import { useEffect, useRef, useState } from "react";
import { SpinnerLoader } from "../../common/Global.Style";
import { toast } from "react-toastify";
import { getFilterTestLocations, uploadFile } from "../../api/TestController";
import Compressor from "compressorjs";

interface IQuestionnaireProps {
    open: boolean;
    onclose: () => void;
    onSUbmit: (values: any) => void;
    locations: any[];
    users: any[];
    regForCustomer: boolean;
    user: any;
}

export const Questionnaire = ({
    open,
    onclose,
    onSUbmit,
    locations,
    regForCustomer,
    users,
    user
}: IQuestionnaireProps) => {

    const [filterLocationOptions] = useState<any>([
        { label: "Air Filter", value: "air_filter" },
        { label: "In Duct", value: "in_duct" },
        { label: "Others", value: "others" },
    ]);

    const [buildingTypeOptions] = useState<any>([
        { label: "Residential", value: "residential" },
        { label: "Commercial", value: "commercial" },
        { label: "Industrial", value: "industrial" },
    ]);

    const [lastFilterChangeOptions] = useState<any>([
        { label: "Within 1 Month", value: "within_1_month" },
        { label: "1-3 Months", value: "1-3_month" },
        { label: "3-6 Months", value: "3-6_months" },
        { label: "6-12 Months", value: "6-12_months" },
        { label: "> 12 Months", value: "more_than_12_months" },
        { label: "Do not Know", value: "do_not_know" },
    ]);

    const [filterRatingOptions] = useState<any[]>([
        { label: "MERV 6", value: "merv6" },
        { label: "MERV 8", value: "merv8" },
        { label: "MERV 11", value: "merv11" },
        { label: "MERV 13", value: "merv13" },
        { label: "Other", value: "other" },
        { label: "Do not Know", value: "do_not_know" },
    ]);

    const [thermostatSettingOptions] = useState<any>([
        { label: "Auto", value: "auto" },
        { label: "Manual", value: "manual" },
        { label: "Do not Know", value: "do_not_know" },
    ]);

    const monthEl: any = useRef(null);
    const yearEl: any = useRef(null);
    const sNoEl: any = useRef(null);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [addressOptions, setAddressOptions] = useState<any[]>([]);
    const [userOptions, setUserOptions] = useState<any[]>([]);
    const [oldLocations, setOldLocations] = useState<any[]>([]);
    const [qId, setQuestionnaireId] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const refImage: string = "https://iqintel-dev-files.s3.amazonaws.com/standards/Order+ID+PNG.png";

    let validationSchema: any = {
        buildingType: Yup.object({
            label: Yup.string().required("Building Type is required"),
            value: Yup.string().required("Building Type is required"),
        }).required("Building Type is required"),
        filterLocation: Yup.object({
            label: Yup.string().required("Filter Location is required"),
            value: Yup.string().required("Filter Location is required"),
        }).required("Filter Location is required"),
        thermostatSettings: Yup.object({
            label: Yup.string().required("Thermostat Settings is required"),
            value: Yup.string().required("Thermostat Settings is required"),
        }).required("Thermostat Settings is required"),
        year: Yup.number().required("Enter 3 digit number"),
        month: Yup.number().required("Enter 3 digit number"),
        serialNo: Yup.number().required("Enter minimum 4 digit number"),
        zipcode: Yup.number().min(5).required("Enter valid zipcode"),
        city: Yup.string().trim().required("City is Required")
            .matches(/([a-zA-Z])/, "City is not valid"),
        state: Yup.string().trim().required("State is Required")
            .matches(/([a-zA-Z])/, "State is not valid"),
    };

    const formik = useFormik({
        initialValues: {
            locationId: { label: "Select Existing Location", value: "" },
            filterLocation: { label: "Select Filter Location", value: "" },
            lastFilterChange: { label: "Select Filter Change", value: "" },
            filterRating: { label: "Select Filter Rating", value: "" },
            thermostatSettings: { label: "Select Thermostat Setting", value: "" },
            temperature: "",
            additionalInfo: "",
            occupantsInfo: "",
            month: "",
            year: "",
            serialNo: "",
            location: "",
            address: "",
            oldAddress: { label: "Select Existing Address", value: "" },
            oldUser: { label: "Select Existing User", value: "" },
            buildingType: { label: "Select Building Type", value: "" },
            neighbourhood: "",
            existingLoc: "true",
            existingAddress: "true",
            zipcode: "",
            state: "",
            city: "",
            images: [],
            surroundingImages: [],
            email: "",
            existingUser: "true",
        },
        validationSchema: Yup.object().shape(validationSchema),
        onSubmit: ({
            filterLocation,
            lastFilterChange,
            filterRating,
            thermostatSettings,
            temperature,
            additionalInfo,
            occupantsInfo,
            locationId,
            location,
            oldAddress,
            address,
            buildingType,
            neighbourhood,
            year,
            month,
            serialNo,
            zipcode,
            state,
            city,
            images,
            surroundingImages,
            email,
            oldUser
        }) => {

            if (formik.values.filterLocation.value === "air_filter") {
                if (formik.values.lastFilterChange.value === "") {
                    formik.setFieldError("lastFilterChange", "Last FilterChange value is required");
                    return;
                }
                if (formik.values.filterRating.value === "") {
                    formik.setFieldError("filterRating", "Filter Rating is required");
                    return;
                }
            }

            if (!formik.values.address.trim() && !formik.values.oldAddress.value) {
                formik.setFieldError("address", "Address is Required");
                formik.setFieldError("oldAddress", "Address is Required");
                return;
            }

            if (formik.values.address && !/([a-zA-Z])/.test(formik.values.address)) {
                formik.setFieldError("address", "Invalid Address");
                return;
            }

            if (!formik.values.location.trim() && !formik.values.locationId.value) {
                formik.setFieldError("location", "Location is Required");
                formik.setFieldError("locationId", "Location is Required");
                return;
            }

            if (formik.values.location && !/([a-zA-Z])/.test(formik.values.location)) {
                formik.setFieldError("location", "Invalid Location Name");
                return;
            }

            if (formik.values.additionalInfo && !/([a-z])/.test(formik.values.additionalInfo)) {
                formik.setFieldError("additionalInfo", "Invalid Additional Information");
                return;
            }

            if (formik.values.occupantsInfo && !/([a-z])/.test(formik.values.occupantsInfo)) {
                formik.setFieldError("occupantsInfo", "Invalid Additional Information");
                return;
            }

            onSUbmit({
                orderId: `iQi-${year}-${month}-${serialNo}`,
                questionnaire: {
                    filterLocation: filterLocation.value,
                    lastFilterChange: lastFilterChange.value,
                    filterRating: filterRating.value,
                    thermostatSettings: thermostatSettings.value,
                    temperature,
                    additionalInfo,
                    occupantsInfo,
                    buildingType: buildingType.value,
                    neighbourhood,
                    images,
                    surroundingImages,
                    _id: qId
                },
                locationId: locationId.value,
                location,
                address,
                oldAddress: oldAddress.label,
                zipcode,
                state,
                city,
                email: email || oldUser.value
            });
        },
    });

    useEffect(() => {
        yearEl?.current?.focus();
        if (regForCustomer) {
            locations = locations.filter((l: any) => l.user !== user._id);
        }
        setLocationOptions(locations?.map(
            (loc: any) => {
                return { value: loc?._id, label: loc?.name }
            })
        );
        setAddressOptions(locations?.map(
            (loc: any) => {
                return { value: loc?._id, label: loc?.address }
            })
        );
        setUserOptions(users?.map(
            (user: any) => {
                return { value: user?.email, label: user?.email }
            }
        ));
    }, []);

    const onChangeYear = (value: any) => {
        formik.setFieldValue("year", value?.slice(0, 3));
        if (value.length === 3) {
            monthEl?.current?.focus();
        }
    }

    const onChangeMonth = (value: any) => {
        formik.setFieldValue("month", value?.slice(0, 3));
        if (value.length === 3) {
            sNoEl?.current?.focus();
        }
    }

    const onChangeSerialNo = (value: any) => {
        formik.setFieldValue("serialNo", value);
    }

    const onChooseImage = async (event: any, type: string) => {
        const apiBody = new FormData();
        let [file] = event.target.files;
        new Compressor(file, {
            quality: 0.8,
            width: 250,
            height: 200,
            resize: "contain",
            success: async (compressedResult: Blob) => {
                apiBody.append("file", compressedResult);
                setIsLoading(true);
                const response = await uploadFile(apiBody);
                setIsLoading(false);
                if (response?.error) {
                    toast.error(response?.error);
                } else if (response?.responseObj?.Location) {
                    const tempImages: string[] = type === "img" ? formik.values.images : formik.values.surroundingImages;
                    tempImages.push(response?.responseObj?.Location);
                    type === "img"
                        ? formik.setFieldValue("images", tempImages)
                        : formik.setFieldValue("surroundingImages", tempImages);
                }
            },
            error: (err) => {
                console.log(err, "File compression error");
                return { error: "Upload Failed" };
            }
        });
    }

    const onClickCloseIcon = (index: number, type: string) => {
        let tempImages = type === "img" ? formik.values.images : formik.values.surroundingImages;
        tempImages.splice(index, 1);
        type === "img"
            ? formik.setFieldValue("images", tempImages)
            : formik.setFieldValue("surroundingImages", tempImages);
    }

    const onSelectLocation = (value: any) => {
        formik.setFieldValue("locationId", value);
        let location = locations.find((l: any) => l._id === value.value);
        if (!location) {
            location = oldLocations.find((l: any) => l._id === value.value)
        }

        if (location) {
            formik.setFieldValue("zipcode", get(location, "zipcode", ""));
            formik.setFieldValue("state", get(location, "state", ""));
            formik.setFieldValue("city", get(location, "city", ""));
        }

        if (location && location.questionnaire) {
            const questionnaire = location.questionnaire;
            formik.setFieldValue("temperature", get(questionnaire, "temperature", ""));
            formik.setFieldValue("additionalInfo", get(questionnaire, "additionalInfo", ""));
            formik.setFieldValue("occupantsInfo", get(questionnaire, "occupantsInfo", ""));
            formik.setFieldValue("neighbourhood", get(questionnaire, "neighbourhood", ""));
            formik.setFieldValue("images", get(questionnaire, "images", []));
            formik.setFieldValue("surroundingImages", get(questionnaire, "surroundingImages", []));
            setQuestionnaireId(get(questionnaire, "_id", ""));
            const bType = buildingTypeOptions.find((b: any) => b.value === questionnaire?.buildingType);
            if (bType) {
                formik.setFieldValue("buildingType", bType);
            }
            const filterLocation = filterLocationOptions.find((l: any) => l.value === questionnaire?.filterLocation);
            if (filterLocation) {
                formik.setFieldValue("filterLocation", filterLocation);
            }
            const lastChange = lastFilterChangeOptions.find((c: any) => c.value === questionnaire?.lastFilterChange);
            if (lastChange) {
                formik.setFieldValue("lastFilterChange", lastChange);
            }
            const rating = filterRatingOptions.find((r: any) => r.value === questionnaire?.filterRating);
            if (rating) {
                formik.setFieldValue("filterRating", rating);
            }
            const thermoSetting = thermostatSettingOptions.find((t: any) => t.value === questionnaire?.thermostatSettings);
            if (thermoSetting) {
                formik.setFieldValue("thermostatSettings", thermoSetting);
            }
        }
    }

    const onCheckNewLocation = () => {
        formik.setFieldValue("existingLoc", "false");
        formik.setFieldValue("locationId", { label: "Select Location", value: "" });
    }

    const getLocations = async (userId: string) => {
        setIsLoading(true);
        const response = await getFilterTestLocations(userId);
        setIsLoading(false);
        if (response?.responseObj?.locations?.length) {
            setOldLocations(response?.responseObj?.locations);
            setLocationOptions(response?.responseObj?.locations?.map(
                (l: any) => {
                    return { label: l?.name, value: l?._id }
                }
            ));
            setAddressOptions(response?.responseObj?.locations?.map(
                (loc: any) => {
                    return { value: loc?._id, label: loc?.address }
                })
            );
        }
    }

    const onSelectUser = (data: any) => {
        formik.setFieldValue("oldUser", data);
        const user: any = users.find((u: any) => u.email === data.value);
        if (!user) {
            return;
        }
        getLocations(user._id);
    }

    return (
        <Drawer anchor={"right"} open={open} onClose={onclose}>
            {isLoading && <SpinnerLoader />}
            <div className="d-flex justify-content-between p-3">
                <h5 className="align-items-center d-flex mb-0">Register Your Test</h5>
                <IconButton onClick={onclose}>
                    <CloseIcon />
                </IconButton>
            </div>

            <FormikProvider value={formik}>
                <form className="p-4" onSubmit={formik.handleSubmit}>
                    <div className="mb-3 form-group">
                        <label className="pass mb-2">Order ID</label>
                        <div className="d-flex">
                            <input
                                id="orderId"
                                value="iQi"
                                name="orderId"
                                type="text"
                                readOnly
                                className="pt-2 pb-2 input-text"
                            />
                            <span className="m-1 mt-2">-</span>

                            <input
                                id="year"
                                value={formik.values.year}
                                name="year"
                                type="number"
                                ref={yearEl}
                                onChange={(e) => { onChangeYear(e?.target?.value) }}
                                className={`${formik.errors.year ? "input-error" : ""} pt-2 pb-2`}
                            />
                            <span className="m-1 mt-2">-</span>

                            <input
                                id="month"
                                value={formik.values.month}
                                name="month"
                                type="number"
                                ref={monthEl}
                                onChange={(e) => { onChangeMonth(e?.target?.value) }}
                                className={`${formik.errors.month ? "input-error" : ""} pt-2 pb-2`}
                            />
                            <span className="m-1 mt-2">-</span>

                            <input
                                id="serialNo"
                                value={formik.values.serialNo}
                                name="serialNo"
                                type="number"
                                ref={sNoEl}
                                onChange={(e) => { onChangeSerialNo(e?.target?.value) }}
                                className={`${formik.errors.serialNo ? "input-error" : ""} pt-2 pb-2`}
                            />
                        </div>
                        {formik.touched?.year && formik.errors.year ? (
                            <div className="text-danger">
                                Please Enter Valid Order ID
                            </div>
                        ) : null}
                        {formik.touched?.month && formik.errors.month && !formik.errors.year ? (
                            <div className="text-danger">
                                Please Enter Valid Order ID
                            </div>
                        ) : null}
                        {formik.touched?.serialNo
                            && formik.errors.serialNo
                            && !formik.errors.month
                            && !formik.errors.year
                            ? (
                                <div className="text-danger">
                                    Please Enter Valid Order ID
                                </div>
                            ) : null}
                    </div>

                    <p className="desc-medium m-1">
                        You can find the order ID on the top of the mailing envelop and also on the top of the Bio Film Sampler.
                    </p>

                    <div className="d-flex justify-content-center m-3">
                        <img className="ref-img" src={refImage} />
                    </div>

                    {regForCustomer && users.length === 0 &&
                        <div className="mb-3 form-group">
                            <label className="pass mb-2">Customer Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="bg-light border-0 mt-2"
                                placeholder="Enter the customer email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched?.email && formik.errors.email ? (
                                <div className="text-danger">
                                    {formik.errors.email}
                                </div>
                            ) : null}
                        </div>
                    }

                    {regForCustomer && users.length > 0 &&
                        <>
                            <div className="mb-3 form-group">
                                <label className="pass mb-2">Customer Email</label>

                                <div className="mt-2">
                                    <input
                                        type="radio"
                                        id="p-plan"
                                        name="existingUser"
                                        onChange={formik.handleChange}
                                        className="mr-1"
                                        value="true"
                                        checked={formik.values.existingUser === "true"}
                                    />{" "}
                                    Existing User
                                    <input
                                        type="radio"
                                        id="f-plan"
                                        name="existingUser"
                                        onChange={formik.handleChange}
                                        className="mr-1 ml-2"
                                        value="false"
                                        checked={formik.values.existingUser === "false"}
                                    />{" "}
                                    New User
                                    {formik.errors.existingUser && (
                                        <div className="text-danger mt-1">
                                            {formik.errors.existingUser}
                                        </div>
                                    )}
                                </div>

                                {formik.values.existingUser === "true" &&
                                    <>
                                        <div className="password">
                                            <Select
                                                name="colors"
                                                options={userOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                value={formik.values.oldUser}
                                                onChange={(value) => onSelectUser(value)}
                                            />
                                        </div>
                                        {formik.errors.oldUser ? (
                                            <div className="text-danger">
                                                {formik.errors.oldUser}
                                            </div>
                                        ) : null}
                                    </>
                                }

                                {formik.values.existingUser === "false" &&
                                    <>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="bg-light border-0 mt-2"
                                            placeholder="Enter the customer email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="text-danger">{formik.errors.email}</div>
                                        ) : null}
                                    </>
                                }
                            </div>
                        </>
                    }

                    {locationOptions?.length > 0 &&
                        <>
                            <div className="mb-3 form-group">
                                <label className="pass mb-2">Address *</label>

                                <div className="mt-2">
                                    <input
                                        type="radio"
                                        id="p-plan"
                                        name="existingAddress"
                                        onChange={formik.handleChange}
                                        className="mr-1"
                                        value="true"
                                        checked={formik.values.existingAddress === "true"}
                                    />{" "}
                                    Existing Address
                                    <input
                                        type="radio"
                                        id="f-plan"
                                        name="existingAddress"
                                        onChange={formik.handleChange}
                                        className="mr-1 ml-2"
                                        value="false"
                                        checked={formik.values.existingAddress === "false"}
                                    />{" "}
                                    New Address
                                    {formik.errors.existingAddress && (
                                        <div className="text-danger mt-1">
                                            {formik.errors.existingAddress}
                                        </div>
                                    )}
                                </div>

                                {formik.values.existingAddress === "true" &&
                                    <>
                                        <div className="password">
                                            <Select
                                                name="colors"
                                                options={addressOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                value={formik.values.oldAddress}
                                                onChange={(value) => formik.setFieldValue("oldAddress", value)}
                                            />
                                        </div>
                                        {formik.errors.oldAddress ? (
                                            <div className="text-danger">
                                                {formik.errors.oldAddress}
                                            </div>
                                        ) : null}
                                    </>
                                }

                                {formik.values.existingAddress === "false" &&
                                    <>
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            className="bg-light border-0 mt-2"
                                            placeholder="Enter the address"
                                            onChange={(e: any) => {
                                                let name = e?.target?.value?.trimStart();
                                                formik.setFieldValue("address", name?.replace(/[^a-zA-Z0-9-,-./ ]/g, ''));
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address}
                                        />
                                        {formik.touched.address && formik.errors.address ? (
                                            <div className="text-danger">{formik.errors.address}</div>
                                        ) : null}
                                    </>
                                }
                            </div>
                        </>
                    }

                    {locationOptions?.length === 0 &&
                        <>
                            <div className="mb-3 form-group">
                                <label className="location">Address *</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    className="bg-light border-0 mt-2"
                                    placeholder="Enter the address"
                                    onChange={(e: any) => {
                                        let name = e?.target?.value?.trimStart();
                                        formik.setFieldValue("address", name?.replace(/[^a-zA-Z0-9-,-./ ]/g, ''));
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <div className="text-danger">{formik.errors.address}</div>
                                ) : null}
                            </div>
                        </>
                    }

                    <div className="mb-3 form-group">
                        <label className="email">City *</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="bg-light border-0 mt-2"
                            placeholder="Enter city"
                            onChange={(e: any) => {
                                let name = e?.target?.value?.trimStart();
                                name = name.replace(/[^a-zA-Z ]/g, '');
                                formik.setFieldValue("city", name);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                        />
                        {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger">{formik.errors.city}</div>
                        ) : null}
                    </div>

                    <div className="mb-3 form-group">
                        <label className="email">State *</label>
                        <input
                            type="text"
                            id="state"
                            name="state"
                            className="bg-light border-0 mt-2"
                            placeholder="Enter State"
                            onChange={(e: any) => {
                                let name = e?.target?.value?.trimStart();
                                name = name.replace(/[^a-zA-Z ]/g, '');
                                formik.setFieldValue("state", name);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                        />
                        {formik.touched.state && formik.errors.state ? (
                            <div className="text-danger">{formik.errors.state}</div>
                        ) : null}
                    </div>

                    <div className="mb-3 form-group">
                        <label className="email">Zipcode *</label>
                        <input
                            type="number"
                            id="zipcode"
                            name="zipcode"
                            className="bg-light border-0 mt-2"
                            placeholder="Enter Zipcode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zipcode}
                        />
                        {formik.touched.zipcode && formik.errors.zipcode ? (
                            <div className="text-danger">{formik.errors.zipcode}</div>
                        ) : null}
                    </div>

                    {locationOptions?.length > 0 && formik.values.existingAddress === "true" &&
                        <>
                            <div className="mb-3 form-group">
                                <label className="pass mb-2">Test Location (Ex: 1st Floor, Lobby) *</label>

                                <div className="mt-2">
                                    <input
                                        type="radio"
                                        id="p-plan"
                                        name="existingLoc"
                                        onChange={formik.handleChange}
                                        className="mr-1"
                                        value="true"
                                        checked={formik.values.existingLoc === "true"}
                                    />{" "}
                                    Existing Location
                                    <input
                                        type="radio"
                                        id="f-plan"
                                        name="existingLoc"
                                        onChange={onCheckNewLocation}
                                        className="mr-1 ml-2"
                                        value="false"
                                        checked={formik.values.existingLoc === "false"}
                                    />{" "}
                                    New Location
                                    {formik.errors.existingLoc && (
                                        <div className="text-danger mt-1">
                                            {formik.errors.existingLoc}
                                        </div>
                                    )}
                                </div>

                                {formik.values.existingLoc === "true" &&
                                    <>
                                        <div className="password">
                                            <Select
                                                name="colors"
                                                options={locationOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                value={formik.values.locationId}
                                                onChange={(value) => onSelectLocation(value)}
                                            />
                                        </div>
                                        {formik.errors.locationId ? (
                                            <div className="text-danger">
                                                {formik.errors.locationId}
                                            </div>
                                        ) : null}
                                    </>
                                }

                                {formik.values.existingLoc === "false" &&
                                    <>
                                        <input
                                            type="text"
                                            id="location"
                                            name="location"
                                            className="bg-light border-0 mt-2"
                                            placeholder="Enter the location name"
                                            onChange={(e: any) => {
                                                let name = e?.target?.value?.trimStart();
                                                name = name?.replace(/[^a-zA-Z0-9 ]/g, '');
                                                formik.setFieldValue("location", name);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.location}
                                        />
                                        {formik.errors.location ? (
                                            <div className="text-danger">{formik.errors.location}</div>
                                        ) : null}
                                    </>
                                }
                            </div>
                        </>
                    }

                    {
                        (locationOptions?.length === 0 || formik.values.existingAddress === "false") &&
                        <div className="mb-3 form-group">
                            <label className="location">Test Location (Ex: 1st Floor, Lobby) *</label>
                            <input
                                type="text"
                                id="location"
                                name="location"
                                className="bg-light border-0 mt-2"
                                placeholder="Enter the location name"
                                onChange={(e: any) => {
                                    let name = e?.target?.value?.trimStart();
                                    name = name?.replace(/[^a-zA-Z0-9 ]/g, '');
                                    formik.setFieldValue("location", name);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                            />
                            {formik.errors.location ? (
                                <div className="text-danger">{formik.errors.location}</div>
                            ) : null}
                        </div>
                    }

                    <p className="desc-large font-600 font-black-85">
                        Please enter the details of your building and filter details for an accurate filter forensics report
                    </p>

                    <div className="mb-3 form-group">
                        <label className="pass mb-2">Building Type *</label>
                        <div className="password">
                            <Select
                                name="colors"
                                options={buildingTypeOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formik.values.buildingType}
                                onChange={(value) => formik.setFieldValue("buildingType", value)}
                            />
                        </div>
                        {formik.touched?.buildingType && formik.errors.buildingType?.value ? (
                            <div className="text-danger">
                                {formik.errors.buildingType?.value}
                            </div>
                        ) : null}
                    </div>

                    <div className="mb-3 form-group">
                        <label className="email">Neighbourhood</label>
                        <textarea
                            name="neighbourhood"
                            id="neighbourhood"
                            value={formik.values.neighbourhood}
                            onChange={(e: any) => {
                                const name = e?.target?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
                                formik.setFieldValue("neighbourhood", name);
                            }}
                        />
                        <p className="desc-medium m-1">
                            Please specify if you have any Construction Site, Hospitals, Mining Activity, Dump yard, Landfills, Recycling plant, etc in 1 mile radius.
                        </p>
                    </div>

                    <div className="mb-3 form-group">
                        <label className="pass mb-2">Where is the sample collected from? *</label>
                        <div className="password">
                            <Select
                                name="colors"
                                options={filterLocationOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formik.values.filterLocation}
                                onChange={(value) => formik.setFieldValue("filterLocation", value)}
                            />
                        </div>
                        {formik.touched?.filterLocation && formik.errors.filterLocation?.value ? (
                            <div className="text-danger">
                                {formik.errors.filterLocation?.value}
                            </div>
                        ) : null}
                    </div>

                    {
                        formik.values.filterLocation.value === "air_filter" &&
                        <>
                            <div className="mb-3 form-group">
                                <label className="pass mb-2">Last Filter Change *</label>
                                <div className="password">
                                    <Select
                                        name="colors"
                                        options={lastFilterChangeOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={formik.values.lastFilterChange}
                                        onChange={(value) => formik.setFieldValue("lastFilterChange", value)}
                                    />
                                </div>
                                {formik.errors.lastFilterChange ? (
                                    <div className="text-danger">
                                        {formik.errors.lastFilterChange}
                                    </div>
                                ) : null}
                            </div>

                            <div className="mb-3 form-group">
                                <label className="pass mb-2">Filter Rating *</label>
                                <div className="password">
                                    <Select
                                        name="colors"
                                        options={filterRatingOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={formik.values.filterRating}
                                        onChange={(value) => formik.setFieldValue("filterRating", value)}
                                    />
                                </div>
                                {formik.errors.filterRating ? (
                                    <div className="text-danger">
                                        {formik.errors.filterRating}
                                    </div>
                                ) : null}
                            </div>
                        </>
                    }

                    <div className="mb-3 bg-white org-wrap">
                        <h4 className="desc-large lh-20 ls-024 mb-2 pb-8">
                            Please upload your filter's front and back side image or Duct image, or the spot where sample is collected.
                        </h4>
                        <div className="mt-4">
                            <div className="d-flex justify-content-start">
                                <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="images"
                                    type="file"
                                    onClick={(event: any) => {
                                        event.target.value = null;
                                    }}
                                    onChange={(event: any) => onChooseImage(event, "img")}
                                />
                                <div className="text-right pb-3">
                                    <label htmlFor="images">
                                        <p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
                                            Upload
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {formik.values.images?.length !== 0 && formik.values.images.map((img: string, index: number) => {
                            let name: any = img.split('/');
                            name = name[name.length - 1];
                            return (
                                <div className="d-flex flex-wrap report-data-main">
                                    <div className="report-data-image">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <img src={img} className="test-img" />
                                            </div>
                                            <CloseIcon
                                                className="cursor-pointer ml-1 close-icon"
                                                onClick={() => onClickCloseIcon(index, "img")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>

                    <div className="mb-3 bg-white org-wrap">
                        <h4 className="desc-large lh-20 ls-024 mb-2 pb-8">Photos of Surrounding Location(Optional)</h4>
                        <div className="mt-4">
                            <div className="d-flex justify-content-start">
                                <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="surroundingImages"
                                    type="file"
                                    onClick={(event: any) => {
                                        event.target.value = null;
                                    }}
                                    onChange={(event: any) => onChooseImage(event, "sImg")}
                                />
                                <div className="text-right pb-3">
                                    <label htmlFor="surroundingImages">
                                        <p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
                                            Upload
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {formik.values.surroundingImages?.length !== 0
                            && formik.values.surroundingImages.map((img: string, index: number) => {
                                let name: any = img.split('/');
                                name = name[name.length - 1];
                                return (
                                    <div className="d-flex flex-wrap report-data-main">
                                        <div className="report-data-image">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <img src={img} className="test-img" />
                                                </div>
                                                <CloseIcon
                                                    className="cursor-pointer ml-1 close-icon"
                                                    onClick={() => onClickCloseIcon(index, "sImg")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                    </div>

                    <div className="mb-3 form-group">
                        <label className="pass mb-2">Typical Thermostat Setting *</label>
                        <div className="password">
                            <Select
                                name="colors"
                                options={thermostatSettingOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formik.values.thermostatSettings}
                                onChange={(value) => formik.setFieldValue("thermostatSettings", value)}
                            />
                        </div>
                        {formik.touched?.thermostatSettings && formik.errors.thermostatSettings?.value ? (
                            <div className="text-danger">
                                {formik.errors.thermostatSettings?.value}
                            </div>
                        ) : null}
                    </div>

                    <div className="mb-3 form-group">
                        <label className="email">What Temperature do you generally set? (F)</label>
                        <input
                            type="number"
                            id="temperature"
                            name="temperature"
                            className="bg-light border-0 mt-2"
                            placeholder="Temperature in F"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.temperature}
                        />
                        {formik.touched.temperature && formik.errors.temperature ? (
                            <div className="text-danger">{formik.errors.temperature}</div>
                        ) : null}
                    </div>

                    <div className="mb-3 form-group">
                        <label className="email">Any additional information about HVAC & Building Parameters?</label>
                        <textarea
                            name="additionalInfo"
                            id="additionalInfo"
                            value={formik.values.additionalInfo}
                            onChange={(e: any) => {
                                formik.setFieldValue("additionalInfo", e?.target?.value);
                            }}
                        />
                        {formik.errors.additionalInfo ? (
                            <div className="text-danger">{formik.errors.additionalInfo}</div>
                        ) : null}
                    </div>

                    <div className="mb-3 form-group">
                        <label className="email">Do you want to add any additional information about occupants and occupants health history?</label>
                        <textarea
                            name="occupantsInfo"
                            id="occupantsInfo"
                            value={formik.values.occupantsInfo}
                            onChange={(e: any) => {
                                formik.setFieldValue("occupantsInfo", e?.target?.value);
                            }}
                        />
                        {formik.errors.occupantsInfo ? (
                            <div className="text-danger">{formik.errors.occupantsInfo}</div>
                        ) : null}
                    </div>

                    <button type="submit" className="btn w-100 mb-3 btn-medium">
                        Register
                    </button>
                </form>
            </FormikProvider>
        </Drawer>
    );
};
