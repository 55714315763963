import React from "react";
import { Button, Modal } from "react-bootstrap";
interface PreviewDialogProps {
    onClose: () => void;
    labelUrl: string;
    open: boolean;
}

const LabelPreviewDialog: React.FC<PreviewDialogProps> = ({
    onClose,
    labelUrl,
    open
}) => {
    return (
        <>
            <Modal className="report-preview-modal" show={open} onHide={onClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Shipping Label</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe className="w-100 h-100" src={labelUrl} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn-medium"
                        variant="primary"
                        onClick={() => window.open(labelUrl)}
                        style={{ cursor: "pointer" }}
                    >
                        Download Label
                    </Button>
                    <Button
                        className="btn-medium"
                        variant="primary"
                        onClick={onClose}
                        style={{ cursor: "pointer" }}
                    >
                        Next Step
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LabelPreviewDialog;
