import { ResetPasswordModal } from "../pages/ResetPassword/ResetPassword";
import apiPlugin, { ApiResponse, apiPluginForImageUpload } from "./apiPlugin";

export async function loginApi(userDetail: Object): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/login", undefined, userDetail);
}

export async function loginWithGoogle(userDetail: any): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/google-login", undefined, userDetail);
}

export async function loginWithApple(userDetail: any): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/apple-login", undefined, userDetail);
}

export async function loginWithMicroSoft(userDetail: any): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/microsoft-login", undefined, userDetail);
}

export async function loginWithFacebook(userDetail: any): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/facebook-login", undefined, userDetail);
}

export async function forgotPasswordApi(
  email: Object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/forgot-password", undefined, email);
}

export async function resetPasswordApi(
  resetPassword: ResetPasswordModal
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/reset-password", undefined, resetPassword);
}

export async function getProfileDetails(): Promise<ApiResponse<any>> {
  return apiPlugin<any>("GET", "filter-testing/profile");
}

export async function updateProfile(
  userDetail: Object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("PUT", "filter-testing/profile", undefined, userDetail);
}

export async function getSignatureUrl(
  imageDetails: object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "get-upload-url", undefined, imageDetails);
}

export async function updateUserAvtar(
  url: string,
  avtarFile: any
): Promise<ApiResponse<any>> {
  return apiPluginForImageUpload<any>("PUT", url, avtarFile);
}

export async function changePassword(
  reqMap: object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("PUT", `filter-testing/change-password`, undefined, reqMap);
}

export async function getResetPasswordLink(
  reqMap: object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/password-reset-link", undefined, reqMap);
}

export async function createUser(reqMap: object): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/user", undefined, reqMap);
}

export async function uploadProfileImage(imageDetails: any): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/profile-upload", undefined, undefined, imageDetails);
}

export async function getFilterAdminsList(): Promise<ApiResponse<any>> {
  return apiPlugin<any>("GET", "filter-testing/admins");
}

export async function getFilterUsersList(): Promise<ApiResponse<any>> {
  return apiPlugin<any>("GET", "filter-testing/users");
}

export async function getShippingLabelsList(): Promise<ApiResponse<any>> {
  return apiPlugin<any>("GET", "filter-testing/shipping-label");
}

export async function createFilterAdmin(reqMap: object): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/add-admin", undefined, reqMap);
}

export async function updateUser(
  userDetail: Object, userId: string
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("PUT", `filter-testing/update-user/${userId}`, undefined, userDetail);
}

export async function voidShippinglabel(
  labelId: string
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("PUT", `filter-testing/${labelId}/void`, undefined);
}
