import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";

import { IMenuItem } from "../../common/Interface";
import {
	SidebarLink,
	SidebarLabel,
} from "./Navbar.Style";
import "./Navbar.scoped.scss";
interface SubMenuProps {
	item: IMenuItem;
	keyValue: any;
}

const SubMenu: React.FC<SubMenuProps> = ({ item, keyValue }) => {
	const { path, subNav, icon, title } = item;
	const [subnav, setSubnav] = useState(false);

	useEffect(() => {
		if (subNav?.length) {
			const isFound = subNav.find(
				(mapData) => mapData.path === window.location.pathname
			);
			if (!isEmpty(isFound)) {
				setSubnav(true);
			}
		}
	}, []);

	const showSubnav = () => setSubnav(!subnav);

	return (
		<>
			{path && (
				<SidebarLink
					className={window.location.pathname === path ? "active-link p-0" : "p-0"}
					to={path}
					key={keyValue}
					onClick={subNav && showSubnav}
				>
					<div className="d-flex align-items-center h-100">
						<div className="menu-list-section w-fit h-100 d-flex flex-column justify-content-center p-2 align-items-center">
							{icon}
							<SidebarLabel className="side-menu-font">{title}</SidebarLabel>
						</div>
					</div>
				</SidebarLink>
			)}
		</>
	);
};

export default SubMenu;
