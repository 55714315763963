import React from "react";
import { Modal } from "react-bootstrap";

interface FilterImages {
    images: string[];
    show: boolean;
    onClose: () => void;
}

export const FilterTestImagesModal: React.FC<FilterImages> = ({
    show,
    images,
    onClose
}) => {
    const hideModal = () => {
        onClose();
    }

    return (
        <Modal show={show} onHide={hideModal} scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>Images uploaded by User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {images?.length !== 0 && images?.map((url: string) => {
                        return <>
                            <img src={url} />
                        </>
                    })}
                    {
                        images?.length === 0 &&
                        <p>No images uploaded by user.</p>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};
