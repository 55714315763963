import { default as ReactSelect, components } from "react-select";

const multiSelect: any = {
	control: (styles: any) => ({
		...styles,
		"min-width": "300px",
	}),
};

const Option = (props: any) => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					className="select-input mr-2"
					checked={props.isSelected}
					onChange={() => null}
				/>
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

export const MultiSelectWithCheckBox = ({
	seletedOptions,
	onSelectionChange,
	selectValue,
}: any) => {
	const onSelectChange = (selected: any, event: any) => {
		if (event?.option?.value === "*") {
			onSelectionChange(seletedOptions);
		} else {
			onSelectionChange(selected);
		}
	};

	return (
		<span
			className="d-inline-block w-100"
			data-toggle="popover"
			data-trigger="focus"
			data-content="Please selecet dimension(s)"
		>
			<ReactSelect
				options={[{ label: "All", value: "*" }, ...seletedOptions]}
				isMulti
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				components={{
					Option,
				}}
				className="w-100"
				onChange={onSelectChange}
				value={selectValue}
				// styles={multiSelect}
				placeholder=""
			/>
		</span>
	);
};