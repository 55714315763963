import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
	updateProfile,
	uploadProfileImage,
} from "../../api/UserController";

import { SpinnerLoader } from "../../common/Global.Style";
import { setUser } from "../../redux/actions/userActions";

import "./Profile.scoped.scss";
import { RootState } from "../../redux/reducers";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Add from "@material-ui/icons/Add"
import { useHistory } from "react-router-dom";
import Compressor from "compressorjs";

interface Profile {
	firstName: string;
	lastName: string;
	email: string;
	contactNumber: string;
	country: string;
	address: string;
	city: string;
	state: string;
	zip: string;
}

const Profile: React.FC = (props) => {
	const imageUploader = React.useRef<HTMLInputElement>(null);
	const [profileData, setProfileData] = useState<Profile>({
		firstName: "",
		lastName: "",
		email: "",
		contactNumber: "",
		country: "",
		address: "",
		city: "",
		state: "",
		zip: ""
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<any>("/images/userpro.svg");
	const [signedImageUrl, setSignedImageUrl] = useState<any>();
	const user = useSelector((state: RootState) => state.user);
	const [countryOption] = useState<Array<{ value: string; label: string }>>([
		{
			value: "United States",
			label: "United States",
		},
	]);

	const colourStyles = {
		control: (styles: any) => ({
			...styles,
			borderRadius: "10px",
			backgroundColor: "#f8f9fa",
			height: "55px",
			border: "0 !important",
			boxShadow: "0 !important",
			"&:hover": {
				border: "0 !important",
			},
		}),
	};

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		setUserProfile(user);
	}, [])

	const profileSchema = Yup.object({
		firstName: Yup.string().trim().required("First name is required")
			.matches(/[a-zA-Z]/, "First name is not valid"),
		lastName: Yup.string().trim().required("Last name is required")
			.matches(/[a-zA-Z]/, "Last name is not valid"),
		email: Yup.string().trim()
			.email("Invalid Email Address")
			.required("Email is required"),
		contactNumber: Yup.string().trim().matches(/^\d{10}$/, "Please enter 10 digit number without country code"),
		country: Yup.string().trim(),
		address: Yup.string().trim().required("Address is required"),
		city: Yup.string().trim().required("City is required"),
		state: Yup.string().trim().required("State is required"),
		zip: Yup.number()
			.min(10000, 'Must be exactly 5 characters')
			.max(99999, 'Must be exactly 5 characters').required("Zipcode is required"),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: profileData,
		validationSchema: profileSchema,
		onSubmit: (values) => {
			updateProfileDetails(values);
		},
	});

	const updateProfileDetails = async (values: object) => {
		setIsLoading(true);
		const obj: any = values;
		obj.avatar = signedImageUrl;
		const response = await updateProfile(obj);
		setIsLoading(false);
		if (response.responseObj) {
			toast.success(response.responseObj.success);
			dispatch(setUser(response.responseObj.user));
		} else if (response.error) {
			toast.error(response.error);
		}
	};

	const setUserProfile = (profileDetails: any) => {
		const initialValues = {
			firstName: profileDetails.firstName || "",
			lastName: profileDetails.lastName || "",
			email: profileDetails.email || "",
			contactNumber: profileDetails.contactNumber || "",
			country: profileDetails.country || "",
			address: profileDetails.address || "",
			city: profileDetails.city || "",
			state: profileDetails.state || "",
			zip: profileDetails.zip || "",
			avatar: profileDetails.avatar || ""
		};
		setImageUrl(profileDetails.avatar);
		setProfileData(initialValues);
	};

	const onChooseImage = async (event: any) => {
		const apiBody = new FormData();
		let [file] = event.target.files;
		new Compressor(file, {
			quality: 0.8,
			width: 150,
			height: 200,
			success: async (compressedResult) => {
				apiBody.append("file", compressedResult);
				setIsLoading(true);
				const response = await uploadProfileImage(apiBody);
				setIsLoading(false);

				if (response?.error) {
					toast.error(response?.error);
				} else if (response?.responseObj?.user) {
					setImageUrl(response?.responseObj?.user?.avatar);
					dispatch(setUser(response.responseObj.user));
				}
			},
			error: (err) => {
				console.log(err, "File compression error");
			}
		});
	};

	return (
		<div className="pt-4 pl-3">
			<div
				className="d-flex cursor-pointer mb-3"
				onClick={() => history.goBack()}
			>
				<ArrowBackIosIcon fontSize="small" className="ml-2" />
				<p className="breadcrumb-para">Back</p>
			</div>
			<div className="container m-0">
				<div className="profile-component bg-white">
					<form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="col-md-2">
								<div className="position-relative user-profile-img">
									<img
										src={imageUrl}
										alt="user Profile"
										className="cursor-pointer rounded-circle user-profile-photo"
										onClick={() => imageUploader.current!.click()} />
									<input
										type="file"
										accept="image/*"
										onClick={(event: any) => {
											event.target.value = null;
										}}
										onChange={onChooseImage}
										ref={imageUploader}
										multiple={false}
										hidden
									/>
									<Add
										className="cursor-pointer bg-dark-blue-btn txt-white border-radius-20 profile-add-icon"

									/>
								</div>
							</div>
							<div className="col-md-12 col-lg-8 col-xl-10">
								<div className="row align-items-end">
									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">First Name</label>
										<input
											id="firstName"
											type="text"
											className="w-100 p-3 bg-white profileinput txt-black fs-14"
											placeholder="First Name"
											onChange={(e: any) => {
												const name = e?.target?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
												formik.setFieldValue("firstName", name);
											}}
											onBlur={formik.handleBlur}
											value={formik.values.firstName}
										/>
										{formik.touched.firstName && formik.errors.firstName ? (
											<div className="text-danger">
												{formik.errors.firstName}
											</div>
										) : null}
									</div>
									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Last Name</label>
										<input
											id="lastName"
											type="text"
											className="w-100  p-3 bg-white profileinput txt-black fs-14"
											placeholder="Last Name"
											onChange={(e: any) => {
												const name = e?.target?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
												formik.setFieldValue("lastName", name);
											}}
											onBlur={formik.handleBlur}
											value={formik.values.lastName}
										/>
										{formik.touched.lastName && formik.errors.lastName ? (
											<div className="text-danger">
												{formik.errors.lastName}
											</div>
										) : null}
									</div>
									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Email Address</label>
										<input
											id="email"
											type="email"
											className="w-100  p-3 bg-white profileinput txt-black fs-14"
											placeholder="email"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}
											readOnly
										/>
									</div>
									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Contact Number</label>
										<div className="mt-2">
											<input
												id="contactNumber"
												type="text"
												className="w-100  p-3 bg-white profileinput txt-black fs-14"
												placeholder="Enter phone number"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.contactNumber}
											/>
											{formik.touched.contactNumber &&
												formik.errors.contactNumber ? (
												<div className="text-danger">
													{formik.errors.contactNumber}
												</div>
											) : null}
										</div>
									</div>
									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Country</label>
										<div className="dropdown w-100 pl-0 pr-0">
											<Select
												className="country-select fs-14"
												placeholder="Select Country"
												value={{
													value: formik.values.country,
													label: formik.values.country,
												}}
												isSearchable={false}
												name="country"
												onChange={(data: any) => {
													formik.setFieldValue("country", data.value);
												}}
												options={countryOption}
												styles={colourStyles}
											/>
											{formik.touched.country && formik.errors.country ? (
												<div className="text-danger">
													{formik.errors.country}
												</div>
											) : null}
										</div>
									</div>

									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Address</label>
										<input
											id="address"
											type="text"
											className="w-100 p-3 bg-white profileinput txt-black fs-14"
											placeholder="address"
											onChange={(e: any) => {
												const name = e?.target?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
												formik.setFieldValue("address", name);
											}}
											onBlur={formik.handleBlur}
											value={formik.values.address}
										/>
										{formik.touched.address && formik.errors.address ? (
											<div className="text-danger">{formik.errors.address}</div>
										) : null}
									</div>
									<div className="col-md-6 pb-4">
										<h6 className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">City</h6>
										<input
											id="city"
											type="text"
											className="w-100  p-3 bg-white profileinput txt-black fs-14"
											placeholder="city"
											onChange={(e: any) => {
												const name = e?.target?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
												formik.setFieldValue("city", name);
											}}
											onBlur={formik.handleBlur}
											value={formik.values.city}
										/>
										{formik.touched.city && formik.errors.city ? (
											<div className="text-danger">{formik.errors.city}</div>
										) : null}
									</div>
									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">State</label>
										<input
											id="state"
											type="text"
											className="w-100 p-3 bg-white profileinput txt-black fs-14"
											placeholder="state"
											onChange={(e: any) => {
												const name = e?.target?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
												formik.setFieldValue("state", name);
											}}
											onBlur={formik.handleBlur}
											value={formik.values.state}
										/>
										{formik.touched.state && formik.errors.state ? (
											<div className="text-danger">{formik.errors.state}</div>
										) : null}
									</div>
									<div className="col-md-6 pb-4">
										<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Zip Code</label>
										<input
											id="zip"
											type="number"
											className="w-100 p-3 bg-white profileinput txt-black fs-14"
											placeholder="zip code"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.zip}
										/>
										{formik.touched.zip && formik.errors.zip ? (
											<div className="text-danger">{formik.errors.zip}</div>
										) : null}
									</div>
									<div className="col-md-6 user-empty-div">Empty div</div>
									<div className="col-md-6 mt-2">
										<div className="profile-center">
											<button type="submit" className="bg-dark-blue-btn txt-white btn border-radius-4">
												Save Profile
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
					{isLoading && <SpinnerLoader />}
				</div>
			</div>
		</div>
	);
};
export default Profile;
