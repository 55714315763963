import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function uploadFile(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "filter-testing/file-upload",
        undefined,
        undefined,
        apiData
    );
}

export async function createFilterTest(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "filter-testing",
        undefined,
        apiData
    );
}

export async function createFilterTestInBulk(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "filter-testing/bulk-add",
        undefined,
        apiData
    );
}

export async function createMicroOrganism(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "filter-testing/add-organism",
        undefined,
        apiData
    );
}

export async function updateFilterTest(
    apiData: any, id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/${id}`,
        undefined,
        apiData
    );
}

export async function uploadModifiedReport(
    apiData: any, id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/upload-report/${id}`,
        undefined,
        apiData
    );
}

export async function updateOrganism(
    apiData: any, id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/organism/${id}`,
        undefined,
        apiData
    );
}

export async function doneWithTestAnalysis(id: string): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/done-with-analysis/${id}`,
    );
}

export async function activateFilterTest(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        "filter-testing/activate",
        undefined,
        apiData
    );
}

export async function updateFilterTestReadings(
    id: string,
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/readings/${id}`,
        undefined,
        apiData
    );
}

export async function parseReadingsFile(
    id: string,
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/${id}/parse-reading`,
        undefined,
    );
}

export async function getFilterTestReadings(
    id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "GET",
        `filter-testing/get-readings/${id}`
    );
}

export async function getFilterTestLocations(userId: string): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "GET",
        `filter-testing/locations/${userId}`
    );
}

export async function generateTestReport(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "gen-report",
        undefined,
        apiData,
        undefined,
        undefined,
        true
    );
}

export async function getFilterTest(userId: string = ""): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `filter-testing?userId=${userId}`);
}

export async function getFilterUsersByContractor(): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `filter-testing/users-by-contractor`);
}

export async function getFilterTestForAdmins(adminId: string = ""): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `filter-testing?adminId=${adminId}`);
}

export async function getMicroOrganisms(): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `filter-testing/organisms-list`);
}

export async function getOrganismsCharacters(): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `filter-testing/organism-char`);
}

export async function getQuestionnaires(locationId: string): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `filter-testing/questionnaire/${locationId}`);
}

export async function generateShipmentLabel(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        `filter-testing/shipping-label`,
        undefined,
        apiData
    );
}
