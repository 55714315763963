import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import get from "lodash/get";
import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { SpinnerLoader } from "../../common/Global.Style";
import { getFilterAdminsList } from "../../api/UserController";

interface IAddProductProps {
	open: boolean;
	onclose: () => void;
	onSUbmit: (values: any) => void;
	formData: any;
	isFilterAdmin: () => boolean;
	isLoading: boolean;
}

export const AddTest = ({
	open,
	onclose,
	formData,
	onSUbmit,
	isFilterAdmin,
	isLoading
}: IAddProductProps) => {

	const [status] = useState<any>([
		{ label: "Test Kit received", value: 2 },
		{ label: "Lab Prep in Progress", value: 3 },
		{ label: "Lab Testing in Progress", value: 4 },
	]);
	const [adminOptions, setAdminOptions] = useState<any[]>([]);
	const statusText: any = {
		0: "Order Created",
		1: "Customer Registered",
		2: "Test Kit Received",
		3: "Lap Prep In Progress",
		4: "Lab Testing In Progress",
		5: "Lab Test Report Available",
		6: "Analysis Done"
	}

	let validationSchema: any = {
		name: Yup.string().trim().required("Test Name is required"),
		status: Yup.object({
			label: Yup.string().required("Status is required"),
			value: Yup.string().required("Status is required"),
		}).required("Status is required"),
		admin: Yup.object({
			label: Yup.string().required("Admin is required"),
			value: Yup.string().required("Admin is required"),
		}).required("Admin is required")
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			status: { label: "Order Created", value: 0 },
			address: "",
			comments: "",
			resellerName: "",
			admin: { label: "", value: "" },
			iqiTrackingNo: "",
			customerTrackingNo: "",
			labTrackingNo: ""
		},
		validationSchema: Yup.object().shape(validationSchema),
		onSubmit: ({
			name,
			status,
			address,
			comments,
			resellerName,
			admin,
			iqiTrackingNo,
			labTrackingNo,
			customerTrackingNo
		}) => {
			onSUbmit({
				name,
				status: status.value,
				address,
				comments,
				resellerName,
				admin: admin.value,
				iqiTrackingNo,
				labTrackingNo,
				customerTrackingNo
			});
		},
	});

	useEffect(() => {
		if (!isEmpty(formData)) {
			formik.setFieldValue("name", get(formData, "name"));
			formik.setFieldValue("status", {
				value: get(formData, "status"),
				label: statusText[formData?.status],
			});
			formik.setFieldValue("address", get(formData, "address"));
			formik.setFieldValue("resellerName", get(formData, "resellerName"));
			formik.setFieldValue("iqiTrackingNo", get(formData, "iqiTrackingNo"));
			formik.setFieldValue("labTrackingNo", get(formData, "labTrackingNo"));
			formik.setFieldValue("customerTrackingNo", get(formData, "customerTrackingNo"));
			formik.setFieldValue("comments", get(formData, "comments"))
		}
		listFilterAdmins();
	}, []);

	const listFilterAdmins = async () => {
		const response = await getFilterAdminsList();
		if (response?.responseObj?.list?.length) {
			const admin = response?.responseObj?.list.find((l: any) => l?._id === formData?.admin);
			if (admin) {
				formik.setFieldValue("admin", {
					value: admin?._id,
					label: `${admin?.firstName}${admin.lastName}`,
				});
			}

			setAdminOptions(
				response.responseObj.list.map((l: any) => {
					return { value: l?._id, label: `${l?.firstName}${l?.lastName}` }
				})
			)
		}
	}

	return (
		<Drawer anchor={"right"} open={open} onClose={onclose}>
			{isLoading && <SpinnerLoader />}
			<div className="d-flex justify-content-between p-3">
				<p className="align-items-center d-flex mb-0">{isEmpty(formData) ? "Add" : "Edit"} Filter Test</p>
				<IconButton onClick={onclose}>
					<CloseIcon />
				</IconButton>
			</div>

			<FormikProvider value={formik}>
				<form className="p-4" onSubmit={formik.handleSubmit}>
					<div className="mb-3 form-group">
						<label className="email">Test Name</label>
						<input
							type="text"
							id="name"
							name="name"
							className="bg-light border-0 mt-2"
							placeholder="Filter Test Name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
							disabled={isFilterAdmin()}
						/>
						{formik.touched.name && formik.errors.name ? (
							<div className="text-danger">{formik.errors.name}</div>
						) : null}
					</div>

					<div className="mb-3 form-group">
						<label className="pass mb-2">Assigned Admin</label>
						<div className="password">
							<Select
								name="colors"
								options={adminOptions}
								className="basic-multi-select"
								classNamePrefix="select"
								value={formik.values.admin}
								onChange={(value) => formik.setFieldValue("admin", value)}
								isDisabled={isFilterAdmin()}
							/>
						</div>
						{formik.touched?.admin && formik.errors.admin?.value ? (
							<div className="text-danger">
								{formik.errors.admin?.value}
							</div>
						) : null}
					</div>

					{!isEmpty(formData) &&
						<div className="mb-3 form-group">
							<label className="pass mb-2">Order Status</label>
							<div className="password">
								<Select
									name="colors"
									options={status}
									className="basic-multi-select"
									classNamePrefix="select"
									value={formik.values.status}
									onChange={(value) => formik.setFieldValue("status", value)}
									isDisabled={formData?.status === 5 || formData?.status === 6}
								/>
							</div>
							{formik.touched?.status && formik.errors.status?.value ? (
								<div className="text-danger">
									{formik.errors.status?.value}
								</div>
							) : null}
						</div>
					}

					<div className="mb-3 form-group">
						<label className="email">Reseller Name</label>
						<input
							type="text"
							id="resellerName"
							name="resellerName"
							className="bg-light border-0 mt-2"
							placeholder="Reseller Name"
							onChange={(e: any) => {
								const name = e?.target?.value?.replace(/[^a-zA-Z ]/g, '');
								formik.setFieldValue("resellerName", name);
							}}
							onBlur={formik.handleBlur}
							value={formik.values.resellerName}
							disabled={isFilterAdmin()}
						/>
						{formik.touched.resellerName && formik.errors.resellerName ? (
							<div className="text-danger">{formik.errors.resellerName}</div>
						) : null}
					</div>

					<div className="mb-3 form-group">
						<label className="email">iQi Shipment Tracking #</label>
						<input
							type="text"
							id="iqiTrackingNo"
							name="iqiTrackingNo"
							className="bg-light border-0 mt-2"
							placeholder="iQi Tracking Number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.iqiTrackingNo}
							disabled={isFilterAdmin()}
						/>
					</div>

					<div className="mb-3 form-group">
						<label className="email">Customer Shipment Tracking #</label>
						<input
							type="text"
							id="customerTrackingNo"
							name="customerTrackingNo"
							className="bg-light border-0 mt-2"
							placeholder="Customer Tracking Number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.customerTrackingNo}
							disabled={true}
						/>
					</div>

					<div className="mb-3 form-group">
						<label className="email">Lab Shipment Tracking #</label>
						<input
							type="text"
							id="labTrackingNo"
							name="labTrackingNo"
							className="bg-light border-0 mt-2"
							placeholder="Lab Tracking Number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.labTrackingNo}
						/>
					</div>

					{!isFilterAdmin() &&
						<div className="mb-3 form-group">
							<label className="email">Comments</label>
							<textarea
								name="comments"
								id="comments"
								value={formik.values.comments}
								onChange={(e: any) => formik.setFieldValue("comments", e?.target?.value)}
							/>
							{formik.touched.comments && formik.errors.comments ? (
								<div className="text-danger">{formik.errors.comments}</div>
							) : null}
						</div>
					}

					<button type="submit" className="btn w-100 mb-3 btn-medium">
						{!isEmpty(formData) ? "Update" : "Add"}
					</button>
				</form>
			</FormikProvider>
		</Drawer>
	);
};
