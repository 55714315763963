import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Redirect, Route, Switch } from "react-router-dom";

import Header from "../../components/Header/Header";
import { URL } from "../../components/Navbar/routes";
import AddReportData from "../AddReportData/AddReportData";
import FilterTest from "../FilterTest/FilterTest";
import Profile from "../Profile/Profile";

import "./Routes.scoped.scss";
import OrganismsList from "../Organisms/List";
import FilterAdmin from "../User/User";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import ShippingLabel from "../Label/Label";
import SuperAdminRoute from "../../utils/SuperAdminRoute";
import AdminRoute from "../../utils/AdminRoute";

const Routes: React.FC = () => {

  return (
    <div>
      <div className="left-panel m-d-none">
        <Navbar />
      </div>
      <div className="right-panel">
        <Header />
        <div className="mobile-app-content">
          <Switch>
            <Route
              path={URL.DASHBOARD}
              exact
              component={FilterTest}
            />
            <AdminRoute
              path={URL.ORGANISMS_LIST}
              exact
              component={OrganismsList}
            />
            <SuperAdminRoute
              path={URL.INVITE_USER}
              exact
              component={FilterAdmin}
            />
            <SuperAdminRoute
              path={URL.LABEL}
              exact
              component={ShippingLabel}
            />
            <AdminRoute
              path={URL.ADD_REPORT_DATA}
              component={AddReportData}
            />
            <Route
              path={URL.DASHBOARD_PROFILE}
              exact
              component={Profile}
            />
            <Route
              path={URL.CHANGE_PASSWORD}
              exact
              component={ChangePassword}
            />
            <Route path="*">
              <Redirect to={URL.LOGIN} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Routes;
