import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";

interface SuperAdminRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
}

const SuperAdminRoute = (props: SuperAdminRouteProps) => {
    const { component: Component, ...rest } = props;
    const role = useSelector((state: RootState) => state.user.role);

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                if (role !== "super-admin") {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                            }}
                        />
                    );
                }
                return <Component {...routeProps} />;
            }}
        />
    );
};

export default SuperAdminRoute;
