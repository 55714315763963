import React, { useEffect, useState } from "react";
import "./ResetPassword.scoped.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SpinnerLoader } from "../../common/Global.Style";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { resetPasswordApi } from "../../api/UserController";
import { ApiResponse } from "../../common/Interface";

// return url search param from url bar
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export interface ResetPasswordModal {
	password: string;
	email: string;
	token: string;
}

const ResetPassword: React.FC = (props: any) => {
	let query = useQuery();
	const [response, setResponse] = useState<ApiResponse | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [resetPasswordModal, setResetPasswordModal] =
		useState<ResetPasswordModal>({ password: "", email: "", token: "" });

	const email = query.get("email");
	const token = query.get("user-token");
	useEffect(() => {
		if (token && token !== "") {
			setResetPasswordModal({ password: "", email: email ? email : "", token });
		}
	}, [email, token]);

	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		React.useState<boolean>(false);

	const onPasswordChange = async (password: string) => {
		const resetPasswordModalTemp = { ...resetPasswordModal };
		resetPasswordModalTemp.password = password;
		setIsLoading(true);
		const response: any = await resetPasswordApi(resetPasswordModalTemp);
		setIsLoading(false);
		if (response && response?.responseObj) {
			setResponse({
				isSuccess: true,
				message: response.responseObj.success,
			});
			setTimeout(() => {
				props.history.push("/login");
			}, 2000);
		} else {
			setResponse({ isSuccess: false, message: response?.error });
		}
	};

	const formik = useFormik({
		initialValues: {
			password: "",
			confirmPassword: "",
		},
		validationSchema: Yup.object({
			password: Yup.string()
				.trim()
				.test(
					"len",
					"The password must be 6–16 characters in length",
					function (newPassword: any) {
						return newPassword?.length > 5 && newPassword?.length < 17;
					}
				)
				.test(
					"security-check",
					"The password should contain at least two of the following types: numbers, uppercase letters, lowercase letters, and underscores.",
					function (newPassword: any) {
						let isFound = [];
						if (/\d/.test(newPassword)) {
							isFound.push(true);
						}
						if (/[a-z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (/[A-Z]/.test(newPassword)) {
							isFound.push(true);
						}

						if (newPassword && newPassword.includes("_")) {
							isFound.push(true);
						}
						return isFound.length >= 2;
					}
				)
				.required("New Password is required"),
			confirmPassword: Yup.string()
				.trim()
				.required("Confirm Password is required")
				.oneOf([Yup.ref("password"), null], "Passwords must match"),
		}),
		onSubmit: (values) => {
			onPasswordChange(values.password);
		},
	});

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<>
			<div className="forgot-page d-flex">
				<div className="flex-1 forgot-banner">
					<div className="forgot-logo" />
					<div className="d-flex align-items-end forgot-content font-700">
						<div>
							<h4 className="fs-60 ls-024 txt-white d-flex flex-column mb-4 lh-72">
								iQi
								<span className="font-warn-light-1">Filter Forensics</span>
							</h4>
							<p className="desc-xlarge font-white-85 ls-024 mb-5">
								For a Healthier, Cleaner & Safer Country
							</p>
						</div>
					</div>
				</div>
				<div className=" flex-1 bg-white p-3 forgot-block d-flex flex-column justify-content-center">
					<div className="w-75 mx-auto">
						<h4 className="mb-4 fs-24 font-700 ls-024 lh-normal font-text-1">Reset Password</h4>
						<form onSubmit={formik.handleSubmit}>
							<div className="form-group">
								<label className="pass mb-2">New Password</label>
								<div className="password">
									<input
										type={showPassword ? "text" : "password"}
										id="password"
										name="password"
										className="bg-light border-0"
										placeholder="Type New Password"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.password}
									/>
									<IconButton
										className="icon"
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</div>
								{formik.touched.password && formik.errors.password ? (
									<div className="text-danger">{formik.errors.password}</div>
								) : null}
							</div>

							<div className="form-group">
								<label className="pass mb-2">Confirm Password</label>
								<div className="password">
									<input
										type={showConfirmPassword ? "text" : "password"}
										id="confirmPassword"
										name="confirmPassword"
										className="bg-light border-0"
										placeholder="Type Confirm Password"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.confirmPassword}
									/>
									<IconButton
										className="icon"
										aria-label="toggle password visibility"
										onClick={handleClickShowConfirmPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</div>
								{formik.touched.confirmPassword &&
									formik.errors.confirmPassword ? (
									<div className="text-danger">
										{formik.errors.confirmPassword}
									</div>
								) : null}
							</div>
							{response && (
								<div
									className={response.isSuccess ? "text-success" : "text-danger"}
								>
									{response.message ? response.message : ""}
								</div>
							)}

							<div className="d-flex flex-column align-items-center justify-content-center">
								<button type="submit" className="btn btn-submit mb-3 mr-0">
									submit
								</button>
								<a href="/login" className="link">
									Go to login
								</a>
							</div>
						</form>
					</div>
				</div>
			</div>
			{isLoading && <SpinnerLoader />}
		</>
	);
};

export default withRouter(ResetPassword);
