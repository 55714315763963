import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { parseReadingsFile, updateFilterTest, uploadFile } from "../../api/TestController";
import { toast } from "react-toastify";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from "@material-ui/icons/Close";
import TableView from "@material-ui/icons/TableChartOutlined"
import PreviewDialog from "../../components/PreviewModal/PreviewModal";
import { SpinnerLoader } from "../../common/Global.Style";

interface UploadFiles {
    open: boolean;
    handleClose: () => void;
    test: any;
}

export const UploadFilesModal: React.FC<UploadFiles> = ({
    open,
    handleClose,
    test
}) => {

    const [originalReport, setOriginalReport] = useState<string>("");
    const [rawReadingsFile, setRawReadingsFile] = useState<string>("");
    const [cocReport, setCocReport] = useState<string>("");
    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const onSubmit = async () => {
        setIsLoading(true);
        const response = await updateFilterTest({
            originalReport,
            rawReadingsFile,
            cocReport
        }, test._id);
        setIsLoading(false);
        handleClose();
        if (test.rawReadingsFile !== rawReadingsFile) {
            await parseRawReadingsFile();
        }
        if (response?.error) {
            toast.error(response?.error);
        } else {
            toast.success("Files uploaded successfully");
        }
    };

    useEffect(() => {
        setRawReadingsFile(test?.rawReadingsFile);
        setCocReport(test?.cocReport);
        setOriginalReport(test?.originalReport);
    }, []);

    const parseRawReadingsFile = async () => {
        await parseReadingsFile(test?._id);
    }

    const onFileChange = async (event: any, type: string) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0], event.target.files[0].name);
        setIsLoading(true);
        const res = await uploadFile(formData);
        setIsLoading(false);
        if (res?.responseObj?.Location) {
            const url = res?.responseObj?.Location;
            if (type === "report") {
                setOriginalReport(url)
            }
            if (type === "readings") {
                setRawReadingsFile(url);
            }
            if (type === "coc") {
                setCocReport(url);
            }
        }
    };

    const onClickCloseReport = (type: string) => {
        if (type === "original") {
            setOriginalReport("");
        } else if (type === "coc") {
            setCocReport("");
        } else if (type === "raw") {
            setRawReadingsFile("");
        }
    }

    return (
        <>
            {showPreview && <PreviewDialog
                onPositiveBtnClicked={() => setShowPreview(false)}
                onNegativeBtnClicked={() => setShowPreview(false)}
                onCloseIconClicked={() => setShowPreview(false)}
                reportUrl={originalReport}
                title="Report Preview"
                positiveButtonLabel=""
            />
            }
            <Modal show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading && <SpinnerLoader />}

                    <div className="d-flex pb-0">
                        <h6>{test?.orderId}</h6>
                    </div>

                    <div className="mb-3">
                        <label className="mr-3">Upload Original Report</label>
                        <Button
                            type="button"
                            startIcon={<UploadFileIcon />}
                            className="btn p-3 cursor-pointer"
                        >
                            <label
                                htmlFor="report-file"
                                className="m-0 w-100 cursor-pointer"
                            >
                                <input
                                    accept="application/pdf"
                                    style={{ display: "none" }}
                                    id="report-file"
                                    type="file"
                                    onChange={(event: any) => onFileChange(event, "report")}
                                />
                                Upload
                            </label>
                        </Button>
                    </div>

                    <div className="d-flex flex-wrap report-data-main">
                        {originalReport &&
                            <div className="report-data-image mb-4">
                                <div className="d-flex justify-content-between align-items-center">
                                    <PictureAsPdfIcon className="pdf-icon" />
                                    <div className="d-flex align-items-center">
                                        <p
                                            onClick={() => setShowPreview(true)}
                                            className="mb-0 font-400 f-s-14 lh-16 ls-024 text-truncate max-w-100 txt-black cursor-pointer">
                                            Lab Report
                                        </p>
                                    </div>
                                    <CloseIcon
                                        className="cursor-pointer ml-1 close-icon"
                                        onClick={() => onClickCloseReport("original")}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="mb-3">
                        <label className="mr-3">Upload COC File</label>
                        <Button
                            type="button"
                            startIcon={<UploadFileIcon />}
                            className="btn p-3"
                        >
                            <label
                                htmlFor="contained-button-file"
                                className="m-0 w-100"
                            >
                                <input
                                    accept="application/pdf"
                                    style={{ display: "none" }}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(event: any) => onFileChange(event, "coc")}
                                />
                                COC
                            </label>
                        </Button>
                    </div>

                    <div className="d-flex flex-wrap report-data-main">
                        {cocReport &&
                            <div className="report-data-image mb-4">
                                <div className="d-flex justify-content-between align-items-center">
                                    <PictureAsPdfIcon className="pdf-icon" />
                                    <div className="d-flex align-items-center">
                                        <p
                                            onClick={() => setShowPreview(true)}
                                            className="mb-0 font-400 f-s-14 lh-16 ls-024 text-truncate max-w-100 txt-black cursor-pointer">
                                            COC Report
                                        </p>
                                    </div>
                                    <CloseIcon
                                        className="cursor-pointer ml-1 close-icon"
                                        onClick={() => onClickCloseReport("coc")}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="mb-2">
                        <label className="mr-4">Upload Readings</label>
                        <Button
                            type="button"
                            startIcon={<UploadFileIcon />}
                            className="btn p-3 cursor-pointer"
                        >
                            <label
                                htmlFor="readings-button-file"
                                className="m-0 w-100 cursor-pointer"
                            >
                                <input
                                    accept=".xlsx,.csv"
                                    style={{ display: "none" }}
                                    id="readings-button-file"
                                    multiple
                                    type="file"
                                    onChange={(event: any) => onFileChange(event, "readings")}
                                />
                                Upload
                            </label>
                        </Button>
                    </div>

                    <div className="d-flex flex-wrap report-data-main">
                        {rawReadingsFile &&
                            <div className="report-data-image mb-4">
                                <div className="d-flex justify-content-between align-items-center">
                                    <TableView className="pdf-icon" />
                                    <div className="d-flex align-items-center">
                                        <p
                                            className="mb-0 font-400 f-s-14 lh-16 ls-024 text-truncate max-w-100 txt-black cursor-pointer">
                                            Readings File
                                        </p>
                                    </div>
                                    <CloseIcon
                                        className="cursor-pointer ml-1 close-icon"
                                        onClick={() => onClickCloseReport("raw")}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="button" onClick={onSubmit}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
