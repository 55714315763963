import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import './previewMOdal.scss';

interface PreviewDialogProps {
    onPositiveBtnClicked: () => void;
    onNegativeBtnClicked: () => void;
    onCloseIconClicked: () => void;
    reportUrl: string;
    title: string;
    positiveButtonLabel: string;
}

const PreviewDialog: React.FC<PreviewDialogProps> = ({
    onPositiveBtnClicked,
    onNegativeBtnClicked,
    onCloseIconClicked,
    reportUrl,
    title,
    positiveButtonLabel
}) => {
    const [show, setShow] = useState(true);

    const onPositiveBtn = () => {
        setShow(false);
        onPositiveBtnClicked();
    };

    const hideModal = () => {
        setShow(false);
        onCloseIconClicked();
    };

    return (
        <>
            <Modal className="report-preview-modal" show={show} onHide={hideModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe className="w-100 h-100" src={reportUrl} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        className="btn-medium"
                        onClick={onNegativeBtnClicked}>
                        Cancel
                    </Button>

                    {positiveButtonLabel?.length !== 0 &&
                        <Button
                            className="btn-medium"
                            variant="primary"
                            onClick={onPositiveBtn}
                            style={{ cursor: "pointer" }}
                        >
                            Upload
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PreviewDialog;
