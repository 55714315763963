import { SelectableRows } from "mui-datatables";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { InviteAdmin } from "./InviteAdmin";
import { toast } from "react-toastify";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Dropdown } from "react-bootstrap";
import { createFilterAdmin, getFilterUsersList, getResetPasswordLink, updateUser } from "../../api/UserController";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const FilterAdmin = () => {
    const [showUserTest, setShowUserTest] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userList, setUserList] = useState<any>([]);
    const [activeUsersList, setActiveUsersList] = useState<any>([]);
    const [deactivatedUsersList, setDeactivatedUsersList] = useState<any>([]);
    const [selectedUser, setSelectedUser] = useState<any>({});
    const [showDeleteUser, setShowDeleteUser] = useState<boolean>(false);
    const [showActivateUser, setShowActivateUser] = useState<boolean>(false);
    const [resetLink, setResetLink] = useState("");
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        setIsLoading(true);
        const response = await getFilterUsersList();
        setIsLoading(false);
        if (response?.responseObj?.list) {
            const list = response?.responseObj?.list
            setUserList(list);
            setActiveUsersList(list.filter((l: any) => !l.deleted));
            setDeactivatedUsersList(list.filter((l: any) => l.deleted));
        }
    }

    const onSUbmit = async (values: any) => {
        let response;
        setIsLoading(true);
        if (selectedUser._id) {
            response = await updateUser({
                ...values,
                _id: selectedUser._id,
            }, selectedUser._id);
            setIsLoading(false);
        } else {
            response = await createFilterAdmin(values);
            setIsLoading(false);
        }
        if (response?.error) {
            toast.error(response?.error);
        } else if (response?.responseObj.message) {
            setShowUserTest(false);
            getList();
            toast.success(response?.responseObj.message);
        }
    };

    const onDelete = async () => {
        setIsLoading(true);
        let response = await updateUser({ deleted: true }, selectedUser._id);
        setIsLoading(false);
        if (response?.error) {
            toast.error(response?.error);
        } else if (response?.responseObj.message) {
            setShowDeleteUser(false);
            toast.success("Deleted successfully");
            getList();
        }
    };

    const onActivate = async () => {
        setShowActivateUser(false);
        setIsLoading(true);
        let response = await updateUser({ deleted: false }, selectedUser._id);
        setIsLoading(false);
        if (response?.error) {
            toast.error(response?.error);
        } else if (response?.responseObj.message) {
            toast.success("Activated successfully");
            getList();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const generateLink = async (email: string) => {
        setIsLoading(true);
        const response = await getResetPasswordLink({ email });
        setIsLoading(false);
        if (response?.error) {
            toast.error(response?.error);
        } else if (response?.responseObj?.data?.resetUrl) {
            setResetLink(response?.responseObj?.data?.resetUrl);
            setOpen(true);
        }
    };

    const columns = [
        {
            name: "firstName",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Email ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "contactNumber",
            label: "Contact Number",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Reset Password Link",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value: string) => {
                    return (
                        <span onClick={() => generateLink(value)} className="link">
                            Generate Link
                        </span>
                    );
                },
            },
        },
        {
            name: "_id",
            label: "Actions",
            options: {
                filter: false,
                display: !value,
                download: false,
                customBodyRender: (value: any) => {
                    return (
                        <Dropdown className="">
                            <Dropdown.Toggle
                                variant="Primary"
                                id="dropdown-basic"
                                bsPrefix="dropdown-button"
                            >
                                <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "inherit" }}>
                                <Dropdown.Item
                                    className="simple text-break"
                                    onClick={() => {
                                        const selectedData: any = userList.find(
                                            (mapData: any) => mapData._id === value
                                        );
                                        setSelectedUser(selectedData);
                                        setShowUserTest(true);
                                    }}
                                >
                                    Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                    className="simple text-break"
                                    onClick={() => {
                                        const selectedData: any = userList.find(
                                            (mapData: any) => mapData._id === value
                                        );
                                        setSelectedUser(selectedData);
                                        setShowDeleteUser(true);
                                    }}
                                >
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                },
            },
        },
        {
            name: "_id",
            label: "Actions",
            options: {
                filter: false,
                display: value === 1,
                download: false,
                customBodyRender: (value: any) => {
                    return (
                        <Dropdown className="">
                            <Dropdown.Toggle
                                variant="Primary"
                                id="dropdown-basic"
                                bsPrefix="dropdown-button"
                            >
                                <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "inherit" }}>
                                <Dropdown.Item
                                    className="simple text-break"
                                    onClick={() => {
                                        const selectedData: any = userList.find(
                                            (mapData: any) => mapData._id === value
                                        );
                                        setSelectedUser(selectedData);
                                        setShowActivateUser(true);
                                    }}
                                >
                                    Activate
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                },
            },
        },

    ];

    const options = {
        print: false,
        viewColumns: false,
        responsive: "standard",
        downloadOptions: {
            filename: "FilterUsers.csv",
        },
        selectableRows: "none" as SelectableRows,
    };

    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <div className="px-4 py-4">
            <Modal show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <a
                            href={resetLink}
                            rel="noreferrer"
                            className="link"
                            target="_blank"
                            style={{ wordBreak: "break-all" }}
                        >
                            {resetLink}
                        </a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>
                <div className="d-flex justify-content-end mb-4">
                    <Button onClick={() => {
                        setShowUserTest(true);
                        setSelectedUser({});
                    }
                    } className="btn mr-0 btn-medium">
                        Add New
                    </Button>
                </div>
            </div>
            {isLoading && <SpinnerLoader />}

            {showUserTest && (
                <InviteAdmin
                    open={showUserTest}
                    onclose={() => setShowUserTest(false)}
                    formData={selectedUser}
                    onSUbmit={onSUbmit}
                    isLoading={isLoading}
                />
            )}

            {showDeleteUser && (
                <ConfirmDialog
                    dialogTitle="Delete"
                    message="Are you sure want to delete the admin?"
                    positiveBtnLabel="Confirm"
                    negativeBtnLabel="Cancel"
                    onPositiveBtnClicked={onDelete}
                    onNegativeBtnClicked={() => setShowDeleteUser(false)}
                    onCloseIconClicked={() => setShowDeleteUser(false)}
                />
            )}

            {showActivateUser && (
                <ConfirmDialog
                    dialogTitle="Activate"
                    message="Are you sure want to activate this user?"
                    positiveBtnLabel="Confirm"
                    negativeBtnLabel="Cancel"
                    onPositiveBtnClicked={onActivate}
                    onNegativeBtnClicked={() => setShowActivateUser(false)}
                    onCloseIconClicked={() => setShowActivateUser(false)}
                />
            )}
            <div className="user-section">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                >
                    <Tab label="Users" {...a11yProps(0)} />
                    <Tab label="Deleted Users" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <CustomDataTable
                        title="Users"
                        rows={activeUsersList}
                        columns={columns}
                        options={options}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CustomDataTable
                        title="Deleted Users"
                        rows={deactivatedUsersList}
                        columns={columns}
                        options={options}
                    />
                </TabPanel>
            </div>
        </div>
    )
};

export default FilterAdmin;
