import { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { theme } from "./theme/CustomTheme";
import Shared from "./common/Shared";
import { URL } from "./components/Navbar/routes";
import SigUp from "./pages/SignUp/SignUp";

import "./MainStyle.scss";
import "./App.css";
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./pages/Routes/Routes";

function App() {

	useEffect(() => {
		document.title = Shared.title;
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<div className="sans-serif">
				<Switch>
					<Route exact path="/">
						<Redirect to={URL.LOGIN} />
					</Route>
					<Route exact path={URL.LOGIN} component={Login} />
					<Route path={URL.RESET_PASSWORD} component={ResetPassword} />
					<Route exact path={URL.SIGN_UP} component={SigUp} />
					<Route path={URL.FORGOT_PASSWORD} component={ForgotPassword} />
					<PrivateRoute path={URL.DASHBOARD} component={Dashboard} />
					<Route path="*">
						<Redirect to={URL.LOGIN} />
					</Route>
				</Switch>
			</div>
		</ThemeProvider>
	);
}

export default App;
