import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import get from "lodash/get";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { SpinnerLoader } from "../../common/Global.Style";
import Select from "react-select";
import { uploadFile } from "../../api/TestController";
import { toast } from "react-toastify";

interface IAddAdminProps {
	open: boolean;
	onclose: () => void;
	onSUbmit: (values: any) => void;
	formData: any;
	isLoading: boolean;
}

export const InviteAdmin = ({
	open,
	onclose,
	formData,
	onSUbmit,
	isLoading
}: IAddAdminProps) => {

	let validationSchema: any = {
		email: Yup.string()
			.email("Invalid email address")
			.required("Email ID is required"),
		firstName: Yup.string().trim().required("First Name is required")
			.matches(/[a-zA-Z]/, "First name is not valid"),
		lastName: Yup.string().trim(),
		contact: Yup.string()
			.matches(/^(\d{10}(,(?=.))?)+$/, "Please enter 10 digit valid phone number without country code")
			.required("Phone Number is required"),
	};

	const roleOptions: any[] = [
		{ value: "filter-admin", label: "filter-admin" },
		{ value: "lab-admin", label: "lab-admin" }
	]

	const formik = useFormik({
		initialValues: {
			role: { label: "", value: "" },
			firstName: "",
			lastName: "",
			email: "",
			contact: "",
			addressLine1: "",
			addressLine2: "",
			cityLocality: "",
			stateProvince: "",
			countryCode: "",
			postalCode: "",
			certification: "",
			signature: ""
		},
		validationSchema: Yup.object().shape(validationSchema),
		onSubmit: ({
			firstName,
			lastName,
			email,
			contact,
			addressLine1,
			addressLine2,
			cityLocality,
			stateProvince,
			countryCode,
			postalCode,
			role,
			signature,
			certification
		}) => {
			if (formik.values.role.value === "filter-admin") {
				if (!formik.values.addressLine1.trim().length) {
					formik.setFieldError("addressLine1", "Address Line is required");
					return;
				}
				if (!formik.values.stateProvince.trim().length) {
					formik.setFieldError("stateProvince", "State Province is required");
					return;
				}
				if (!formik.values.cityLocality.trim().length) {
					formik.setFieldError("cityLocality", "City Locality is required");
					return;
				}
				if (!formik.values.countryCode.trim().length) {
					formik.setFieldError("countryCode", "Country Code is required");
					return;
				}
				if (!formik.values.postalCode) {
					formik.setFieldError("postalCode", "Postal Code is required");
					return;
				}
			}

			onSUbmit({
				firstName,
				lastName,
				email: email.toLowerCase(),
				contactNumber: contact,
				role: role.value,
				signature,
				certification,
				shipToAddress: {
					addressLine1,
					addressLine2,
					cityLocality,
					stateProvince,
					countryCode,
					postalCode
				}
			});
		},
	});

	useEffect(() => {
		if (!isEmpty(formData)) {
			formik.setFieldValue("firstName", get(formData, "firstName"));
			formik.setFieldValue("lastName", get(formData, "lastName"));
			formik.setFieldValue("email", get(formData, "email"));
			formik.setFieldValue("contact", get(formData, "contactNumber"));
			formik.setFieldValue("addressLine1", get(formData, "shipToAddress.addressLine1", ""));
			formik.setFieldValue("addressLine2", get(formData, "shipToAddress.addressLine2", ""));
			formik.setFieldValue("cityLocality", get(formData, "shipToAddress.cityLocality", ""));
			formik.setFieldValue("stateProvince", get(formData, "shipToAddress.stateProvince", ""));
			formik.setFieldValue("countryCode", get(formData, "shipToAddress.countryCode", ""));
			formik.setFieldValue("postalCode", get(formData, "shipToAddress.postalCode", ""));
			const role = get(formData, "role");
			formik.setFieldValue("role", { value: role, label: role });
		}
	}, []);

	const onUploadSignature = async (event: any) => {
		const formData = new FormData();
		formData.append("file", event.target.files[0], event.target.files[0].name);
		const res = await uploadFile(formData);
		if (res?.responseObj?.Location) {
			formik.setFieldValue("signature", res?.responseObj?.Location);
		} else {
			toast.error("Upload failed");
		}
	}

	return (
		<>
			{isLoading && <SpinnerLoader />}

			{!isLoading &&
				<Drawer anchor={"right"} open={open} onClose={onclose}>
					<div className="d-flex justify-content-between p-3">
						<p className="align-items-center d-flex mb-0">
							{isEmpty(formData) ? "Add" : "Edit"} User
						</p>
						<IconButton onClick={onclose}>
							<CloseIcon />
						</IconButton>
					</div>

					<FormikProvider value={formik}>
						<form className="p-4" onSubmit={formik.handleSubmit}>
							<div className="mb-3 form-group">
								<label className="email">First Name</label>
								<input
									type="text"
									id="firstName"
									name="firstName"
									className="bg-light border-0 mt-2"
									placeholder="First Name"
									onBlur={formik.handleBlur}
									value={formik.values.firstName}
									onChange={formik.handleChange}
									maxLength={20}
								/>
								{formik.touched.firstName && formik.errors.firstName ? (
									<div className="text-danger">{formik.errors.firstName}</div>
								) : null}
							</div>
							<div className="mb-3 form-group">
								<label className="email">Last Name</label>
								<input
									type="text"
									id="lastName"
									name="lastName"
									className="bg-light border-0 mt-2"
									placeholder="Last Name"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.lastName}
								/>
								{formik.touched.lastName && formik.errors.lastName ? (
									<div className="text-danger">{formik.errors.lastName}</div>
								) : null}
							</div>
							<div className="mb-3 form-group">
								<label className="email">Email</label>
								<input
									type="email"
									id="email"
									name="email"
									className="bg-light border-0 mt-2"
									placeholder="Email ID"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
								/>
								{formik.touched.email && formik.errors.email ? (
									<div className="text-danger">{formik.errors.email}</div>
								) : null}
							</div>
							{formik.values.role.value !== "user" &&
								<>
									<label className="email">Role</label>
									<div className="password mb-3">
										<Select
											name="colors"
											options={roleOptions}
											className="basic-select"
											classNamePrefix="select"
											value={formik.values.role}
											onChange={(value) => formik.setFieldValue("role", value)}
										/>
									</div>
									{formik.errors.role ? (
										<div className="text-danger">
											{formik.errors.role}
										</div>
									) : null}
								</>
							}
							{formik.values.role.value === "filter-admin" &&
								<>
									<div className="mb-3 bg-white org-wrap">
										<input
											accept="image/*"
											style={{ display: "none" }}
											id="images"
											type="file"
											onClick={(event: any) => {
												event.target.value = null;
											}}
											onChange={(event: any) => {
												onUploadSignature(event);
											}}
										/>
										<div className="pb-2 d-flex">
											<label htmlFor="images">
												<p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
													Upload Signature
												</p>
											</label>
										</div>
										{formik.values.signature.length > 0 &&
											<img className="ref-img" src={formik.values.signature} />
										}
									</div>

									<div className="mb-3 bg-white org-wrap">
										<label className="email">Certifications</label>
										<input
											type="text"
											id="certification"
											name="certification"
											className="bg-light border-0 mt-2"
											placeholder="Certifications"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.certification}
										/>
									</div>

								</>
							}
							<div className="mb-3 form-group">
								<label className="email">Contact</label>
								<input
									type="text"
									id="contact"
									name="contact"
									maxLength={10}
									className="bg-light border-0 mt-2"
									placeholder="Contact"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.contact}
								/>
								{formik.touched.contact && formik.errors.contact ? (
									<div className="text-danger">{formik.errors.contact}</div>
								) : null}
							</div>

							{formik.values.role.value === "filter-admin" &&
								<>
									<label className="email">Shipping Address</label>
									<div className="mb-3 form-group">
										<label className="email">Address Line 1</label>
										<input
											type="text"
											id="addressLine1"
											name="addressLine1"
											maxLength={50}
											className="bg-light border-0 mt-2"
											placeholder="Address Line 1"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.addressLine1}
										/>
										{formik.touched.addressLine1 && formik.errors.addressLine1 ? (
											<div className="text-danger">{formik.errors.addressLine1}</div>
										) : null}
									</div>
									<div className="mb-3 form-group">
										<label className="email">Address Line 2</label>
										<input
											type="text"
											id="addressLine2"
											name="addressLine2"
											maxLength={50}
											className="bg-light border-0 mt-2"
											placeholder="Address Line 2"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.addressLine2}
										/>
										{formik.touched.addressLine2 && formik.errors.addressLine2 ? (
											<div className="text-danger">{formik.errors.addressLine2}</div>
										) : null}
									</div>
									<div className="mb-3 form-group">
										<label className="email">City</label>
										<input
											type="text"
											id="cityLocality"
											name="cityLocality"
											className="bg-light border-0 mt-2"
											placeholder="City"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.cityLocality}
										/>
										{formik.touched.cityLocality && formik.errors.cityLocality ? (
											<div className="text-danger">{formik.errors.cityLocality}</div>
										) : null}
									</div>
									<div className="mb-3 form-group">
										<label className="email">State</label>
										<input
											type="text"
											id="stateProvince"
											name="stateProvince"
											maxLength={2}
											className="bg-light border-0 mt-2"
											placeholder="State Province"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.stateProvince}
										/>
										{formik.touched.stateProvince && formik.errors.stateProvince ? (
											<div className="text-danger">{formik.errors.stateProvince}</div>
										) : null}
									</div>
									<div className="mb-3 form-group">
										<label className="email">Country Code</label>
										<input
											type="text"
											id="countryCode"
											name="countryCode"
											maxLength={2}
											className="bg-light border-0 mt-2"
											placeholder="Country Code"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.countryCode}
										/>
										{formik.touched.countryCode && formik.errors.countryCode ? (
											<div className="text-danger">{formik.errors.countryCode}</div>
										) : null}
									</div>
									<div className="mb-3 form-group">
										<label className="email">Zip Code</label>
										<input
											type="number"
											id="postalCode"
											name="postalCode"
											className="bg-light border-0 mt-2"
											placeholder="Postal Code"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.postalCode}
										/>
										{formik.touched.postalCode && formik.errors.postalCode ? (
											<div className="text-danger">{formik.errors.postalCode}</div>
										) : null}
									</div>
								</>
							}

							<button type="submit" className="btn w-100 mb-3 btn-medium">
								{!isEmpty(formData) ? "Update" : "Add"}
							</button>
						</form>
					</FormikProvider>
				</Drawer>}
		</>
	);
};
