import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import get from "lodash/get";
import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { SpinnerLoader } from "../../common/Global.Style";
import { MultiSelectWithCheckBox } from "../../components/MultiSelectWithCheckbox/MultiSelectWithCheckbox";

interface IAddProductProps {
	open: boolean;
	onclose: () => void;
	onSUbmit: (values: any) => void;
	formData: any;
	index: number;
	isLoading: boolean;
	charOptions: any[];
}

export const AddEditOrganisms = ({
	index,
	open,
	onclose,
	formData,
	onSUbmit,
	isLoading,
	charOptions
}: IAddProductProps) => {
	const [selectedChars, setSelectedChars] = useState<any>([]);
	const title: any = {
		0: "Particulate",
		1: "Micro Organisms",
		2: "Definition",
		3: "Glossary",
		4: "Remediation"
	}
	let validationSchema: any = {
		definition: Yup.string().trim()
			.required("Definition is required"),
		name: Yup.string().trim().required("Name is required"),
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			definition: "",
			characteristics: [],
			weightage: 0,
			solutions: "",
			good: {
				min: 0,
				max: 0,
				problems: "",
			},
			moderate: {
				min: 0,
				max: 0,
				problems: "",
			},
			poor: {
				min: 0,
				max: 0,
				problems: "",
			},
			hazardous: {
				min: 0,
				max: 0,
				problems: "",
			},
		},
		validationSchema: Yup.object().shape(validationSchema),
		onSubmit: ({
			name,
			definition,
			weightage,
			good,
			moderate,
			poor,
			hazardous,
			solutions
		}) => {
			onSUbmit(
				{
					organism: {
						name: name.trim(),
						definition: definition.trim(),
						type: index,
						characteristics: selectedChars.map((s: any) => s.value),
					},
					dimension: {
						weightage,
						good,
						moderate,
						poor,
						hazardous,
						solutions
					}
				}
			);
		},
	});

	const onCharChange = (values: any) => {
		values = values?.length > 1 ? values.filter((v: any) => v.label !== "N/A") : values;
		setSelectedChars(values);
	}

	useEffect(() => {
		if (!isEmpty(formData)) {
			formik.setFieldValue("definition", get(formData, "definition"));
			formik.setFieldValue("name", get(formData, "name"));
			formik.setFieldValue("weightage", get(formData, "dimension.weightage"));
			formik.setFieldValue("solutions", get(formData, "dimension.solutions"));
			formik.setFieldValue("good.min", get(formData, "dimension.good.min"));
			formik.setFieldValue("good.max", get(formData, "dimension.good.max"));
			formik.setFieldValue("good.problems", get(formData, "dimension.good.problems"));
			formik.setFieldValue("moderate.min", get(formData, "dimension.moderate.min"));
			formik.setFieldValue("moderate.max", get(formData, "dimension.moderate.max"));
			formik.setFieldValue("moderate.problems", get(formData, "dimension.moderate.problems"));
			formik.setFieldValue("poor.min", get(formData, "dimension.poor.min"));
			formik.setFieldValue("poor.max", get(formData, "dimension.poor.max"));
			formik.setFieldValue("poor.problems", get(formData, "dimension.poor.problems"));
			formik.setFieldValue("hazardous.min", get(formData, "dimension.hazardous.min"));
			formik.setFieldValue("hazardous.max", get(formData, "dimension.hazardous.max"));
			formik.setFieldValue("hazardous.problems", get(formData, "dimension.hazardous.problems"));

			let options = formData?.characteristics;
			options = options?.length > 1 ? options.filter((o: any) => o.name !== "N/A") : options;
			if (options && options.length) {
				setSelectedChars(options.map((o: any) => { return { label: o?.name, value: o?._id } }))
			}
		}
	}, []);

	return (
		<>
			{isLoading && <SpinnerLoader />}

			{!isLoading &&
				<Drawer anchor={"right"} open={open} onClose={onclose}>
					<div className="d-flex justify-content-between p-3">
						<p className="align-items-center d-flex mb-0">
							{`${isEmpty(formData) ? "Add" : "Edit"} ${title[index]}`}
						</p>
						<IconButton onClick={onclose}>
							<CloseIcon />
						</IconButton>
					</div>

					<FormikProvider value={formik}>
						<form className="p-4" onSubmit={formik.handleSubmit}>
							<div className="mb-3 form-group">
								<label className="email">Name</label>
								<input
									type="text"
									id="name"
									name="name"
									className="bg-light border-0 mt-2"
									placeholder="Enter Name"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.name}
								/>
								{formik.touched.name && formik.errors.name ? (
									<div className="text-danger">{formik.errors.name}</div>
								) : null}
							</div>

							<div className="mb-3 form-group">
								<label className="email">Definition</label>
								<textarea
									name="definition"
									id="definition"
									value={formik.values.definition}
									onChange={(e: any) => formik.setFieldValue("definition", e?.target?.value)}
								/>
								{formik.touched.definition && formik.errors.definition ? (
									<div className="text-danger">{formik.errors.definition}</div>
								) : null}
							</div>

							{![2, 3, 4].includes(index) &&
								<div className="mb-3 form-group">
									<label className="email">Characteristics</label>
									<MultiSelectWithCheckBox
										seletedOptions={charOptions}
										selectValue={selectedChars}
										onSelectionChange={onCharChange}
									/>
								</div>
							}

							<div className="mb-3 form-group">
								<label className="email">Weightage</label>
								<input
									type="number"
									id="weightage"
									name="weightage"
									className="bg-light border-0 mt-2"
									placeholder="Enter weightage"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.weightage}
								/>
								{formik.touched.weightage && formik.errors.weightage ? (
									<div className="text-danger">{formik.errors.weightage}</div>
								) : null}
							</div>

							<div className="mb-3 form-group">
								<label className="email">Recommendations</label>
								<textarea
									name="solutions"
									id="solutions"
									value={formik.values.solutions}
									onChange={(e: any) => formik.setFieldValue("solutions", e?.target?.value)}
								/>
								{formik.touched.solutions && formik.errors.solutions ? (
									<div className="text-danger">{formik.errors.solutions}</div>
								) : null}
							</div>

							<div className="mb-3 form-group d-flex">
								<label className="email mt-3 mr-2">Good</label>
								<input
									type="number"
									id="good.min"
									name="good.min"
									className="bg-light border-0 mt-2 w-50"
									placeholder="Enter Min"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.good.min}
								/>
								<input
									type="number"
									id="good.max"
									name="good.max"
									className="bg-light border-0 mt-2 w-50 ml-3"
									placeholder="Enter Max"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.good.max}
								/>
							</div>

							<div className="mb-3 form-group">
								<label className="email">Comments</label>
								<textarea
									name="good.problems"
									id="good.problems"
									value={formik.values.good.problems}
									onChange={(e: any) => formik.setFieldValue("good.problems", e?.target?.value)}
								/>
								{formik.touched.good?.problems && formik.errors.good?.problems ? (
									<div className="text-danger">{formik.errors.good?.problems}</div>
								) : null}
							</div>


							<div className="mb-3 form-group d-flex">
								<label className="email mt-3 mr-2">Moderate</label>
								<input
									type="number"
									id="moderate.min"
									name="moderate.min"
									className="bg-light border-0 mt-2 w-50"
									placeholder="Enter Min"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.moderate.min}
								/>
								<input
									type="number"
									id="moderate.max"
									name="moderate.max"
									className="bg-light border-0 mt-2 w-50 ml-3"
									placeholder="Enter Max"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.moderate.max}
								/>
							</div>

							<div className="mb-3 form-group">
								<label className="email">Comments</label>
								<textarea
									name="moderate.problems"
									id="moderate.problems"
									value={formik.values.moderate.problems}
									onChange={(e: any) => formik.setFieldValue("moderate.problems", e?.target?.value)}
								/>
								{formik.touched.moderate?.problems && formik.errors.moderate?.problems ? (
									<div className="text-danger">{formik.errors.moderate?.problems}</div>
								) : null}
							</div>

							<div className="mb-3 form-group d-flex">
								<label className="email mt-3 mr-2">Poor</label>
								<input
									type="number"
									id="poor.min"
									name="poor.min"
									className="bg-light border-0 mt-2 w-50"
									placeholder="Enter Min"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.poor.min}
								/>
								<input
									type="number"
									id="poor.max"
									name="poor.max"
									className="bg-light border-0 mt-2 w-50 ml-3"
									placeholder="Enter Max"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.poor.max}
								/>
							</div>

							<div className="mb-3 form-group">
								<label className="email">Comments</label>
								<textarea
									name="poor.problems"
									id="poor.problems"
									value={formik.values.poor.problems}
									onChange={(e: any) => formik.setFieldValue("poor.problems", e?.target?.value)}
								/>
								{formik.touched.poor?.problems && formik.errors.poor?.problems ? (
									<div className="text-danger">{formik.errors.poor?.problems}</div>
								) : null}
							</div>

							<div className="mb-3 form-group d-flex">
								<label className="email mt-3 mr-2">Hazardous</label>
								<input
									type="number"
									id="hazardous.min"
									name="hazardous.min"
									className="bg-light border-0 mt-2 w-50"
									placeholder="Enter Min"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.hazardous.min}
								/>
								<input
									type="number"
									id="hazardous.max"
									name="hazardous.max"
									className="bg-light border-0 mt-2 w-50 ml-3"
									placeholder="Enter Max"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.hazardous.max}
								/>
							</div>

							<div className="mb-3 form-group">
								<label className="email">Comments</label>
								<textarea
									name="hazardous.problems"
									id="hazardous.problems"
									value={formik.values.hazardous.problems}
									onChange={(e: any) => formik.setFieldValue("hazardous.problems", e?.target?.value)}
								/>
								{formik.touched.hazardous?.problems && formik.errors.hazardous?.problems ? (
									<div className="text-danger">{formik.errors.hazardous?.problems}</div>
								) : null}
							</div>

							<button type="submit" className="btn w-100 mb-3 btn-medium">
								{!isEmpty(formData) ? "Update" : "Add"}
							</button>
						</form>
					</FormikProvider>
				</Drawer>
			}

		</>

	);
};
