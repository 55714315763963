import IconButton from "@material-ui/core/IconButton/IconButton";
import TableCell from "@material-ui/core/TableCell/TableCell";
import { SelectableRows } from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { RootState } from "../../redux/reducers";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { AddTest } from "./AddTest";
import { toast } from "react-toastify";
import {
	activateFilterTest,
	createFilterTest,
	getFilterTest,
	updateFilterTest,
	createFilterTestInBulk,
	getFilterTestLocations,
	getFilterTestForAdmins,
	doneWithTestAnalysis,
	getFilterUsersByContractor,
} from "../../api/TestController";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { URL } from "../../components/Navbar/routes";
import "./FilterTest.scss";
import { setTest } from "../../redux/actions/testActions";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Dropdown } from "react-bootstrap";
import { AddBulkTest } from "./BulkTestAdd";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment-timezone";
import { OrderRegiteredModal } from "../../components/ActivationSuccess/ActivationSuccess";
import { Questionnaire } from "../Questionnaire/Questionnaire";
import { GenerateLabel } from "../../components/GenerateShippingLabel/GenerateShippingLabel";
import { ShipItModal } from "../../components/ShipItModal/ShipItModal";
import { GetResultModal } from "../../components/GetResultModal/GetResultModal";
import LabelPreviewDialog from "../../components/LabelPreviewModal/LabelPreviewModal";
import DatePicker from "../../components/DatePicker/DatePicker";
import { getFilterAdminsList } from "../../api/UserController";
import Select from "react-select";
import EditIcon from "@material-ui/icons/Edit";
import { UploadFilesModal } from "./UploadFilesModal";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const FilterTest = () => {

	const user = useSelector((state: RootState) => state.user);
	const [showAddTest, setShowAddTest] = useState<boolean>(false);
	const [showBulkAddTest, setShowBulkAddTest] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filterTestList, setFilterTestList] = useState<any>([]);
	const [filterTestListCopy, setFilterTestListCopy] = useState<any>([]);
	const [selectedTest, setSelectedTest] = useState<any>({});
	const [showDeleteTest, setShowDeleteTest] = useState<boolean>(false);
	const [showDoneWithAnalysis, setShowDoneWithAnalysis] = useState<boolean>(false);
	const [showActivationModal, setShowActivationModal] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [showShipItModal, setShowShipItModal] = useState<boolean>(false);
	const [showGetResultModal, setShowGetResultModal] = useState<boolean>(false);
	const [showLabelGenerate, setShowLabelGenerate] = useState<boolean>(false);
	const [showLabelPreview, setShowLabelPreview] = useState<boolean>(false);
	const [clickedGenerateLabel, setClickedGenerateLabel] = useState<boolean>(false);
	const [showOpenRegenerate, setShowOpenRegenerate] = useState<boolean>(false);
	const [locations, setLocations] = useState<any[]>([]);
	const [users, setUsers] = useState<any[]>([]);
	const [url, setLabelUrl] = useState<string>("");
	const [regForCustomer, setRegisterForCustomer] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const [adminOptions, setAdminOptions] = useState<any[]>([]);
	const [selectedAdmin, setSelectedAdmin] = useState<any>();
	const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);

	const { innerWidth: width } = window;
	const dispatch = useDispatch();
	const history = useHistory();

	const statusText: any = {
		0: "Order Created",
		1: "Customer Registered",
		2: "Test Kit Received",
		3: "Lap Prep In Progress",
		4: "Lab Testing In Progress",
		5: "Lab Test Report Available",
		6: "Analysis Done"
	}

	const statusTextClass: any = {
		0: "created",
		1: "registered",
		2: "received",
		3: "prep-in-progress",
		4: "in-progress",
		5: "available",
		6: "done"
	}

	const isAdmin = () => {
		return user?.role === "super-admin" || user?.role === "filter-admin";
	}

	const isSuperAdmin = () => {
		return user?.role === "super-admin";
	}

	const isFilterAdmin = () => {
		return user?.role === "filter-admin";
	}

	const isLabAdmin = () => {
		return user?.role === "lab-admin";
	}

	const isUser = () => {
		return user?.role === "user" || user?.role === "contractor";
	}

	const isShowAddReport = (id: string) => {
		const test = filterTestList.find((f: any) => f._id === id);
		return test?.status === 4 || test?.status === 5;
	}

	const isShowGenerateLabel = (id: string) => {
		const test = filterTestList.find((f: any) => f._id === id);
		return test.status === 1;
	}

	const generateLabelText = (id: string) => {
		const test = filterTestList.find((f: any) => f._id === id);
		return test?.shipmentLabel ? "Regenerate" : "Generate";
	}

	const isAnalysisDone = (id: string) => {
		const test = filterTestList.find((f: any) => f._id === id);
		return test?.status === 6;
	}

	const isShowDoneButton = (id: string) => {
		const test = filterTestList.find((f: any) => f._id === id);
		return test?.status === 5;
	}

	const getActionTitle = (id: string) => {
		const test = filterTestList.find((f: any) => f._id === id);
		return test?.status === 4 ? "Add" : "Edit";
	}

	useEffect(() => {
		getFilterTestList();
		getLocations();
		getUsers();
		if (isSuperAdmin()) {
			listFilterAdmins();
		}
	}, [])

	const getLocations = async () => {
		const response = await getFilterTestLocations(user._id);
		if (response?.responseObj?.locations?.length) {
			setLocations(response?.responseObj?.locations);
		}
	}

	const getUsers = async () => {
		if (user.role !== "contractor") {
			return;
		}
		const response = await getFilterUsersByContractor();
		if (response?.responseObj?.users?.length) {
			setUsers(response?.responseObj?.users);
		}
	}

	const addReport = (testId: string) => {
		const selectedData: any = filterTestList.find(
			(mapData: any) => mapData._id === testId
		);
		if (selectedData) {
			dispatch(setTest(selectedData));
			history.push(URL.ADD_REPORT_DATA);
		}
	}

	const onClickGenerateLabel = (testId: string) => {
		setClickedGenerateLabel(true);
		const selectedData: any = filterTestList.find(
			(mapData: any) => mapData._id === testId
		);
		if (selectedData) {
			dispatch(setTest(selectedData));
			setShowLabelGenerate(true);
		}
	}

	const bulkAdd = async (data: any) => {
		setShowBulkAddTest(false);
		const response = await createFilterTestInBulk(data);
		if (response?.responseObj) {
			toast.success("Added Successfully");
			getFilterTestList();
		} else if (response?.error) {
			toast.error(response?.error);
		}
	}

	const getFilterTestList = async () => {
		setIsLoading(true);
		let response;
		if (isSuperAdmin() || isLabAdmin()) {
			response = await getFilterTest();
		} else if (isFilterAdmin()) {
			response = await getFilterTestForAdmins(user?._id);
		} else {
			response = await getFilterTest(user?._id);
		}

		setIsLoading(false);
		if (response?.responseObj?.list) {
			setFilterTestList(response?.responseObj?.list);
			setFilterTestListCopy(response?.responseObj?.list);
		}
	}

	const onSUbmit = async (values: any) => {
		setIsLoading(true);
		let response;
		if (selectedTest._id) {
			response = await updateFilterTest({
				...values,
				_id: selectedTest._id,
			}, selectedTest._id);
			setIsLoading(false);
		} else {
			response = await createFilterTest(values);
			setIsLoading(false);
		}
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj.message) {
			setShowAddTest(false);
			getFilterTestList();
			toast.success(response?.responseObj.message);
		}
	};

	const activateNewOrder = async (data: any) => {
		setIsLoading(true);
		const response = await activateFilterTest(data);
		setIsLoading(false);
		if (response?.responseObj?.message) {
			dispatch(setTest(response?.responseObj?.test));
			setSelectedTest(response?.responseObj?.test);
			setShowActivationModal(false);
			setShowSuccessModal(true);
		} else if (response?.error) {
			toast.error(response?.error);
		}
	}

	const onCloseGetResultModal = () => {
		setShowGetResultModal(false);
		getFilterTestList();
	}

	const onDelete = async () => {
		setIsLoading(true);
		let response = await updateFilterTest({ isDeleted: true }, selectedTest._id);
		setIsLoading(false);
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj.message) {
			setShowDeleteTest(false);
			toast.success("Deleted successfully");
			getFilterTestList();
		}
	};

	const confirmRegenerateShippingLabel = () => {
		setShowOpenRegenerate(false);
		setClickedGenerateLabel(true);
		onClickGenerateLabel(selectedTest?._id);
	}

	const onClickRegenerateShippingLabel = (id: any) => {
		const test = filterTestList.find((f: any) => f._id === id);
		if (!test) {
			return;
		}
		setSelectedTest(test);
		if (test?.shipmentLabel) {
			setShowOpenRegenerate(true);
		} else {
			setClickedGenerateLabel(true);
			setShowLabelGenerate(true);
		}
	}

	const onClickDownloadLabel = () => {
		setShowOpenRegenerate(false);
		window.open(selectedTest?.shipmentLabel);
	}

	const onCloseActivationModal = async () => {
		setShowActivationModal(false);
		getLocations();
		getUsers();
	}

	const doneWithAnalysis = async () => {
		setIsLoading(true);
		const response = await doneWithTestAnalysis(selectedTest?._id);
		setShowDoneWithAnalysis(false);
		setIsLoading(false);
		if (response?.responseObj?.message) {
			toast.success("Successfully Completed");
			getFilterTestList();
		} else if (response?.error) {
			toast.error(response?.error);
		}
	}

	const onClickFilter = () => {
		if (!startDate && !endDate && !selectedAdmin?.value) {
			return;
		}
		const modifiedStartDate = moment(startDate).startOf("day").toDate();
		const modifiedEndDate = moment(endDate).endOf("day").toDate();
		const startTime: number = new Date(modifiedStartDate)?.getTime() || 0;
		const endTime: number = new Date(modifiedEndDate)?.getTime() || 0;
		let list = filterTestListCopy;

		if (startTime) {
			list = filterTestListCopy.filter(
				(f: any) => {
					const createdAt = new Date(f.createdAt).getTime();
					if (createdAt >= startTime) {
						return f;
					}
				});
		}
		if (endTime) {
			list = filterTestListCopy.filter(
				(f: any) => {
					const createdAt = new Date(f.createdAt).getTime();
					if (createdAt <= endTime) {
						return f;
					}
				});
		}
		if (selectedAdmin && selectedAdmin.value) {
			list = list.filter((f: any) => f.admin === selectedAdmin.value);
		}
		setFilterTestList(list);
	}

	const onChangeDate = (value: Date | null, propertyName: string) => {
		if (propertyName === "start") {
			setStartDate(value);
		} else {
			setEndDate(value);
		}
	};

	const listFilterAdmins = async () => {
		const response = await getFilterAdminsList();
		if (response?.responseObj?.list?.length) {
			setAdminOptions(
				response.responseObj.list.map((l: any) => {
					return { value: l?._id, label: `${l?.firstName}${l?.lastName}` }
				}));
		}
	}

	const onClearFilter = () => {
		setFilterTestList(filterTestListCopy);
		setStartDate(null);
		setEndDate(null);
		setSelectedAdmin({});
	}

	const columns = [
		{
			name: "name",
			label: "Test Name",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "orderId",
			label: "Order Id",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: false,
				sort: true,
				download: false,
				display: !isLabAdmin(),
				customBodyRender: (value: any) => {
					return <span className={`px-2 py-1 text-nowrap ${statusTextClass[value]}`}>{statusText[value]}</span>
				},
			},
		},
		{
			name: "email",
			label: "User Email",
			options: {
				sort: true,
				filter: true,
				display: !isLabAdmin(),
			},
		},
		{
			name: "adminName",
			label: "Assigned Admin",
			options: {
				sort: true,
				filter: false,
				display: isSuperAdmin()
			},
		},
		{
			name: "lName",
			label: "Location",
			options: {
				sort: true,
				filter: false,
				display: !isAdmin() && !isLabAdmin()
			},
		},
		{
			name: "lAddress",
			label: "Address",
			options: {
				filter: false,
				display: !isAdmin() && !isLabAdmin()
			},
		},
		{
			name: "activatedDate",
			label: "Activated At",
			options: {
				sort: true,
				filter: false,
				display: !isLabAdmin(),
				customBodyRender: (value: any) => {
					return (value ? <span>{moment(value).format("lll")}</span> : <span>-</span>)
				},
			},
		},
		{
			name: "resellerName",
			label: "Reseller Name",
			options: {
				sort: true,
				filter: true,
				display: isAdmin(),
			},
		},
		{
			name: "customerTrackingNo",
			label: "Customer Shipment Tracking #",
			options: {
				sort: true,
				filter: true,
				display: isAdmin(),
			},
		},
		{
			name: "_id",
			options: {
				display: isAdmin(),
				filter: false,
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Add Report</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					if (isShowAddReport(value)) {
						return (<Button className="btn-medium btn-primary-outline"
							onClick={() => addReport(value)}>{getActionTitle(value)}</Button>)
					}
				},
			},
		},
		{
			name: "modifiedReport",
			label: "Download Report",
			options: {
				filter: false,
				display: !isLabAdmin(),
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Download Report</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					return <IconButton disabled={!value} onClick={() => window.open(value)}><GetAppIcon className={value ? "download-icon" : ""} /></IconButton>;
				},
			},
		},
		{
			name: "shipmentLabel",
			label: "Download Shipment Label",
			options: {
				filter: false,
				display: isUser(),
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Download Shipment Label</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					return <IconButton disabled={!value} onClick={() => window.open(value)}><GetAppIcon className={value ? "download-icon" : ""} /></IconButton>;
				},
			},
		},
		{
			name: "_id",
			label: "Generate Shipment Label",
			options: {
				filter: false,
				display: isUser(),
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Generate Shipment Label</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					if (isShowGenerateLabel(value)) {
						return (<Button className="btn-medium btn-primary-outline"
							onClick={() => onClickRegenerateShippingLabel(value)}>{generateLabelText(value)}</Button>)
					}
				},
			},
		},
		{
			name: "_id",
			label: "Done with Analysis",
			options: {
				display: isUser(),
				filter: false,
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Done With Analysis</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					if (isShowDoneButton(value)) {
						return <Button className="btn btn-submit btn-medium ml-3" onClick={() => {
							const selectedData: any = filterTestList.find(
								(mapData: any) => mapData._id === value
							);
							setSelectedTest(selectedData);
							setShowDoneWithAnalysis(true);
						}
						}>
							Done
						</Button>
					}
				},
			},
		},
		{
			name: "originalReport",
			label: "Original Report",
			options: {
				filter: false,
				display: isAdmin(),
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Original Report</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					return <IconButton disabled={!value} onClick={() => window.open(value)}><GetAppIcon className={value ? "download-icon" : ""} /></IconButton>;
				},
			},
		},
		{
			name: "reportUpdatedDate",
			label: "Report Uploaded At",
			options: {
				sort: true,
				filter: false,
				display: !isLabAdmin(),
				customBodyRender: (value: any) => {
					return (value ? <span>{moment(value).format("lll")}</span> : <span>-</span>)
				},
			},
		},
		{
			name: "_id",
			label: "Actions",
			options: {
				filter: false,
				download: false,
				display: isAdmin(),
				customBodyRender: (value: any) => {
					if (!isAnalysisDone(value)) {
						return (
							<Dropdown>
								<Dropdown.Toggle
									variant="Primary"
									id="dropdown-basic"
									bsPrefix="dropdown-button"
								>
									<MoreHorizIcon />
								</Dropdown.Toggle>
								<Dropdown.Menu style={{ width: "inherit" }}>
									<Dropdown.Item
										className="simple text-break"
										onClick={() => {
											const selectedData: any = filterTestList.find(
												(mapData: any) => mapData._id === value
											);
											setSelectedTest(selectedData);
											setShowAddTest(true);
										}}
									>
										Edit
									</Dropdown.Item>

									{!isFilterAdmin() &&
										<Dropdown.Item
											className="simple text-break"
											onClick={() => {
												const selectedData: any = filterTestList.find(
													(mapData: any) => mapData._id === value
												);
												setSelectedTest(selectedData);
												setShowDeleteTest(true);
											}}
										>
											Delete
										</Dropdown.Item>
									}
								</Dropdown.Menu>
							</Dropdown>
						);
					}
				},
			},
		},
		{
			name: "customerTrackingNo",
			label: "Shipment Tracking No",
			options: {
				filter: false,
				download: false,
				display: isLabAdmin()
			},
		},
		{
			name: "_id",
			label: "Upload Actions",
			options: {
				filter: false,
				download: false,
				display: isLabAdmin(),
				customBodyRender: (value: any) => {
					if (!isAnalysisDone(value)) {
						return (
							<UploadFileIcon className="txt-blue"
								onClick={() => {
									const selectedData: any = filterTestList.find(
										(mapData: any) => mapData._id === value
									);
									setSelectedTest(selectedData);
									setShowUploadFileModal(true);
								}}
							/>
						);
					}
				},
			},
		},
	];

	const options = {
		viewColumns: false,
		responsive: "standard",
		selectableRows: "none" as SelectableRows,
		download: true,
		downloadOptions: {
			filename: "FilterOrders.csv",
		},
		print: false,
	};

	return (
		<div className={isAdmin() ? "px-4 py-3" : "px-4 py-5"}>
			{isAdmin() &&
				<div className="d-flex mt-4 iaq-report-block">
					<div className="d-flex col-lg-6 col-md-12 p-0 iaq-report-select">
						<div className="mr-2 w-50 date-select">
							<div className="form-group">
								<DatePicker
									label="Start Date"
									inputId={"start-date-input"}
									date={startDate}
									onDateChanged={(date: Date | null) =>
										onChangeDate(date, "start")
									}
									disableFuture={true}
									datepickerClass="iaq-re-input p-2"
								/>
							</div>
						</div>
						<div className="mr-2 w-50 date-select">
							<div className="form-group">
								<DatePicker
									label="End Date"
									inputId={"end-date-input"}
									date={endDate}
									onDateChanged={(date: Date | null) =>
										onChangeDate(date, "end")
									}
									disableFuture={true}
									datepickerClass="iaq-re-input p-2"
									minDate={startDate}
								/>
							</div>
						</div>
					</div>
					{
						isSuperAdmin() &&
						<div className="mb-3 form-group">
							<label className="pass mb-2">Admin</label>
							<div className="password">
								<Select
									name="colors"
									options={adminOptions}
									className="admin-select"
									classNamePrefix="select"
									value={selectedAdmin}
									onChange={(value) => setSelectedAdmin(value)}
								/>
							</div>
						</div>
					}
				</div>
			}
			{
				isAdmin() &&
				<div className="d-flex mb-2">
					<Button onClick={onClickFilter} className="btn font-600 btn-medium mr-0">
						Filter
					</Button>
					<Button onClick={() => {
						onClearFilter();
					}
					} className="btn font-600 btn-medium btn-primary-outline ml-2">
						Clear Filter
					</Button>

				</div>
			}
			{(!isFilterAdmin() && !isLabAdmin()) &&
				<div className="m-d-none py-4">
					{isAdmin() ?
						<div className="d-flex justify-content-end mb-4">
							<Button onClick={() => {
								setShowBulkAddTest(true);
							}
							} className="btn font-600 btn-medium btn-primary-outline mr-2">
								Bulk Add
							</Button>
							<Button onClick={() => {
								setShowAddTest(true);
								setSelectedTest({});
							}
							} className="btn font-600 btn-medium mr-0">
								Add New
							</Button>
						</div>
						:
						<div className="d-flex justify-content-end mb-4">
							<Button onClick={() => {
								setRegisterForCustomer(false);
								setShowActivationModal(true);
							}
							} className="btn btn-medium mr-2">
								{user.role !== "contractor" ? "Register Your Test" : "Register for me"}
							</Button>
							{
								user.role === "contractor" &&
								<Button onClick={() => {
									setRegisterForCustomer(true);
									setShowActivationModal(true);
								}
								} className="btn btn-medium">
									Register for a customer
								</Button>
							}
						</div>
					}
				</div>
			}

			{isLoading && <SpinnerLoader />}

			{showAddTest && (
				<AddTest
					open={showAddTest}
					onclose={() => setShowAddTest(false)}
					formData={selectedTest}
					onSUbmit={onSUbmit}
					isFilterAdmin={isFilterAdmin}
					isLoading={isLoading}
				/>
			)}

			{showBulkAddTest && (
				<AddBulkTest
					open={showBulkAddTest}
					onclose={() => setShowBulkAddTest(false)}
					onSUbmit={bulkAdd}
				/>
			)}

			{showActivationModal && (
				<Questionnaire
					open={showActivationModal}
					onclose={() => onCloseActivationModal()}
					onSUbmit={activateNewOrder}
					locations={locations}
					regForCustomer={regForCustomer}
					users={users}
					user={user}
				/>
			)}

			{showLabelGenerate &&
				<GenerateLabel
					open={showLabelGenerate}
					test={selectedTest}
					onclose={(data: any) => {
						if (data && data.length) {
							setLabelUrl(data);
							setShowLabelGenerate(false);
							setShowLabelPreview(true);
						} else {
							setShowLabelGenerate(false);
							if (!clickedGenerateLabel) {
								setShowGetResultModal(true);
							} else {
								getFilterTestList();
							}
						}
					}
					}
				/>
			}

			{showLabelPreview &&
				<LabelPreviewDialog
					labelUrl={url}
					open={showLabelPreview}
					onClose={() => {
						setShowLabelPreview(false);
						if (!clickedGenerateLabel) {
							setShowGetResultModal(true);
							getFilterTestList();
							getUsers();
						} else {
							setClickedGenerateLabel(false);
							getFilterTestList();
							getUsers();
						}
					}
					}
				/>
			}

			{showGetResultModal &&
				<GetResultModal
					open={showGetResultModal}
					onClose={() => onCloseGetResultModal()}
				/>
			}

			{showSuccessModal && (
				<OrderRegiteredModal
					open={showSuccessModal}
					nextStep={() => {
						setShowSuccessModal(false);
						setShowShipItModal(true);
					}
					}
				/>
			)}

			{showShipItModal && (
				<ShipItModal
					open={showShipItModal}
					genearteLabel={() => {
						setShowShipItModal(false);
						setShowLabelGenerate(true);
					}
					}
				/>
			)}

			{showDeleteTest && (
				<ConfirmDialog
					dialogTitle="Delete"
					message="Are you sure want to delete the order?"
					positiveBtnLabel="Confirm"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={onDelete}
					onNegativeBtnClicked={() => setShowDeleteTest(false)}
					onCloseIconClicked={() => setShowDeleteTest(false)}
				/>
			)}

			{showOpenRegenerate && (
				<ConfirmDialog
					dialogTitle="Regenerate Shipping Label"
					message="You already have a shipping label created for your order. Do you want to download it?"
					positiveBtnLabel="Regenerate"
					negativeBtnLabel="Download"
					onPositiveBtnClicked={confirmRegenerateShippingLabel}
					onNegativeBtnClicked={onClickDownloadLabel}
					onCloseIconClicked={() => setShowOpenRegenerate(false)}
				/>
			)}

			{showDoneWithAnalysis && (
				<ConfirmDialog
					dialogTitle="Done With Analysis"
					message="This action will remove the record permanently and can not be recovered"
					positiveBtnLabel="Confirm"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={doneWithAnalysis}
					onNegativeBtnClicked={() => setShowDoneWithAnalysis(false)}
					onCloseIconClicked={() => setShowDoneWithAnalysis(false)}
				/>
			)}

			{showUploadFileModal &&
				<UploadFilesModal
					open={showUploadFileModal}
					handleClose={() => setShowUploadFileModal(false)}
					test={selectedTest}
				/>
			}

			<div className="table-web-block">
				<CustomDataTable
					title="Filter Test Reports"
					rows={filterTestList}
					columns={columns}
					options={options}
				/>
			</div>

			<div className="mt-4 m-m-0 responssive-card-block">
				<div className="audit-report-cards">
					{
						filterTestList.map((reportData: any) => (
							<div className="audit-report-card">
								<div className="details-block">
									<h4 className="desc-normal font-bold font-primary mb-0">
										{reportData?.name}
									</h4>

									<div className="flex-1 d-flex flex-wrap text-wrap mt-2">
										<p
											className={`px-2 py-1 text-nowrap ${statusTextClass[reportData?.status]}`}>
											{statusText[reportData?.status]}
										</p>
										{
											reportData?.status === 5 &&
											!isAdmin() &&
											<p
												className="px-1 py-1 ml-1 text-nowrap done-action"
												onClick={() => {
													setSelectedTest(reportData);
													setShowDoneWithAnalysis(true)
												}}
											>
												Done with Analysis
											</p>
										}

										{
											reportData?.status === 1 &&
											!isAdmin() &&
											!isFilterAdmin() &&
											<p
												className="px-1 py-1 ml-1 text-nowrap done-action"
												onClick={() => {
													onClickRegenerateShippingLabel(reportData?._id);
												}}
											>
												{reportData?.shipmentLabel ? "Regenerate Shipping Label" : "Generate Shipping Label"}
											</p>
										}

									</div>

									<div className="flex-1 d-flex flex-wrap text-wrap justify-content-between">
										<div>
											<p className="desc-small font-tertiary text-truncate">Order ID</p>
											<p className="desc-small font-primary text-wrap">{reportData?.orderId}</p>
										</div>
										<div className="mr-2">
											<p className="desc-small font-tertiary text-truncate">Location</p>
											<p className="desc-small font-primary text-wrap">{reportData?.lName}</p>
										</div>
									</div>

									<div className="flex-1 d-flex flex-wrap text-wrap justify-content-between">
										<div>
											<p className="desc-small font-tertiary text-truncate">Report Uploaded At</p>
											<p className="desc-small font-primary text-wrap">{moment(reportData?.reportUpdatedDate).format("lll")}</p>
										</div>
										<div className="mr-2">
											<p className="desc-small font-tertiary text-truncate">Address</p>
											<p className="desc-small font-primary text-wrap">{reportData?.lAddress}</p>
										</div>
									</div>

									{reportData?.modifiedReport &&
										<div className="d-flex align-items-center justify-content-between mt-3">
											<Button
												onClick={() => {
													window.open(reportData?.modifiedReport);
												}}
												className="font-600 btn-medium btn-primary-outline flex-1"
											>
												View Report
											</Button>
										</div>
									}

									{reportData?.shipmentLabel && reportData?.status === 1 &&
										<div className="d-flex align-items-center justify-content-between mt-3">
											<Button
												onClick={() => {
													window.open(reportData?.shipmentLabel);
												}}
												className="font-600 btn-medium btn-primary-outline flex-1"
											>
												Download Shipping Label
											</Button>
										</div>
									}

								</div>
							</div>
						))
					}
					{
						(filterTestList.length === 0 && !isLoading) && (
							<div className="text-align-center">
								<img className="mt-5"
									src="https://iqintel-dev-files.s3.amazonaws.com/standards/activate+order.png"
								/>
								<h1>Register Your Test</h1>
								<p>You can find the order id on the top of the mailing envelop and also on the top of the filter bag</p>
								<button
									className="btn primary-btn"
									onClick={() => setShowActivationModal(true)}
								>
									Register Your Test
								</button>
							</div>
						)}
					{width < 1024 && filterTestList.length > 0 && (
						<div>
							<div className="m-add-customer d-none m-d-block add-customer">
								<button
									className="btn btn-medium primary-btn"
									onClick={() => setShowActivationModal(true)}
								>
									<AddIcon />
									Register Your Next Test
								</button>
							</div>
							{user.role === "contractor" &&
								<div className="m-add-for-customer d-none m-d-block add-for-customer">
									<button
										className="btn btn-medium primary-btn"
										onClick={() => {
											setRegisterForCustomer(true);
											setShowActivationModal(true);
										}}
									>
										<AddIcon />
										Register Your Next Test For Customer
									</button>
								</div>
							}
						</div>
					)}
				</div>
			</div>
		</div >)
};

export default FilterTest;
