export const URL = {
  DASHBOARD: "/dashboard/filter-test",
  ORGANISMS_LIST: "/dashboard/filter-test/organisms-list",
  DASHBOARD_PROFILE: "/dashboard/filter-test/profile",
  CHANGE_PASSWORD: "/dashboard/filter-test/change-password",
  SIGN_UP: "/sign-up",
  LOGIN: "/login",
  ADD_REPORT_DATA: "/dashboard/filter-test/data",
  INVITE_USER: "/dashboard/filter-test/user",
  RESET_PASSWORD: "/reset-password",
  LABEL: "/dashboard/filter-test/label",
  FORGOT_PASSWORD: "/forgotPassword"
};
