import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { generateShipmentLabel } from "../../api/TestController";
import { toast } from "react-toastify";
import { SpinnerLoader } from "../../common/Global.Style";
import { get } from "lodash";
import moment from "moment-timezone";

interface IGenerateProps {
    open: boolean;
    onclose: (data: any) => void;
    test: any;
}

export const GenerateLabel = ({
    open,
    onclose,
    test
}: IGenerateProps) => {
    const user: any = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [lastYear, setLastYear] = useState<string>("");
    const [nextYear, setNextYear] = useState<string>("");

    let validationSchema: any = {
        name: Yup.string().trim().required("Name is required")
            .matches(/([a-zA-Z])/, "Name is not valid"),
        addressLine1: Yup.string().trim().required("Address Line is required")
            .matches(/([a-zA-Z])/, "Address is not valid"),
        cityLocality: Yup.string().trim().required("City Locality is required"),
        postalCode: Yup.string().trim().required("Postal Code is required"),
    };

    const generateShippingLabel = async (data: any) => {
        setLoading(true);
        const response = await generateShipmentLabel(data);
        setLoading(false);
        if (response?.responseObj?.label) {
            onclose(response?.responseObj?.label);
        } else if (response?.error) {
            toast.error(response?.error);
        }
    }

    useEffect(() => {
        const year = moment().dayOfYear(1).format("YYYY-MM-DD");
        setLastYear(year.toString());
        const nextYear = moment().add(1, "year").format("YYYY-MM-DD");
        setNextYear(nextYear.toString());
    })

    const formik = useFormik({
        initialValues: {
            name: `${user.firstName} ${user.lastName}`,
            addressLine1: get(test.location, "address", ""),
            cityLocality: get(test.location, "city", ""),
            postalCode: get(test.location, "zipcode", ""),
            phone: user.contactNumber,
            alreadyHaveLabel: false,
            date: "",
            agree: false
        },
        validationSchema: Yup.object().shape(validationSchema),
        onSubmit: ({
            name,
            addressLine1,
            cityLocality,
            postalCode,
            phone,
            date
        }) => {
            if (!formik.values.alreadyHaveLabel) {
                if (!formik.values.phone) {
                    formik.setFieldError("phone", "Phone Number is Required");
                    return;
                }
                if (!formik.values.date) {
                    formik.setFieldError("date", "Date sample taken field is required");
                    return;
                }
                if (!formik.values.agree) {
                    formik.setFieldError("agree", "Please agree to the above");
                    return;
                }

                generateShippingLabel({
                    name,
                    addressLine1,
                    cityLocality,
                    postalCode,
                    phone,
                    testId: test?._id,
                    countryCode: "US",
                    date
                });
            } else {
                formik.setFieldError("phone", "");
                onclose("");
            }
        },
    });

    return (
        <>
            {loading && <SpinnerLoader />}

            {!loading &&
                <Drawer anchor={"right"} open={open} onClose={onclose}>
                    <div className="d-flex justify-content-between p-2">
                        <h5 className="align-items-center d-flex mb-0">Generate Shipment Label</h5>
                        <IconButton onClick={onclose}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <FormikProvider value={formik}>
                        <form className="px-4" onSubmit={formik.handleSubmit}>
                            <h5>Sender Information</h5>

                            <div className="mb-3 form-group d-flex align-items-baseline">
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id="alreadyHaveLabel"
                                    name="alreadyHaveLabel"
                                    onChange={formik.handleChange}
                                    checked={formik.values.alreadyHaveLabel}
                                />
                                <label className="ml-2 mb-0 cursor-pointer" htmlFor="terms">
                                    I already have Shipping Label.
                                </label>

                                {formik.touched.alreadyHaveLabel && formik.errors.alreadyHaveLabel && (
                                    <div className="text-danger mt-1">{formik.errors.alreadyHaveLabel}</div>
                                )}
                            </div>

                            <div className="mb-3 form-group">
                                <label className="email">Full Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="bg-light border-0 mt-2"
                                    placeholder="Enter Name"
                                    onChange={(e: any) => {
                                        let name = e?.target?.value?.trimStart();
                                        name = name?.replace(/[^a-zA-Z0-9 ]/g, '');
                                        formik.setFieldValue("name", name);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-danger">{formik.errors.name}</div>
                                ) : null}
                            </div>

                            <div className="mb-3 form-group">
                                <label className="email">Phone Number</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    className="bg-light border-0 mt-2"
                                    placeholder="Phone"
                                    onChange={(e: any) => {
                                        const name = e?.target?.value?.replace(/[^0-9]/g, '');
                                        formik.setFieldValue("phone", name);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <div className="text-danger">{formik.errors.phone}</div>
                                ) : null}
                            </div>

                            <div className="mb-3 form-group">
                                <label className="email">Address Line</label>
                                <input
                                    type="text"
                                    id="addressLine1"
                                    name="addressLine1"
                                    className="bg-light border-0 mt-2"
                                    placeholder="Address Line"
                                    onChange={(e: any) => {
                                        let name = e?.target?.value?.trimStart();
                                        formik.setFieldValue("addressLine1",
                                            name?.replace(/[^a-zA-Z0-9-,-./ ]/g, ''));
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addressLine1}
                                />
                                {formik.touched.addressLine1 && formik.errors.addressLine1 ? (
                                    <div className="text-danger">{formik.errors.addressLine1}</div>
                                ) : null}
                            </div>

                            <div className="mb-3 form-group">
                                <label className="email">City</label>
                                <input
                                    type="text"
                                    id="cityLocality"
                                    name="cityLocality"
                                    className="bg-light border-0 mt-2"
                                    placeholder="City"
                                    onChange={(e: any) => {
                                        let name = e?.target?.value?.trimStart();
                                        name = name?.replace(/[^a-zA-Z ]/g, '');
                                        formik.setFieldValue("cityLocality", name);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.cityLocality}
                                />
                                {formik.touched.cityLocality && formik.errors.cityLocality ? (
                                    <div className="text-danger">{formik.errors.cityLocality}</div>
                                ) : null}
                            </div>

                            <div className="mb-3 form-group">
                                <label className="email">Zip Code</label>
                                <input
                                    type="text"
                                    id="postalCode"
                                    name="postalCode"
                                    className="bg-light border-0 mt-2"
                                    placeholder="Postal Code"
                                    onChange={(e: any) => {
                                        const name = e?.target?.value?.replace(/[^0-9]/g, '');
                                        formik.setFieldValue("postalCode", name);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.postalCode}
                                />
                                {formik.touched.postalCode && formik.errors.postalCode ? (
                                    <div className="text-danger">{formik.errors.postalCode}</div>
                                ) : null}
                            </div>

                            <div className="mb-3 form-group">
                                <label className="email">When are you planning to take the sample?</label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    min={lastYear}
                                    max={nextYear}
                                    className="bg-light border-0 mt-2"
                                    placeholder="Date sample was taken"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.date}
                                    onKeyDown={(e: any) => e.preventDefault()}
                                />
                                {formik.touched.date && formik.errors.date ? (
                                    <div className="text-danger">{formik.errors.date}</div>
                                ) : null}
                            </div>

                            <div>
                                <div className="mb-3 form-group d-flex align-items-baseline">
                                    <input
                                        className="checkbox"
                                        type="checkbox"
                                        id="agree"
                                        name="agree"
                                        onChange={formik.handleChange}
                                        checked={formik.values.agree}
                                    />
                                    <label className="ml-2 mb-0 cursor-pointer" htmlFor="terms">
                                        I agree to electronically sign the chain of custody form for the filter sample taken
                                    </label>
                                </div>

                                <div>
                                    {formik.errors.agree && (
                                        <div className="text-danger mt-1">{formik.errors.agree}</div>
                                    )}
                                </div>
                            </div>

                            <button type="submit" className="btn w-100 mb-3">
                                {!formik.values.alreadyHaveLabel ? "Generate" : "Next"}
                            </button>
                        </form>
                    </FormikProvider>
                </Drawer>
            }
        </>
    );
};
